import { FunctionComponent } from 'react';
import styled from 'styled-components';

import sparrowhubLoader from "../../assets/images/graphics/sparrowhub-loader.gif"

type LoaderProps = {
}

export const Loader: FunctionComponent<LoaderProps> = ({ }) => {
  return (
    <StyledLoader className="Loader" style={{ display: 'flex' }}>
      <span>
        <img src={sparrowhubLoader} title="Loading..." alt="" />
      </span>
    </StyledLoader>
  );
}

const StyledLoader = styled.div`
  img {
    width: 100%;
    height: 41px;
    margin-top: 18px;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  }
`