export enum Category {
  OpenOrders = "open",
  RequiresManifest = "manifest",
  DispatchCollection = "dispatch",
  ClosedOrders = "closed",
}

export enum Subcategory {
  // open
  IncomingOrders = "open_incoming",
  FraudOrders = "open_fraud",
  InProgress = "open_inProgress",
  PendingPayment = "open_payment",
  // requires manifest
  ManifestPickup = "manifest_pickup",
  ManifestDropoff = "manifest_dropoff",
  // dispatch & collection
  DispatchPickup = "dispatch_pickup",
  DispatchDropoff = "dispatch_dropoff",
  DispatchCollection = "dispatch_collection",
  // closed
  CompletedOrders = "closed_complete",
  CancelledOrders = "closed_cancelled",
  PartialRefundOrders = "closed_partiallyRefunded",
  RefundOrders = "closed_refunded",
}

// export enum Category {
//   Open = "open",
//   Overdue = "overdue",
//   AwaitingDelivery = "awaiting_delivery",
//   AwaitingPickup = "awaiting_pickup",
//   AwaitingPayment = "awaiting_payment",
//   Complete = "complete",
//   CancelledRefunded = "cancelled_refunded",
// }

// export enum Subcategory {
//   RequiresAction = "requires_action",
//   DispatchOrganised = "dispatch_organised"
// }

export enum ScriptQueueCategory {
  New = "new",
  Dispensing = "dispensing_in_progress",
  Dispensed = "dispensed",
  AwaitingCollection = "awaiting_collection",
  Archived = "archived",
}

export enum ScriptQueueSubcategory {
  AwaitingProcessing = "awaiting_processing",
  OnHold = "on_hold",
  PreCollection = "pre_collection",
}