import { Timestamp } from "./Timestamp"

export enum UserRoleCode {
  User = "user",
  Admin = "admin",
  Manager = "manager",
}

export interface IUser {
  id: number
  email: string
  first_name: string
  last_name: string
  created_at: Timestamp
  last_seen: Timestamp
  telephone: string | null
  is_email_verified: boolean
  is_account_disabled: boolean
  partner_id: number
  role_code: UserRoleCode
  image_profile: any
}

export interface IUsers extends IUser{}
