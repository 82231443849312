import { FunctionComponent, ChangeEventHandler, FocusEventHandler } from 'react';
import styled from 'styled-components';
import { colours } from '../../assets/css/variables';

import SelectArrow from '../../assets/images/icons/SelectArrow.svg';

export type SelectInputOption = {
  value: string | number | undefined
  label: string
}

type SelectInputProps = {
  id?: string
  label?: string
  autocomplete?: string
  name?: string
  options: Array<SelectInputOption>
  value?: any
  required?: boolean
  onChange?: ChangeEventHandler
  onFocus?: FocusEventHandler
  onBlur?: FocusEventHandler
}

export const SelectInput: FunctionComponent<SelectInputProps> = ({ id, label, autocomplete, name, options, value, required, onChange, onFocus, onBlur }) => {
  return (
    <>
      <StyledSelectInputContainer className={`SelectInput ${(required && label) && 'required'}`}>
        <label htmlFor={id}>{label}</label>
        <StyledSelectInput
          id={id}
          autoComplete={autocomplete}
          name={name}
          value={value || ''}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
        >
          {options.map((option: SelectInputOption, i: number) => {
            const optionLabel = option.label || "any";
            return (
              <option value={option.value} key={`${id}_option-${i}`}>{optionLabel.charAt(0).toUpperCase() + optionLabel.slice(1)}</option>
            )
          })}
        </StyledSelectInput>
      </StyledSelectInputContainer>
    </>
  );
}

const StyledSelectInputContainer = styled.div`
  &.required {
    label:after {
      content: "*";
      margin-left: 2px;
      color: ${colours.alert};
    }
  }
`

const StyledSelectInput = styled.select`
  width: 100%;
  height: 48px;
  margin: 8px 0 32px 0;
  padding: 13px;
  border-radius: 4px;
  border: 1px solid #9B9B9B;
  
  -moz-appearance:none;
  -webkit-appearance:none;
  appearance:none;

  background: no-repeat center right 13px url(${SelectArrow});

  &:active, &:focus-visible {
    border: 2px solid black;
    outline: none;
    padding: 12px;
  }

  &::placeholder {
    color: #D4D4D4;
  }

  &:last-of-type {
    margin-bottom: 8px;
  }
`