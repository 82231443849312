import { createContext, useContext, FunctionComponent } from "react";
import { ApiHelper } from "../common/ApiHelper/ApiHelper";
import { SparrowHubApiInterface, SparrowHubApiFactory } from "sparrowhub-client-axios";

// import axios from 'axios'
import axios from 'axios';

// init context
const ApiContext = createContext({});

type ApiProviderProps = {
  children: any,
}

export const ApiProvider: FunctionComponent<ApiProviderProps> = ({ children }) => {
  // init legacy API class
  const apiHelper = new ApiHelper();

  // get endpoint URL from .env variables
  let basePath = undefined;
  if (
    process.env.REACT_APP_BACKEND_API_PROTOCOL && 
    process.env.REACT_APP_BACKEND_API_HOST && 
    process.env.REACT_APP_BACKEND_API_PORT && 
    process.env.REACT_APP_BACKEND_API_PREFIX
  ) {
    basePath = `${process.env.REACT_APP_BACKEND_API_PROTOCOL}://${process.env.REACT_APP_BACKEND_API_HOST}:${process.env.REACT_APP_BACKEND_API_PORT}/${process.env.REACT_APP_BACKEND_API_PREFIX}`;
  }

  const axiosInstance = axios.create({
    baseURL: basePath,
    withCredentials: true
  });

  // init generated API class
  // const api: SparrowHubApiInterface = SparrowHubApiFactory(undefined, basePath, axios);
  const api: SparrowHubApiInterface = SparrowHubApiFactory(undefined, basePath, axiosInstance);

  return (
    <ApiContext.Provider value={{ apiHelper, api }}>
      { children }
    </ApiContext.Provider>
  );
}

// Api hook
export function useApi(): any {
  const apiContext = useContext(ApiContext);

  if (!apiContext) {
    throw new Error("ApiProvider has returned context as null");
  }

  return apiContext;
}
