import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { transitions } from '../../assets/css/variables';

import dropdownIcon from '../../assets/images/icons/Dropdown.svg';
import minusIcon from '../../assets/images/icons/MinusPlain.svg';
import plusIcon from '../../assets/images/icons/PlusPlain.svg';
import personIcon from '../../assets/images/icons/Person.svg';

export enum AccordionIcon {
  Person = 1
}

type AccordionProps = {
  label: string
  icon?: AccordionIcon
  chevronIcon?: 'none' | 'chevron' | 'plus_minus'
  chevronText?: 'none' | 'show_hide' | 'expand_hide'
  defaultOpen?: boolean
  children: any
}

export const Accordion: FunctionComponent<AccordionProps> = ({ label, icon, chevronIcon, chevronText, defaultOpen, children }) => {
  const [open, setOpen] = useState(defaultOpen);
  const [openHeight, setOpenHeight] = useState(-1);

  const iconSrc = (icon: AccordionIcon): string => {
    switch (icon) {
      case AccordionIcon.Person:
        return personIcon;
      default:
        return '';
    }
  }

  const toggleOpen = (): void => {
    if (openHeight === -1) {
      setOpenHeight(calculateOpenHeight());
      setTimeout(() => {
        setOpen(!open);
      }, 0);
    } else {
      setOpen(!open);
    }
  }

  const itemRefs: Array<HTMLElement> = [];
  
  const addItemRef = (el: HTMLElement | null): void => {
    if (el) itemRefs.push(el);
  }

  const calculateOpenHeight = (): number => {
    return itemRefs.reduce((acc, el) => {
      return acc + el.offsetHeight;
    }, 0);
  }

  return (
    <StyledAccordion className={`${open ? 'open' : 'closed'}`} style={{ height: `${openHeight}px` }}>
      <div className="Accordion_header" onClick={toggleOpen} ref={el => addItemRef(el)}>
        {icon && <img className="Accordion_icon" src={iconSrc(icon)} alt="" draggable="false" />}
        <p className="bold">{label}</p>
        
        <div className="Accordion_dropdown">
          {/* text */}
          <p className="Accordion_dropdownText bold">
            {chevronText === 'show_hide' &&
              <>{open ? 'Hide' : 'Show'}</>
            }
            {chevronText === 'expand_hide' &&
              <>{open ? 'Hide' : 'Expand'}</>
            }
          </p>

          {/* icon */}
          {chevronIcon === 'chevron' &&
            <img className="Accordion_dropdownIcon chevron" src={dropdownIcon} alt="" draggable="false" />
          }
          {chevronIcon === 'plus_minus' &&
            <img className="Accordion_dropdownIcon" src={open ? minusIcon : plusIcon} alt="" draggable="false" />
          }
        </div>
      </div>

      <div className="Accordion_body" ref={el => addItemRef(el)}>
        {children}
      </div>
    </StyledAccordion>
  );
}

Accordion.defaultProps = {
  chevronIcon: 'chevron',
  chevronText: 'none',
  defaultOpen: false
}

const StyledAccordion = styled.div`
  overflow: hidden;
  transition: height ${transitions.slow};

  &.open {
    .Accordion_header .chevron {
      transform: rotate(-180deg);
    }
  }

  &.closed {
    height: 25px !important;
  }

  .Accordion_header {
    display: flex;
    height: 25px;
    cursor: pointer;
    user-select: none;
    align-items: center;

    .Accordion_icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    p {
      margin: 0;
    }

    .Accordion_dropdown {
      margin-left: auto;
      display: flex;
      gap: 4px;
      align-items: center;

      .Accordion_dropdownIcon {
        &.chevron {
          transition: transform ${transitions.slow};
          margin-left: 5px;
        }
      }

      .Accordion_dropdownText {
        /* text-decoration: underline; */
      }
    }
  }
`