import { FunctionComponent, useEffect } from "react";
import { NavLink } from 'react-router-dom';

import { IUser } from "../types/IUsers";

import { Body } from "../components/Body/Body";
import { Heading } from "../components/Heading/Heading";


type UnknownPageProps = {
  loggedin: boolean
  user: IUser | null
  onSelectLocation: Function
  onSelectUser: Function
  onLogout: Function
  onSetShowSplash: Function
}

export const UnknownPage: FunctionComponent<UnknownPageProps> = ({ loggedin, user, onSelectLocation, onSelectUser, onLogout, onSetShowSplash }) => {
  useEffect(() => {
    onSetShowSplash(false);
  })

  return (
    <Body loggedin={loggedin} user={user} location={null} onSelectLocation={onSelectLocation} onSelectUser={onSelectUser} onLogout={onLogout}>
      <Heading heading="Unknown page" />
      <NavLink to="/">
        <p>Return home</p>
      </NavLink>
    </Body>
  );
}
