import { FunctionComponent, useRef, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import styled from 'styled-components';
import { useReactToPrint } from 'react-to-print';
import { useDummyData } from '../context/DummyDataProvider';
import { format } from "date-fns";

import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import { IUser } from "../types/IUsers";
import { IDeliveryBatches } from "../types/IDeliveryBatches";

import { Body } from "../components/Body/Body";
import { Heading } from "../components/Heading/Heading";
import { BackButton } from "../components/BackButton/BackButton";
import { Modal } from '../components/Modal/Modal';

import { useApi } from "../context/ApiProvider";
import { ApiHelper } from "../common/ApiHelper/ApiHelper";
import { SparrowHubApiInterface } from 'sparrowhub-client-axios';

import printIcon from "../assets/images/icons/Print.svg";
import closeIcon from "../assets/images/icons/Close.svg";
import { Loader } from "../components/Loader/Loader";


// configure react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';
const options = {
  cMapUrl: 'cmaps/',
  standardFontDataUrl: 'standard_fonts/',
};

type ShipmentHistoryPageProps = {
  loggedin: boolean,
  user: IUser | null,
  location: any,
  onSelectLocation: Function,
  onSelectUser: Function,
  onLogout: Function
}

export const ShipmentHistoryPage: FunctionComponent<ShipmentHistoryPageProps> = ({ loggedin, user, location, onSelectLocation, onSelectUser, onLogout }) => {
  // context
  const { apiHelper, api }: { apiHelper: ApiHelper; api: SparrowHubApiInterface } = useApi();
  const dummyData: any = useDummyData();

  // state
  const [isLoading, setIsLoading] = useState(false);
  const [showCurrentManifest, setShowCurrentManifest] = useState(false);
  const [shipmentHistoryBatches, setShipmentHistoryBatches] = useState<IDeliveryBatches[] | null>([]);
  
  const [currentManifest, setCurrentManifest] = useState(undefined as any | undefined);
  const [numPages, setNumPages] = useState(0);
  // const [shouldPrint, setShouldPrint] = useState(false);

  const printRef = useRef(null);

  // methods
  const handleViewManifest = async (shipmentHistoryBatch: any) => {
    const manifestResponse = await apiHelper.getDeliveryBatchDocument(shipmentHistoryBatch);
    const manifestUrl = URL.createObjectURL(manifestResponse.body);
    window.open(manifestUrl, '_blank')
    // setCurrentManifest(manifestUrl);
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages);
    // if (shouldPrint) {
    //   setTimeout(() => {
    //     printManifest();
    //     setShouldPrint(false);
    //   }, 500);
    // }
  }

  const handlePrintManifest = async (shipmentHistoryBatch: number) => {
    // setShouldPrint(true);
    await handleViewManifest(shipmentHistoryBatch);
  };

  const printManifest = useReactToPrint({
    content: () => printRef.current,
    bodyClass: "printable_visible",
  });

  // use useEffect hook to update the Orders state variables when data is returned from the API
  useEffect(() => {
    (async () => {
        const manifests = dummyData.state.useDummyData ? dummyData.state.manifests : [];
      if (manifests?.length) {
        setShipmentHistoryBatches(manifests);
      } else {
        // request Orders data from API
        setIsLoading(true);
        const response = await apiHelper.getLocationDeliveryBatches(
          location?.code
        );
        const data =
          typeof response.body.data === "string"
            ? JSON.parse(response.body.data)
            : response.body.data;
        const filteredShipmentHistoryBatch = data?.filter((courier : IDeliveryBatches)=> {
          return courier.courier_created_at !== null
        }).sort((a : any, b : any) => {
          return b.courier_created_at.localeCompare(a.courier_created_at)
        });
        setShipmentHistoryBatches(filteredShipmentHistoryBatch);
        setIsLoading(false);
      }
    })();
  }, [apiHelper, dummyData.state.manifests, dummyData.state.useDummyData, location?.code]);


  return (
    <>
      {!loggedin || !location ? 
        <Navigate to="/login" />
      : 
      <Body loggedin={loggedin} user={user} location={location} onSelectLocation={onSelectLocation} onSelectUser={onSelectUser} onLogout={onLogout}>
        <StyledShipmentHistoryPage className="ShipmentHistory">
          {/* <BackButton to="/send/orders/awaiting-delivery" text="Back to Awaiting Delivery" /> */}
          <Heading heading="Shipment History" />

          {shipmentHistoryBatches?.length && isLoading ? (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '4rem'}}>
              <Loader />
            </div>
          ) : (
            <>
              <div className="ShipmentHistory_table">
                <div className="row header">
                  <p className="bold">Manifest</p>
                  <p className="bold">Dispatch Date</p>
                  <p className="bold align_center">Order(s)</p>
                  </div>
                  {shipmentHistoryBatches?.length ?
                    shipmentHistoryBatches.map((item, i) => {
                      return (
                        <div className='row' key={i}>
                          <p className='medium'>{item?.courier_ref}</p>
                          {!dummyData.state.useDummyData ?
                            <p className='medium'>
                              {format(
                                new Date(item?.updated_at),
                                "dd/MM/yyyy hh:mm:ss"
                              )}
                            </p>
                          :
                            <p className='medium'>{(item as any).createdString}</p>
                          }
                          
                          <p className='medium align_center'>{item?.qty_orders}</p>
                          <p className='medium buttonContainer'>
                            {/* <span className='viewButton' onClick={() => handleViewManifest(item.id)}>
                              View
                            </span> */}
                            <img className='printButton button' src={printIcon} onClick={() => handlePrintManifest(item.id)} alt='Print shipment details' title='Print shipment details' />
                          </p>
                        </div>
                      )
                    }) : (<p>No shipment history available.</p>)}
                </div>
                <div className="printable_hidden" ref={printRef}>
                  <Document file={currentManifest} options={options} onLoadSuccess={onDocumentLoadSuccess}>
                    {Array.from(
                      new Array(numPages),
                      (el, index) => (
                        <Page
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                        />
                      ),
                    )}
                  </Document>
                </div>
                {/* <Modal show={showCurrentManifest} noPadding>
                  <img src={closeIcon} alt="" className="ShipmentHistory_modalClose button" onClick={() => setShowCurrentManifest(false)} draggable="false" />
                  <Document file={currentManifest} options={options} onLoadSuccess={onDocumentLoadSuccess}>
                    {Array.from(
                      new Array(numPages),
                      (el, index) => (
                        <Page
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                        />
                      ),
                    )}
                  </Document>
                </Modal> */}
              </>
            )}
          </StyledShipmentHistoryPage>
        </Body>
      }
    </>
  );
}

const StyledShipmentHistoryPage = styled.div`
  .ShipmentHistory_table {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 12px;

    .row {
      display: grid;
      grid-template-columns: 185px 130px 80px 1fr;
      gap: 40px;
      grid-gap: 36px;
      height: 24px;
      align-items: center;

      p {
        font-size: 0.75rem; // 12px
        margin: 0;

        &.buttonContainer {
          display: flex;

          .viewButton {
            text-decoration: underline;
            cursor: pointer;
            margin: auto 0;
            padding: 2px 5px;
          }

          .printButton {
            cursor: pointer;
            width: 28px;
            height: auto;
            margin-left: 8px;
            padding: 2px 5px;
          }
        }
      }

      &.header {
        height: 49px;

        p {
          font-size: 1rem; // 16px
        }
      }
    }
  }

  .Modal {
    position: relative;

    .ShipmentHistory_modalClose {
      z-index: 999;
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }
`