import { FunctionComponent, useEffect } from "react";
import { useParams, Params } from "react-router-dom";

import { IUser } from "../types/IUsers";

import { Body } from "../components/Body/Body";
import { AuthModal } from "../components/AuthModal/AuthModal";


type LogoutPageProps = {
  loggedin: boolean
  user: IUser | null
  onLogout: Function
  onSetShowSplash: Function
}

export const LogoutPage: FunctionComponent<LogoutPageProps> = ({ loggedin, user, onLogout, onSetShowSplash }) => {
  const { auto }: Readonly<Params<string>> = useParams();

  useEffect(() => {
    setTimeout(() => {
      onSetShowSplash(false);
    }, 1000);
  }, [ ])

  return (
    <>
      <Body loggedin={loggedin} user={user} location={null}>
        <AuthModal type={auto ? 'logout-auto' : 'logout'} onSetShowSplash={onSetShowSplash} />
      </Body>
    </>
  );
}
