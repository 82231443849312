import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { transitions } from '../../assets/css/variables';

import northIcon from '../../assets/images/icons/north.svg';

type ScrollToTopButtonProps = {
  label?: string
}

export const ScrollToTopButton: FunctionComponent<ScrollToTopButtonProps> = ({ label }) => {
  const handleClick = (): void => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  return (
    <StyledScrollToTopButton className="ScrollToTopButton_container" onClick={handleClick}>
      <div className="ScrollToTopButton_button">
        <img src={northIcon} alt="" />
        {label && <p className="bold">{label}</p>}
      </div>
    </StyledScrollToTopButton>
  );
}

ScrollToTopButton.defaultProps = {
  label: 'Back to Top'
}

const StyledScrollToTopButton = styled.div`
  position: sticky;
  bottom: 5px;

  .ScrollToTopButton_button {
    margin: -65px 17px 0 auto;
    /* padding: 10px; */
    
    width: 90px;
    height: 66px;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;

    cursor: pointer;
    user-select: none;

    transition: margin ${transitions.default}, height ${transitions.default};

    &:hover {
      margin-top: -85px;
      height: 86px;
      img {
        background-color: black;
      }
      p {
        opacity: 1;
      }
    }

    &:active:not(:disabled) {
      transform: scale(0.975);
    }

    p {
      color: black;
      margin: 0;
      font-size: 0.75rem; // 12px
      opacity: 0;
      transition: opacity ${transitions.default};
    }

    img {
      background-color: #D2D2D2;
      padding: 12px;
      border-radius: 100%;
      transition: background-color ${transitions.default};
    }
  }
`