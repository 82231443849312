import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

import { IOrder } from '../../types/IOrders';
import { IRequestBodyProcessDeliveryCancellation } from '../../types/IRequests';
import { IUser } from '../../types/IUsers';
import { IDelivery } from '../../types/IDelivery';
import { Category } from '../../types/DashboardCategories';

import { useApi } from '../../context/ApiProvider';
import { ApiHelper } from '../../common/ApiHelper/ApiHelper';
import { SparrowHubApiInterface } from 'sparrowhub-client-axios';
import { useDummyData } from '../../context/DummyDataProvider';
import { getOrderCategory } from '../../helpers/utils';

import { Modal } from '../Modal/Modal';
import { Heading } from '../Heading/Heading';
import { Button, ButtonType } from '../Button/Button';
import { Alert, AlertIcon, AlertType } from '../Alert/Alert';
import { OrderDetails } from '../OrderDetails/OrderDetails';
import { OrderBadges } from '../OrderBadges/OrderBadges';


type CancelDispatchModalProps = {
  show: boolean
  order: IOrder
  onClose: Function
  onRefresh: Function
  user: IUser
}

export const CancelDispatchModal: FunctionComponent<CancelDispatchModalProps> = ({ user, show, order, onClose, onRefresh }) => {
  const { apiHelper, api }: { apiHelper: ApiHelper; api: SparrowHubApiInterface } = useApi();
  const dummyData = useDummyData();

  // state
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);

  // methods
  const successDuration = 3000;
  const handleProcessDeliveryCancellation = async () => {
    setIsLoading(true);
    setErrorMessage('');
    const response = await apiHelper.getDeliveriesByOrder(order.id)
    const data = typeof response.body.data === "string"
          ? JSON.parse(response.body.data)
          : response.body.data;
    
    if (data.length === 0) {
      setErrorMessage(`Error cancelling delivery: ${response.body.message}`);
      setIsLoading(false);
      // handleClose();
      return;
    }

    const promises: Array<any> = [];
    data.forEach((delivery: IDelivery) => {
      if (delivery.completed_at === null) {
        const requestBody: IRequestBodyProcessDeliveryCancellation = {
          user_id: user.id,
        };
        promises.push(apiHelper.processDeliveryCancellation(delivery.id, requestBody))
      }
    })

    Promise.all(promises).then((responses) => {
      console.log(responses);
      setIsLoading(false);
      if (responses.every(response => response.status === 200)) {
        onRefresh();
        setShowSuccess(true);
        setTimeout(() => {
          handleClose();
        }, successDuration);
      } else {
        setErrorMessage(`Error cancelling delivery.`);
        console.log(responses);
      }
    });
  };

  const transitionDuration = 300;
  const handleClose = (): void => {
    setTimeout(() => {
      setShowSuccess(false);
    }, transitionDuration);

    if (onClose) onClose();
  }

  return (
    <StyledCancelDispatchModal className="CancelDispatchModal">
      <Modal show={show}>
          {!showSuccess ?
            <>
              <Heading heading={getOrderCategory(order, dummyData.state.useDummyData) === Category.DispatchCollection ? 'Are you sure you want to cancel the delivery of this order?' : 'Are you sure you want to reopen this order?'} />
              <div className="CancelDispatchModal_details divider">
                <OrderBadges order={order} />
                <OrderDetails order={order} phone email bold={false} />
              </div>
              <p className="txt-property bold">This will return the label and move the order into “Open Orders” again. This action cannot be undone and the order must be processed again.</p>
              {errorMessage &&
                <div style={{ marginTop: '20px', marginBottom: '-20px' }}>
                  <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                    <p>{errorMessage}<br/>Please try again or contact Rival Software <a href="mailto:support@sparrowhub.com.au?subject=SparrowHub%20Support%20Request" target="_blank" rel="noreferrer">here</a>.</p>
                  </Alert>
                </div>
              }
              <Button
                type={ButtonType.Primary}
                tall={true}
                text="Yes, I'm Sure"
                onClick={handleProcessDeliveryCancellation}
                loading={isLoading} 
              />
              <Button type={ButtonType.Secondary} text="Cancel" onClick={handleClose} />
            </>
          : 
            <>
              <Alert type={AlertType.PositiveSecondary} successModal>
                <p>This order has been reopened and can be found in <span className="extrabold">Open Orders &gt; New Orders</span></p>
              </Alert>
            </>
          }
        </Modal>
    </StyledCancelDispatchModal>
  );
}

const StyledCancelDispatchModal = styled.div`
  .Modal {
    position: relative;
    z-index: -1;
    
    .CancelDispatchModal_details {
      margin-top: 30px;

      .Badge {
        display: inline-flex;
        margin-right: 8px;
      }
    }

    .txt-property {
      padding-top: 30px;
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.646875rem;
    }
  }

  .Button_primary {
    margin-top: 50px;
    margin-bottom: 23px;
  }
`