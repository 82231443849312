
export class Helpers {
  // constructor() {
  // }

  /**
   * build endpoint url with or without suffix input
   * 
   * @param suffix string | null
   * @returns string
   */
  public buildEndpointUrl(suffix: string | null): string {
    let result: string = '';
  
    // get endpoint URL from .env variables
    if (
      process.env.REACT_APP_BACKEND_API_PROTOCOL && 
      process.env.REACT_APP_BACKEND_API_HOST && 
      process.env.REACT_APP_BACKEND_API_PORT && 
      process.env.REACT_APP_BACKEND_API_PREFIX
    ) {
      result = `${process.env.REACT_APP_BACKEND_API_PROTOCOL}://${process.env.REACT_APP_BACKEND_API_HOST}:${process.env.REACT_APP_BACKEND_API_PORT}/${process.env.REACT_APP_BACKEND_API_PREFIX}`;
    }

    // append provided suffix
    if (suffix?.length) result = result + suffix;

    return result;
  }

  /**
   * gets the backend endpoint's custom error code
   * 
   * @returns number | null
   */
  public getEndpointErrorCode(): number | null {
    let result: number | null = null;
  
    // get endpoint URL from .env variables
    result = process.env.REACT_APP_BACKEND_API_ERROR_CODE ? process.env.REACT_APP_BACKEND_API_ERROR_CODE : null;

    return result;
  }
}
