import { Config } from '../Config/Config';
import { ApiClient } from "../ApiClient/ApiClient";

import { LocationCode } from '../../types/ILocations';

import {
  // AUTH
  IRequestBodyRegisterUser,
  IRequestBodyLoginUser,
  // ORDERS
  IRequestBodyCreateOrder,
  IRequestBodyUpdateOrder,
  // ORDER NOTES
  IRequestBodyCreateOrderNote,
  IRequestBodyUpdateOrderNote,
  // ORDER PICKUPS
  IRequestBodyProcessPickupOrderCreation,
  IRequestBodyProcessPickupOrderCompletion,
  // DELIVERY CREATION
  IRequestBodyProcessDeliveryCreation,
  // DELIVERY CANCELLATION
  IRequestBodyProcessDeliveryCancellation,
  // REFUNDS
  IRequestBodyCreateRefund,
  IRequestBodyProcessOrderRefund,
  // DELIVERIES
  IRequestBodyProcessCollectionDeliveriesByLocation,
  IRequestBodyProcessDropoffDeliveriesByLocation,
  IRequestBodyDeliveryLabels,
  // DELIVERY BATCH
  IRequestBodyProcessCollectionDeliveryBatchByLocation,
  IRequestBodyProcessDropoffDeliveryBatchByLocation,
  IRequestBodyProcessDeliveryCompletion,
  // NOTIFICATIONS
  IRequestBodySendNotification,
} from '../../types/IRequests';

import {
  // AUTH
  IResponseRegisterUser,
  IResponseLoginUser,
  IResponseLogoutUser,
  IResponseGetCurrentUser,
  IResponseGetCurrentUserIntegrations,
  // ORDERS
  IResponseCreateOrder,
  IResponseGetOrders,
  IResponseGetOrder,
  IResponseUpdateOrder,
  IResponseDeleteOrder,
  IResponseGetPartnerOrders,
  IResponseGetLocationOrders,
  // ORDER NOTES
  IResponseCreateOrderNote,
  IResponseUpdateOrderNote,
  // ORDER PICKUPS
  IResponseProcessPickupOrderCreation,
  IResponseProcessPickupOrderCompletion,
  IResponseProcessDeliveryCreation,
  // DELIVERY CANCELLATION
  IResponseProcessDeliveryCancellation,
  IResponseDeliveryLabels,
  // LOCATIONS
  IResponseGetLocation,
  IResponseGetPartnerLocations,
  // REFUNDS
  IResponseCreateRefund,
  IResponseProcessOrderRefund,
  // DELIVERIES
  IResponseDeliveryBatch,
  IResponseDeliveryBatchDoc,
  IResponseDeliveries,
// DELIVERY PARCELS
  IResponseDeliveryParcels,
  // DELIVERIES
  IResponseProcessCollectionDeliveriesByLocation,
  IResponseProcessDropoffDeliveriesByLocation,
  // DELIVERY BATCH
  IResponseProcessCollectionDeliveryBatchByLocation,
  IResponseProcessDropoffDeliveryBatchByLocation,
  IResponseOutstandingMessage,
  // MESSAGES
  IResponseProcessMessageActioned,
  IResponseProcessDeliveryCompletion,
  // NOTIFICATIONS
  IResponseSendNotification
} from '../../types/IResponses';

export class ApiHelper {
  private apiClient: ApiClient;

  constructor() {
    this.apiClient = new ApiClient();
  }

  // ---------------------------------------------------------------------------------------- AUTHENTICATION

  /**
   * Register User
   *
   * @param requestBody IRequestBodyRegisterUser
   * @returns Promise<IResponseRegisterUser>
   */
  async registerUser(requestBody: IRequestBodyRegisterUser): Promise<IResponseRegisterUser> {
    return this.apiClient.post(Config.api.backend.auth.register.endpoint_short, requestBody, {});
  }

  /**
   * Login User
   *
   * @param requestBody IRequestBodyRegisterUser
   * @returns Promise<IResponseLoginUser>
   */
  async loginUser(requestBody: IRequestBodyLoginUser): Promise<IResponseLoginUser> {
    return this.apiClient.post(Config.api.backend.auth.login.endpoint_short, requestBody, {});
  }

  /**
   * Logout User
   *
   * @returns Promise<IResponseLogoutUser>
   */
  async logoutUser(): Promise<IResponseLogoutUser> {
    return this.apiClient.get(Config.api.backend.auth.logout.endpoint_short, '', {});
  }

  /**
   * Get Current User
   *
   * @returns Promise<IResponseGetCurrentUser>
   */
  async getCurrentUser(): Promise<IResponseGetCurrentUser> {
    return this.apiClient.get(Config.api.backend.auth.current.endpoint_short, '', {});
  }

  /**
   * Get Current User Integrations
   *
   * @returns Promise<IResponseGetCurrentUserIntegrations>
   */
  async getCurrentUserIntegrations(): Promise<IResponseGetCurrentUserIntegrations> {
    return this.apiClient.get(Config.api.backend.auth.integrations.endpoint_short, '', {});
  }

  // ---------------------------------------------------------------------------------------- ORDERS

  /**
   * Create Order
   *
   * @param requestBody IRequestBodyCreateOrder
   * @returns Promise<IResponseCreateOrder>
   */
  async createOrder(requestBody: IRequestBodyCreateOrder): Promise<IResponseCreateOrder> {
    return this.apiClient.post(Config.api.backend.orders.create.endpoint_short, requestBody, {});
  }

  /**
   * Get Orders
   *
   * @returns Promise<IResponseGetOrders>
   */
  async getOrders(): Promise<IResponseGetOrders> {
    return this.apiClient.get(Config.api.backend.orders.getAll.endpoint_short, '', {});
  }

  /**
   * Get Order
   *
   * @param orderId number
   * @returns Promise<IResponseGetOrder>
   */
  async getOrder(orderId: number): Promise<IResponseGetOrder> {
    return this.apiClient.get(Config.api.backend.orders.get.endpoint_short + orderId, '', {});
  }

  /**
   * Update Order
   *
   * @param orderId number
   * @param requestBody IRequestBodyUpdateOrder
   * @returns Promise<IResponseUpdateOrder>
   */
  async updateOrder(orderId: number, requestBody: IRequestBodyUpdateOrder): Promise<IResponseUpdateOrder> {
    return this.apiClient.put(Config.api.backend.orders.update.endpoint_short + orderId, requestBody, {});
  }

  /**
   * Delete Order
   *
   * @param orderId number
   * @returns Promise<IResponseDeleteOrder>
   */
  async deleteOrder(orderId: number): Promise<IResponseDeleteOrder> {
    return this.apiClient.delete(Config.api.backend.orders.delete.endpoint_short + orderId, {});
  }

  /**
   * Get Partner Orders
   *
   * @param partnerId number
   * @returns Promise<IResponseGetPartnerOrders>
   */
  async getPartnerOrders(partnerId: number): Promise<IResponseGetPartnerOrders> {
    return this.apiClient.get(Config.api.backend.orders.getAllForUser.endpoint_short + partnerId, '', {});
  }

  /**
   * Get Location Orders
   *
   * @param locationCode LocationCode
   * @returns Promise<IResponseGetLocationOrders>
   */
  async getLocationOrders(locationCode: LocationCode): Promise<IResponseGetLocationOrders> {
    return this.apiClient.get(Config.api.backend.orders.getAllForLocation.endpoint_short + locationCode, '', {});
  }

  // ---------------------------------------------------------------------------------------- ORDER NOTES

  /**
   * Create Order Note
   *
   * @param requestBody IRequestBodyCreateOrderNote
   * @returns Promise<IResponseCreateOrderNote>
   */
  async createOrderNote(requestBody: IRequestBodyCreateOrderNote): Promise<IResponseCreateOrderNote> {
    return this.apiClient.post(Config.api.backend.orderNotes.create.endpoint_short, requestBody, {});
  }

  /**
   * Update Order Note
   *
   * @param orderId number
   * @param requestBody IRequestBodyUpdateOrderNote
   * @returns Promise<IResponseUpdateOrderNote>
   */
  async updateOrderNote(orderId: number, requestBody: IRequestBodyUpdateOrderNote): Promise<IResponseUpdateOrderNote> {
    return this.apiClient.put(Config.api.backend.orderNotes.update.endpoint_short + orderId, requestBody, {});
  }

  // ---------------------------------------------------------------------------------------- ORDER PICKUPS

  /**
   * Process Pickup Order Creation
   *
   * @param orderId number
   * @param requestBody IRequestBodyProcessPickupOrderCreation
   * @returns Promise<IResponseProcessPickupOrderCreation>
   */
  async processPickupOrderCreation(orderId: number, requestBody: IRequestBodyProcessPickupOrderCreation): Promise<IResponseProcessPickupOrderCreation> {
    return this.apiClient.post(Config.api.backend.orderPickups.new.endpoint_short.replace('{id}', orderId), requestBody, {});
  }

  /**
   * Process Pickup Order Completion
   *
   * @param orderId number
   * @param requestBody IRequestBodyProcessPickupOrderCompletion
   * @returns Promise<IResponseProcessPickupOrderCompletion>
   */
  async processPickupOrderCompletion(
    orderId: number,
    requestBody: IRequestBodyProcessPickupOrderCompletion
  ): Promise<IResponseProcessPickupOrderCompletion> {
    return this.apiClient.post(
      Config.api.backend.orderPickups.complete.endpoint_short.replace(
        "{id}",
        orderId
      ),
      requestBody,
      {}
    );
  }

  // -------------------------------------------------------------------------- DELIVERIES

  /**
   * Process Delivery Creation
   *
   * @param orderId number
   * @param requestBody IRequestBodyProcessDeliveryCreation
   * @returns Promise<IResponseProcessDeliveryCreation>
   */
  async processDeliveryCreation(
    orderId: number,
    requestBody: IRequestBodyProcessDeliveryCreation
  ): Promise<IResponseProcessDeliveryCreation> {
    return this.apiClient.post(
      Config.api.backend.deliveries.new.endpoint_short.replace("{id}", orderId),
      requestBody,
      {}
    );
  }

  /**
   * Process Delivery Cancellation
   *
   * @param orderId number
   * @param requestBody IRequestBodyProcessDeliveryCancellation
   * @returns Promise<IResponseProcessDeliveryCancellation>
   */
  async processDeliveryCancellation(
    userId: number,
    requestBody: IRequestBodyProcessDeliveryCancellation
  ): Promise<IResponseProcessDeliveryCancellation> {
    return this.apiClient.post(
      Config.api.backend.deliveries.cancel.endpoint_short.replace("{id}", userId),
      requestBody,
      {}
    );
  }
  
  /**
   * Process Delivery Completion
   *
   * @param orderId number
   * @param requestBody IRequestBodyProcessDeliveryCompletion
   * @returns Promise<IResponseProcessDeliveryCompletion>
   */
  async processDeliveryCompletion(
    userId: number,
    requestBody: IRequestBodyProcessDeliveryCompletion
  ): Promise<IResponseProcessDeliveryCompletion> {
    return this.apiClient.post(
      Config.api.backend.deliveries.complete.endpoint_short.replace("{id}", userId),
      requestBody,
      {}
    );
  }

  // ---------------------------------------------------------------------------------------- LOCATIONS

  /**
   * Get Location
   *
   * @param locationId number
   * @returns Promise<IResponseGetLocation>
   */
  async getLocation(locationId: number): Promise<IResponseGetLocation> {
    return this.apiClient.get(Config.api.backend.locations.get.endpoint_short + locationId, '', {});
  }

  /**
   * Get Partner Locations
   *
   * @param locationId number
   * @returns Promise<IResponseGetLocation>
   */
  async getPartnerLocations(locationId: number): Promise<IResponseGetPartnerLocations> {
    return this.apiClient.get(Config.api.backend.locations.getPartnerLocations.endpoint_short + locationId, '', {});
  }

  // ---------------------------------------------------------------------------------------- REFUNDS

  /**
   * Create Refund
   *
   * @param requestBody IRequestBodyCreateRefund
   * @returns Promise<IResponseCreateRefund>
   */
  async createRefund(requestBody: IRequestBodyCreateRefund): Promise<IResponseCreateRefund> {
    return this.apiClient.post(Config.api.backend.refunds.create.endpoint_short, requestBody, {});
  }

  /**
   * Process Order Refund
   *
   * @param requestBody IRequestBodyProcessOrderRefund
   * @returns Promise<IResponseProcessOrderRefund>
   */
  async processOrderRefund(orderId: number, requestBody: IRequestBodyProcessOrderRefund): Promise<IResponseProcessOrderRefund> {
    return this.apiClient.post(Config.api.backend.refunds.processOrderRefund.endpoint_short + orderId + '/process/refund', requestBody, {});
  }

  /**
   * Get Deliveries by Order
   *
   * @param id number
   * @returns Promise<IResponseDeliveries>
   */
  async getDeliveriesByOrder(orderId: number): Promise<IResponseDeliveries> {
    return this.apiClient.get(Config.api.backend.deliveries.byOrder.endpoint_short.replace("{id}", orderId), '', {});
  }

  /**
     * Get Delivery Labels
     *
     * @param id string
     * @returns Promise<IResponseDeliveryLabels>
     */
  async getDeliveryLabels(id: string): Promise<IResponseDeliveryLabels> {
    return this.apiClient.get(Config.api.backend.deliveries.labels.endpoint_short.replace("{id}", id), '', { blob: true });
  }

/**
   * Get Location Deliver Batch
   *
   * @param id number
   * @returns Promise<IResponseDeliveryBatch>
   */
  async getDeliveryBatch(id: number): Promise<IResponseDeliveryBatch> {
    return this.apiClient.get(Config.api.backend.deliveryBatch.endpoint_short.replace("{id}", id), '', {});
  }

  /**
   * Get Location Deliver Batches
   *
   * @param code string
   * @returns Promise<IResponseDeliveryBatch>
   */
  async getLocationDeliveryBatches(code: string): Promise<IResponseDeliveryBatch> {
    return this.apiClient.get(Config.api.backend.deliveryBatches.endpoint_short.replace("{code}", code), '', {});
  }

  /**
   * Get Delivery Batch Document
   *
   * @param id string
   * @returns Promise<IResponseDeliveryBatchDoc>
   */
  async getDeliveryBatchDocument(id: string): Promise<IResponseDeliveryBatchDoc> {
    return this.apiClient.get(Config.api.backend.deliveryBatches.document.endpoint_short.replace("{id}", id), '', { blob: true });
  }

  // DELIVER PARCELS APIs
  /**
   * Get Delivery Parcels
   *
   * @returns Promise<IResponseDeliveryBatchDoc>
   */
  async getDeliveryParcels(): Promise<IResponseDeliveryParcels> {
    return this.apiClient.get(Config.api.backend.deliveryParcels.get.endpoint_short, '', {});
  }


  /**
   * Get Collection Deliveries By Location
   *
   * @param code string
   * @returns Promise<IResponseProcessCollectionDeliveriesByLocation>
   */
  async processCollectionDeliveriesByLocation(code: string): Promise<IResponseProcessCollectionDeliveriesByLocation> {
    return this.apiClient.get(Config.api.backend.deliveries.collectionByLocation.endpoint_short.replace("{code}", code),"", {});
  }

  /**
   * Get Dropoff Deliveries By Location
   *
   * @param code string
   * @returns Promise<IResponseProcessCollectionDeliveriesByLocation>
   */
  async processDropoffDeliveriesByLocation(code: string): Promise<IResponseProcessDropoffDeliveriesByLocation> {
    return this.apiClient.get(Config.api.backend.deliveries.dropoffByLocation.endpoint_short.replace("{code}", code),"", {});
  }

  /**
   * Process Collection Delivery Batch By Location
   *
   * @param code string
   * @param requestBody IRequestBodyProcessCollectionDeliveriesByLocation
   * @returns Promise<IResponseProcessCollectionDeliveriesByLocation>
   */
  async processCollectionDeliveryBatchByLocation(code: string, requestBody: IRequestBodyProcessCollectionDeliveryBatchByLocation): Promise<IResponseProcessCollectionDeliveryBatchByLocation> {
    return this.apiClient.post(Config.api.backend.deliveryBatches.collectionByLocation.endpoint_short.replace("{code}", code), requestBody, {});
  }

  /**
   * Process Dropoff Delivery Batch By Location
   *
   * @param code string
   * @param requestBody IRequestBodyProcessCollectionDeliveriesByLocation
   * @returns Promise<IResponseProcessCollectionDeliveriesByLocation>
   */
  async processDropoffDeliveryBatchByLocation(code: string, requestBody: IRequestBodyProcessDropoffDeliveryBatchByLocation): Promise<IResponseProcessDropoffDeliveryBatchByLocation> {
    return this.apiClient.post(Config.api.backend.deliveryBatches.dropoffByLocation.endpoint_short.replace("{code}", code), requestBody, {});
  }

  // --------------Todays Task--------- //
  /**
   * Get outstanding messages
   *
   * @returns Promise<IResponseProcessCollectionDeliveriesByLocation>
   */
  async getOutstandingMessages(): Promise<IResponseOutstandingMessage> {
    return this.apiClient.get(Config.api.backend.messages.endpoint_short, "", {});
  }

  /**
   * Process Message as Actioned
   *
   * @param id number
   * @returns Promise<IResponseUpdateOrder>
   */
  async processMessageActioned(id: number): Promise<IResponseProcessMessageActioned> {
    return this.apiClient.put(Config.api.backend.messages.actioned.endpoint_short.replace("{id}", id), '', {});
  }

  // ---------------------------------------------------------------------------------------- NOTIFICATIONS

  /**
   * Send Notification
   *
   * @param requestBody IRequestBodySendNotification
   * @returns Promise<IResponseSendNotification>
   */
  async sendNotification(requestBody: IRequestBodySendNotification): Promise<IResponseSendNotification> {
    return this.apiClient.post(Config.api.backend.notifications.send.endpoint_short, requestBody, {});
  }
}
