import { FunctionComponent } from "react";
import { Navigate } from "react-router-dom";

import { IUser } from "../types/IUsers";
import { ILocations } from "../types/ILocations";

import { Body } from "../components/Body/Body";
import { AuthModal } from "../components/AuthModal/AuthModal";


type LoginPageProps = {
  loggedin: boolean
  user: IUser | null
  location: any
  partnerLocations: ILocations | undefined
  selectedUser: any
  onLogin: Function
  onLogout: Function
  onSelectLocation: Function
  onSetPartnerLocations: Function
  onSetShowSplash: Function
}

export const LoginPage: FunctionComponent<LoginPageProps> = ({ loggedin, user, location, partnerLocations, selectedUser, onLogin, onLogout, onSelectLocation, onSetPartnerLocations, onSetShowSplash }) => {
  return (
    <>
      {loggedin ?
        <>
          {selectedUser ? 
            <>
              {location ? 
                <Navigate to="/" />
              :
                <Navigate to="/select-store" />
              }
            </>
          : 
            <Navigate to="/select-user" />
          }
        </>
      : 
        <Body loggedin={loggedin} user={user} location={location}>
          <AuthModal type="login" onLogin={onLogin} onSelectLocation={onSelectLocation} onSetPartnerLocations={onSetPartnerLocations} onSetShowSplash={onSetShowSplash} />
          
          <div className="recaptcha_disclaimer">
            <p>This site is protected by reCAPTCHA and the Google <a className="link" title="Google Privacy Policy" href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Privacy Policy</a> and <a className="link" href="https://policies.google.com/terms" title="Google Terms of Service" target="_blank" rel="noreferrer">Terms of Service</a> apply.</p>
          </div>
        </Body>
      }
    </>
  );
}
