import { FunctionComponent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Intercom, boot, show, shutdown, update } from "@intercom/messenger-js-sdk";

import { IUser } from '../../types/IUsers';
import { parseTimestamp } from '../../helpers/utils';
import { InitType } from '@intercom/messenger-js-sdk/dist/types';

type IntercomMessengerProps = {
  user: IUser | null
}

export const IntercomMessenger: FunctionComponent<IntercomMessengerProps> = ({ user }) => {
  const appId = 'cldnh4sy';
  const baseAttributes: InitType = {
    app_id: appId,
    // styling
    alignment: 'left',
    vertical_padding: 40,
    // vertical_padding: 75,
    horizontal_padding: 40
  };

  // state
  const prevUserId = useRef<number | null>(null);

  // watch user prop to boot & shutdown
  useEffect(() => {
    const currentUserId = (user && user.id) ? user.id : null;
    const userHasChanged = currentUserId !== prevUserId.current;

    // console.log('----- user prop updated')
    // console.log('current', user)
    // console.log('prev', prevUserId.current)

    if (userHasChanged) {
      // console.log(`user is new (${prevUserId.current} --> ${user?.id || 'null'})}`)
      prevUserId.current = user ? user.id : null;
      // console.log(`prevUser set to ${prevUserId.current}`)

      if (user === null) {
        // console.log('user has become null, reboot')
        shutdown();
        boot(baseAttributes);
      } else {
        // console.log(`user is not null (${user.email}), update`);
        update({
          app_id: appId,
          user_id: user ? user.id.toString() : undefined,
          name: user ? `${user.first_name} ${user.last_name}` : undefined,
          email: user ? user.email : undefined,
          created_at: user ? parseTimestamp(user.created_at).getTime() : undefined,
        });
      }
    } else {
      // console.log('user is the same, do nothing')
    }
  }, [ user ]);

  // init app
  Intercom(baseAttributes);
  
  return (
    <></>
  );
}

const StyledIntercomMessenger = styled.div`
  
`