import { FunctionComponent } from "react";
import { Navigate } from "react-router-dom";

import { IUser } from "../types/IUsers";
import { Product } from "../types/Products";

// import { Body } from "../components/Body/Body";
// import { Heading } from "../components/Heading/Heading";


type HomePageProps = {
  loggedin: boolean,
  user: IUser | null,
  location: any,
  selectedUser: any,
  onSelectLocation: Function,
  onSelectUser: Function,
  onLogout: Function
}

export const HomePage: FunctionComponent<HomePageProps> = ({ loggedin, user, location, selectedUser, onSelectLocation, onSelectUser, onLogout }) => {
  const defaultPage = (): string => {
    const product = localStorage.getItem('Scription_product');
    switch (product) {
      case Product.SparrowQueue:
        return '/queue/orders';
      default:
      case Product.SparrowSend:
        return '/send/orders';
    }
  }

  return (
    <>
      {!loggedin || !location || !selectedUser ?
        <Navigate to="/login" />
        :
        <>
          <Navigate to={defaultPage()} />
          {/* <Body loggedin={loggedin}>
            <Heading heading="Hello" />
          </Body> */}
        </>
      }
    </>
  );
}
