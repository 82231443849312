export interface IScriptQueueConfig {
  use_incoming_queue: boolean
  use_patient_name: boolean
  order_number_format: string
}

export const defaultScriptQueueConfig: IScriptQueueConfig = {
  use_incoming_queue: false,
  use_patient_name: false,
  order_number_format: 'alphanumeric-3'
}