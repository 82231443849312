import { FunctionComponent, useState, ChangeEvent, useEffect } from "react";
import { Navigate } from "react-router-dom";
import styled from "styled-components";
import { format } from "date-fns";
import { containers } from "../assets/css/variables";

import { useApi } from "../context/ApiProvider";
import { ApiHelper } from "../common/ApiHelper/ApiHelper";
import { SparrowHubApiInterface } from 'sparrowhub-client-axios';
import { IUser, UserRoleCode } from "../types/IUsers";

import { Body } from "../components/Body/Body";
import { Heading } from "../components/Heading/Heading";
import { InputField } from "../components/InputField/InputField";
import { SelectInput, SelectInputOption } from "../components/SelectInput/SelectInput";
import { Button, ButtonType } from "../components/Button/Button";
import { Loader } from "../components/Loader/Loader";


const partnerIdOptions: Array<SelectInputOption> = [
  {
    value: 1,
    label: '1 -- Scription (invalid)',
  },
  {
    value: 2,
    label: '2 -- Rival Software',
  },
  {
    value: 3,
    label: '3 -- Chemistworks',
  },
  {
    value: 4,
    label: '4 -- Cat & Claudia\'s Community Pharmacy',
  }
]

const roleCodeOptions: Array<SelectInputOption> = [
  {
    value: UserRoleCode.User,
    label: 'user',
  },
  {
    value: UserRoleCode.Admin,
    label: 'admin',
  }
]


type AdminPageProps = {
  loggedin: boolean,
  user: IUser | null,
  location: any,
  onSelectLocation: Function,
  onSelectUser: Function,
  onLogout: Function,
}

export const AdminPage: FunctionComponent<AdminPageProps> = ({ loggedin, user, location, onSelectLocation, onSelectUser, onLogout }) => {
  const { apiHelper, api }: { apiHelper: ApiHelper; api: SparrowHubApiInterface } = useApi();

  const [isLoading, setIsLoading] = useState(false);

  // register user
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [partnerId, setPartnerId] = useState(0);
  const [roleCode, setRoleCode] = useState(UserRoleCode.User);
  const [registerUserResponse, setRegisterUserResponse] = useState('');
  const [outstandingMessage, setOutstandingMessage] = useState<any>([]);
  const [actionMessage, setActionMessage] = useState<any>([]);

  // methods
  const handleRegisterUser = async () => {
    setIsLoading(true);
    apiHelper.registerUser({
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
      partner_id: partnerId,
      role_code: roleCode
    }).then((response) => {
      setIsLoading(false);
      setRegisterUserResponse(`${response.ok ? 'Success' : 'Error'} ${response.status}: ${response.body.data || response.body.message}`);
    });
  }

  const handleOutstandingMessages = async () => {
    setIsLoading(true);
    const response = await apiHelper.getOutstandingMessages();
    const data = typeof response.body.data === "string"
        ? JSON.parse(response.body.data)
        : response.body.data;
    setOutstandingMessage(data.reverse());  
    setIsLoading(false)
  }

  const handleActionMessages = async (id: any) => {
    setIsLoading(true);
    const response = await apiHelper.processMessageActioned(id);
    const actionData = typeof response.body.data === "string"
        ? JSON.parse(response.body.data)
        : response.body.data;
    setActionMessage(actionData);
    const newActionData = outstandingMessage?.filter((item: any) => item?.id !== id);
    setOutstandingMessage(newActionData);  
    setIsLoading(false)
  }

  useEffect(()=>{
    handleOutstandingMessages();
  }, []);
  

  return (
    <>
      {!loggedin || !location || user?.role_code !== UserRoleCode.Admin ? 
        <Navigate to="/login" />
      : 
        <>
          <Body loggedin={loggedin} user={user} location={location} onSelectLocation={onSelectLocation} onSelectUser={onSelectUser} onLogout={onLogout}>
            <Heading heading="Admin Settings page" />
            
            <StyledAdminPage className="AdminPage">
              <div className="AdminPage_settingsTile">
                <h3>Backend Operations</h3>
                <h4>Register user</h4>
                <div className="AdminPage_columns">
                  <InputField type="text" id="firstName" label="First Name" value={firstName} onChange={(e: ChangeEvent) => setFirstName((e.target as HTMLInputElement).value)} />
                  <InputField type="text" id="lastName" label="Last Name" value={lastName} onChange={(e: ChangeEvent) => setLastName((e.target as HTMLInputElement).value)} />
                  <InputField type="email" id="email" label="Email" value={email} onChange={(e: ChangeEvent) => setEmail((e.target as HTMLInputElement).value)} />
                  <InputField type="text" id="password" label="Password" value={password} onChange={(e: ChangeEvent) => setPassword((e.target as HTMLInputElement).value)} />
                  <SelectInput id="partnerId" label="Partner ID" options={partnerIdOptions} value={partnerId} onChange={(e: ChangeEvent) => setPartnerId(parseInt((e.target as HTMLInputElement).value))} />
                  {/* <SelectInput id="roleCode" label="Role Code" options={roleCodeOptions} value={roleCode} onChange={(e: ChangeEvent) => setRoleCode((e.target as HTMLInputElement).value as UserRoleCode)} /> */}
                </div>
                <Button type={ButtonType.Primary} text="Register User" onClick={handleRegisterUser} loading={isLoading} />
                {registerUserResponse &&
                  <>
                    <label>Response:</label>
                    <code className="block">{registerUserResponse}</code>
                  </>
                }
                {outstandingMessage?.length !== 0 ? (
                  <div className="message_box_section">
                  <h4>Error Log</h4>
                  <div className="message_log_box">
                      <div className="log_box_header">
                        <div className="log_box_left">
                          <div className="log_date">
                            <p className="bold">Date</p>
                          </div>
                          <div className="log_title">
                            <p className="bold">Title</p>
                          </div>
                          <div className="log_message">
                            <p className="bold">Message</p>
                          </div>
                        </div>
                          <div className="log_action">
                            <p className="bold">Action</p>
                          </div>
                      </div>
                      <div className="log_box_body_main">
                        <>
                          {isLoading === true ? (
                            <div className="loader_div">
                              <Loader />
                            </div>
                          ):(
                            <>
                              {outstandingMessage?.length && outstandingMessage.map((msg: any) => {
                                return(
                                    <div className="log_box_body">
                                      <div className="log_box_left">
                                        <div className="log_date">
                                          <p>{format(new Date(msg?.created_at), "PP hh:mm")}</p>
                                        </div>  
                                        <div className="log_title"><p>{msg?.message}</p></div>
                                        <div className="log_message"><p>{msg?.title}</p></div>
                                      </div>
                                      <div className="log_action"><p onClick={() => handleActionMessages(msg?.id)}>Action</p></div>
                                    </div>
                                )
                              })}
                            </>
                          )}
                        </>
                      </div>
                  </div>
                </div>
                ) : ( null )}
                
              </div>
            </StyledAdminPage>
          </Body>
        </>
      }
    </>
  );
}

const StyledAdminPage = styled.div`
  .AdminPage_settingsTile {
    ${containers.default}
    padding: 30px;
    margin-top: 40px;

    h3 {
      margin-top: 0;
    }

    .AdminPage_columns {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
    }

    .Button_primary {
      margin: 20px 0;
    }
    .message_box_section {
      .message_log_box {
        max-height: 686px;
        border-radius: 4px;
        border: 1px solid #9B9B9B;
        .log_box_header{
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 50px;
          border-bottom: 1px solid #9B9B9B;
            .log_box_left {
              width: 85%;
              display: flex;
              flex-direction: row;
              .log_date {
              width: 20%;
              padding-left: 10px;
              padding-right: 10px;
              font-size: 0.875rem;
              font-family: 'Inter';
              }
              .log_title {
                width: 40%;
                padding-left: 10px;
                padding-right: 10px;
                font-size: 0.875rem;
                font-family: 'Inter';
              }
              .log_title > p {
                font-size: 0.875rem;
              }
              .log_message {
                width: 40%;
                font-size: 0.875rem;
                padding-left: 10px;
                padding-right: 10px;
              }
            } 
          .log_action {
            width: 15%;
          }
        }
        .log_box_body_main {
          height: 634px;
          overflow-x: hidden;
          overflow-y: auto;
            .loader_div {
              margin-top: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
           .log_box_body{
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              .log_box_left{
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                width: 85%;
                border-right: 1px solid #9B9B9B;
                  .log_date {
                    width: 20%;
                    padding-left: 10px;
                    padding-right: 10px;
                  }
                  .log_date > p {
                    font-size: 0.657rem;
                  }
                  .log_title {
                    width: 40%;
                    text-transform: capitalize;
                    margin-top: -2px;
                    word-wrap: break-word;
                    border-left: 1px solid #9B9B9B;
                    border-right: 1px solid #9B9B9B;
                    padding-left: 10px;
                    padding-right: 10px;
                  }
                  .log_title > p {
                    font-size: 0.657rem;
                  }
                  .log_message {
                    width: 40%;
                    text-transform: capitalize;
                    margin-top: -2px;
                    word-wrap: break-word;
                    padding-left: 10px;
                    padding-right: 10px;
                    height: 100%;
                  }
                  .log_message > p {
                    font-size: 0.657rem;
                  }
                } 
            .log_action {
              width: 15%;
              padding-left: 10px;
              padding-right: 10px;
            }
            .log_action > p {
              font-size: 0.657rem;
              cursor: pointer;
            }
          }
        }
      }
      .log_date > p {
        font-size: 0.875rem;
      }
      .log_message > p {
        font-size: 0.875rem;
      }
      .log_action > p {
        font-size: 0.875rem;
      }
    }
  }
`