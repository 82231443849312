import { FunctionComponent, useRef, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { containers } from '../../assets/css/variables';
import styled from 'styled-components';


type ModalProps = {
  show: boolean
  children: any
  noPadding?: boolean
  maintainParentScroll?: boolean
}

export const Modal: FunctionComponent<ModalProps> = ({ show, children, noPadding, maintainParentScroll }) => {
  const nodeRef = useRef(null);
  
  // use hook to watch show prop update
  // disable body scroll if modal visible
  useEffect(() => {
    if (!maintainParentScroll) {
      setOverflowHidden(show);
    }
    return () => setOverflowHidden(false);
  }, [show, maintainParentScroll]);

  const setOverflowHidden = (disableScroll: boolean): void => {
    document.body.style.overflow = disableScroll ? 'hidden' : 'unset';
  }

  return (
    <CSSTransition
      in={show}
      nodeRef={nodeRef}
      timeout={400}
      classNames="modal"
      unmountOnExit
    >
      <StyledModal className="Modal_overlay" ref={nodeRef}>
        <div className="Modal" style={ noPadding ? { padding: 0 } : {}}>
          {children}
        </div>
      </StyledModal>
    </CSSTransition>
  );
}

const StyledModal = styled.div`
  z-index: 999;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.75);

  .Modal {
    width: 578px;
    height: fit-content;
    max-height: 91vh;
    margin: auto;
    overflow-y: auto;
    ${containers.default}
  }
`