import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

import { Button, ButtonType } from '../Button/Button';

import linkIcon from '../../assets/images/icons/Link.svg';

type CopyTextProps = {
  text: string
}

export const CopyText: FunctionComponent<CopyTextProps> = ({ text }) => {
  const defaultText = 'Copy';
  const copiedText = 'Copied!';
  const textDuration = 3000;

  const [buttonText, setButtonText] = useState(defaultText);

  const handleCopyToClipboard = (): void => {
    navigator.clipboard.writeText(text);
    
    setButtonText(copiedText);
    setTimeout(() => {
      setButtonText(defaultText);
    }, textDuration);
  }

  return (
    <StyledCopyText className="CopyText">
      <img className="CopyText_icon" src={linkIcon} alt="" />
      <p className="CopyText_text">{text}</p>
      <Button text={buttonText} type={ButtonType.Inline} onClick={handleCopyToClipboard}/>
    </StyledCopyText>
  );
}

const StyledCopyText = styled.div`
  display: flex;
  align-items: center;
  padding: 6px;
  gap: 15px;

  border-radius: 4px;
  border: 1px solid #E5E5E5;

  .CopyText_icon {
    border-right: 1px solid #E5E5E5;
    padding-right: 5px;
  }
  
  .CopyText_text {
    font-size: 0.875rem; // 14px
    margin: 0;
    word-break: break-all;
    flex-grow: 1;
  }

  .Button {
    min-width: 96px;
  }
`