import { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';
import { transitions, boxShadows } from '../../assets/css/variables';

import { IScriptQueueConfig } from '../../types/IScriptQueueConfig';
import { QueueElement, QueueStatuses } from 'sparrowhub-client-axios';
import { ScriptQueueCategory } from '../../types/DashboardCategories';
import { filterQueueElementsByQuery } from "../../helpers/utils";

import { DashboardCount } from '../DashboardCount/DashboardCount';

import arrowIcon from '../../assets/images/icons/Arrow.svg';
import inboxIcon from '../../assets/images/icons/Inbox.svg';
import pillIcon from '../../assets/images/icons/Pill.svg';
import pendingIcon from '../../assets/images/icons/Pending.svg';
import acuteIcon from '../../assets/images/icons/Acute.svg';


type ScriptQueueDashboardProps = {
  orders: Array<QueueElement>
  category: string
  query: string
  setCategory: Function
  setSubcategory: Function
  config: IScriptQueueConfig
}

export const ScriptQueueDashboard: FunctionComponent<ScriptQueueDashboardProps> = ({ orders, category, query, setCategory, setSubcategory, config }) => {
  const [suppressAnimation, setSuppressAnimation] = useState(false);

  // methods
  const numOrdersByCategory = (category: string, query: string): number => {
    if (orders.length === 0 || orders[0] === null) {
      return 0;
    } else {
      return filterQueueElementsByQuery(orders, query).filter((element: QueueElement) => {
        if (category === ScriptQueueCategory.New) {
          return (element.queue_status_code as any) === category || (element.queue_status_code as any) === QueueStatuses.Dispensed;
        } else {
          return (element.queue_status_code as any) === category;
        }
      }).length;
    }
  }

  // computed
  const visibleCategories = (): Array<any> => {
    let categories = [];
    // const shouldIncludeIncoming = numOrdersByCategory('new', '') !== 0 || config.use_incoming_queue;
    const shouldIncludeIncoming = config.use_incoming_queue;

    // configurable categories
    if (shouldIncludeIncoming) {
      categories.push({
        id: 'new',
        label: 'Incoming Queue',
        icon: inboxIcon
      })
      categories.push({
        id: 'dispensing_in_progress',
        label: 'Dispensing Queue',
        icon: pillIcon
      })
    } else {
      categories.push({
        id: 'new',
        label: 'Dispensing Queue',
        icon: pillIcon
      })
    }

    // always show Awaiting Collection
    categories.push({
      id: 'awaiting_collection',
      label: 'Awaiting Collection',
      icon: acuteIcon
    })

    return categories;
  }

  // watch query prop to suppress DashboardCount animation
  // when returning from search
  useEffect(() => {
    if (query !== '') {
      setSuppressAnimation(true);
    } else {
      setTimeout(() => {
        setSuppressAnimation(false);
      }, 500);
    }
  }, [query]);

  const handleSetCategory = (category: string): void => {
    setCategory(category);

    if (category === 'new') {
      setSubcategory('awaiting_processing');
    } else if (category === 'awaiting_collection') {
      setSubcategory('on_dashboard');
    }
  }

  return (
    <>
      <StyledDashboard className="Dashboard" style={{ gridTemplateColumns: `repeat(${visibleCategories().length}, 1fr)` }}>
        {visibleCategories().map((cat: any, i: number) => {
          return (
            <div
              className={`dashboard_tile ${i < 4 ? 'dashboard_tile_primary' : 'dashboard_tile_secondary'} ${cat.id === category ? 'selected' : ''}`}
              id={`tile_${cat.id}`}
              key={cat.id}
              onClick={() => handleSetCategory(cat.id)}
            >
              <div className="tile_countParent">
                <div style={{ display: 'inline', position: 'relative' }}>
                  <DashboardCount
                    value={numOrdersByCategory(cat.id, query)}
                    // small={i > 3}
                    noAnimation={query !== '' || suppressAnimation || i === 5}
                  />
                </div>
                <div style={{ display: 'inline' }}>
                  {query && <span className="tile_resultLabel bold">{numOrdersByCategory(cat.id, query) === 1 ? 'result' : 'results'}</span>}
                </div>
              </div>
              <p className="tile_label">{cat.label}</p>
              <img src={arrowIcon} alt="" className={`tile_arrow ${cat.id === category ? 'selected' : ''}`} draggable="false" />
              <img src={cat.icon} alt="" className="tile_icon" draggable="false" />
            </div>
          )
        })}
      </StyledDashboard>
    </>
  );
}

const StyledDashboard = styled.div`
  display: grid;
  grid-gap: 12px 10px;
  gap: 12px 10px;
  margin: 30px 0 30px;
  user-select: none;

  .dashboard_tile {
    position: relative;
    border: 2px solid transparent;
    cursor: pointer;
    border-radius: 6px;
    background: white;
    color: black;
    ${boxShadows.default}
    transition:
      box-shadow ${transitions.default},
      border-color ${transitions.default};

    &:hover {
      ${boxShadows.hover}
    }

    &.selected {
      border: 2px solid black;
      
      .tile_label {
        font-family: 'Mulish Bold';
      }
    }

    p {
      margin: 0;

      &.tile_label {
        font-family: 'Mulish Regular';
        font-size: 0.625rem; // 10px
      }
    }

    .tile_arrow {
      position: absolute;
      top: 16px;
      right: 15px;

      width: 14px;
      height: 10px;
      transition: opacity ${transitions.default}, margin-left ${transitions.slow};

      &.selected {
        opacity: 0;
        margin-left: -32px;
      }
    }

    .tile_icon {
      position: absolute;
      top: 12px;
      left: 13px;
      width: 19px;
      height: 19px;
    }

    &.dashboard_tile_primary {
      height: 106px;
      /* padding: 12px 6px 12px 16px; */
      padding: 11px 5px 11px 15px;
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-start;

      .tile_countParent {
        transition: margin-top ${transitions.slow};
        display: inline-block;

        .tile_resultLabel {
          font-size: 1.125rem; // 18px
          margin-left: 8px;
          display: inline-block;
        }
      }
      
      &.selected {
        padding: 11px 5px 11px 15px;

        .tile_countParent {
          margin-top: 3px;
        }
      }
    }

    &.dashboard_tile_secondary {
      height: 49px;
      grid-column: span 2;
      display: flex;
      align-items: center;
      justify-content: center;
      
      p.tile_label {
        margin: 1px 18px 0 18px;
      }

      .tile_countParent {
        display: flex;
        align-items: center;
        
        .tile_count {
          display: flex;
          align-items: center;
          display: inline-block;
        }

        .tile_resultLabel {
          font-size: 1rem; // 16px
          margin-left: 8px;
          margin-bottom: 1px;
          display: inline-block;
          margin-left: 8px;
        }
      }
    }
  }
`