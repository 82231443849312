import { FunctionComponent, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { formatPrice } from '../../helpers/utils';

import { colours, transitions } from '../../assets/css/variables';
import closeIcon from '../../assets/images/icons/CloseGrey.svg';

import { Button, ButtonType, ButtonIcon } from '../Button/Button';
import { TextTag } from '../TextTag/TextTag';

type SearchBarProps = {
  placeholder: string
  results?: Array<any>
  showShowAll?: boolean
  query?: string
  setCategory?: Function
  onSelect?: Function
  onSubmit?: Function
}

// GENERIC SEARCH BAR COMPONENT (WIP)

export const SearchBar: FunctionComponent<SearchBarProps> = ({ placeholder, results, showShowAll, query, setCategory, onSelect, onSubmit }) => {
  const [inputValue, setInputValue] = useState('');
  const [showResults, setShowResults] = useState(false);
  const inputRef = useRef(null);
  const resultsRef = useRef(null);

  // use useEffect to add and remove mouse event listeners on mount/unmount
  // and init input value to match query prop if passed
  useEffect(() => {
    document.addEventListener('pointerdown', handleClickOutside);
    return () => {
      document.removeEventListener('pointerdown', handleClickOutside);
    }
  })
  
  // update inputValue to match query if passed
  useEffect(() => {
    if (query && query !== inputValue) {
      setInputValue(query);
    }
  }, [ query ])

  // computed variables
  const topResults = (): Array<any> => {
    // return filteredSortedOrders(dummyData.state.orders, undefined, inputValue).slice(0, 4);
    return results || [];
  }

  // methods
  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const currentValue = e.target.value;
    setInputValue(currentValue);
    setShowResults(currentValue !== '');
  }

  const onFocus = (e: React.FocusEvent<HTMLInputElement>): void => {
    // setShowResults(inputValue !== '');
    // TEMP
    setShowResults(true);
  }

  const handleSelect = (index?: number): void => {
    if (index !== undefined) {
      if (onSelect && results) onSelect(results[index]);
    } else {
      if (onSubmit) onSubmit(inputValue);
    }
    setShowResults(false);
  }

  // const focusInput = (): void => {
  //   (inputRef.current! as HTMLInputElement).focus();
  // }
  
  const clearInput = (): void => {
    setInputValue('');
    if (onSubmit) onSubmit('');
    setShowResults(false);
  }

  const handleClickOutside = (event: PointerEvent): void => {
    if (
      showResults && inputRef && resultsRef &&
      !(inputRef.current as unknown as HTMLElement).contains(event.target as Node) &&
      !(resultsRef.current as unknown as HTMLElement).contains(event.target as Node)
    ) {      
      setShowResults(false);
    }
  }

  return (
    <StyledSearchBar className={`${showResults ? 'open' : 'closed'}`}>
      <div className="Search_bar">
        <input
          type="text"
          placeholder={placeholder}
          value={inputValue}
          onChange={onChange}
          onFocus={onFocus}
          ref={inputRef}
        />
        <Button type={ButtonType.Search} icon={ButtonIcon.Search} onClick={handleSelect}/>
        <img className={`Search_clear ${inputValue === '' ? 'hidden' : ''}`} src={closeIcon} alt="Clear search" onClick={clearInput} draggable="false" />
      </div>
      <div className="Search_results" ref={resultsRef}>
        {showShowAll &&
          <div className="Search_result" onClick={() => handleSelect()}>
            <p className="bold">{`Show all results for '${inputValue}'`}</p>
          </div>
        }
        {topResults().map((result: any, i: number) => {
          return (
            <div className="Search_result" onClick={() => handleSelect(i)} key={`searchResult-${result.id}`}>
              <p className="Search_orderCustomer semibold">
                <span>{result.name}</span>
                <TextTag text={result.tag}></TextTag>
              </p>
              <p className="Search_orderItems medium">
                <span>{formatPrice(result.price)}</span>
              </p>
            </div>
          )
        })}
      </div>
    </StyledSearchBar>
  );
}

const StyledSearchBar = styled.div`
  position: relative;
  height: 44px;
  margin: 21px 0 31px 0;

  .Search_bar {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    display: flex;

    input {
      flex-grow: 1;
      height: 44px;
      border-radius: 4px;
      background-color: white;
      border: none;
      padding: 12px;
      border: 1px solid #DADADA;
    }

    .Button_search {
      width: 53px !important;
      height: 44px !important;
      margin-left: 7px;
    }

    .Search_clear {
      position: absolute;
      cursor: pointer;
      right: 70px;
      top: 10px;
      transition: opacity ${transitions.default};

      &.hidden {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  .Search_results {
    position: absolute;
    width: calc(100% - 60px);
    z-index: 2;
    background-color: white;
    box-shadow: 0 0 5px 0 rgba(159, 159, 159, 0.25);
    top: 34px;
    border-radius: 0 0 6px 6px;
    transition: opacity ${transitions.default};
    overflow: hidden;

    .Search_result {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1px 15px;
      cursor: pointer;

      p {
        font-size: 0.6875rem; // 11px
        height: 100%;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 11px;
        padding-bottom: 11px;

        span:not(:last-child) {
          margin-right:12px;
        }

        &.Search_orderItems {
          position: relative;
          white-space: nowrap;
          padding-left: 10px;

          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: -20px;
            width: 20px;
            height: 100%;
            background: linear-gradient(to left, white, rgba(255, 255, 255, 0));
          }
        }

        &.Search_orderCustomer {
          flex-grow: 1;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      &:hover {
        background-color: #DADADA;

        .Search_orderItems:after {
          background: linear-gradient(to left, #DADADA, rgba(255, 255, 255, 0));
        }
      }

      &:first-child {
        padding-top: 10px;
      }
    }
  }

  &.open {
    .Search_bar {
      input {
        border-bottom: 1px solid ${colours.lightGrey};
        border-top: 1px solid transparent;
      }
    }
  }

  &.closed {
    .Search_results {
      opacity: 0;
      pointer-events: none;
    }
  }
`