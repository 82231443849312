import { FunctionComponent, useState } from "react";
import { Navigate } from "react-router-dom";
import styled from 'styled-components';

import { IUser } from "../types/IUsers";
import { sortOrders, filterOrdersByQuery } from "../helpers/utils";

import { Body } from "../components/Body/Body";
import { Heading } from "../components/Heading/Heading";
import { TelehealthSearch } from "../components/TelehealthSearch/TelehealthSearch";
import { TelehealthDashboard } from "../components/TelehealthDashboard/TelehealthDashboard";
import { TelehealthTile } from "../components/TelehealthTile/TelehealthTile";
import { Tabs, TabOption } from "../components/Tabs/Tabs";
import { Button, ButtonType } from "../components/Button/Button";


const dummyPatients = [
  // incoming patients
  {
    status_code: 'incoming',
    time_in_queue: 20,
    created_at: '2023-04-17T00:55:45.179Z',
    delivery_firstname: 'Gabriela',
    delivery_lastname: 'Friedman',
    delivery_email: 'gabriela.friedman@gmail.com',
    delivery_street: '["8 Iris Lane"]',
    delivery_city: 'Chatswood',
    delivery_postcode: '2067',
    delivery_state_code: 'NSW',
    patient_medicare: '1234 56789 1 1',
    patient_sex: 'Female',
    patient_dob: '27/01/1992',
    patient_aboriginal_torres_strait_islander: 'Yes, Aboriginal',
    patient_dva: 'No',
    patient_chronic_conditions: 'N/A',
    patient_history: 'N/A',
    patient_allergies: 'N/A',
    patient_medications: 'N/A',
    delivery_phone: '0412 345 678',
    prescription_handling: 'pharmacy',
    consult_type: 'telehealth_express_consultation',
    seen_by: 'Dr. Maysa Wozeer',
    completion_type: 'missed',
    missed_behaviour: 'notified',
    assigned: 'unassigned'
  },
  {
    status_code: 'incoming',
    time_in_queue: 68,
    created_at: '2023-04-17T00:54:45.179Z',
    delivery_firstname: 'Isabela',
    delivery_lastname: 'Brennan',
    delivery_email: 'isabela.brennan@gmail.com',
    delivery_street: '["2 Taylor Street"]',
    delivery_city: 'Gordon',
    delivery_postcode: '2072',
    delivery_state_code: 'NSW',
    patient_medicare: '1234 56789 1 1',
    patient_sex: 'Female',
    patient_dob: '06/03/1991',
    patient_aboriginal_torres_strait_islander: 'No',
    patient_dva: 'No',
    patient_chronic_conditions: 'N/A',
    patient_history: 'N/A',
    patient_allergies: 'N/A',
    patient_medications: 'N/A',
    delivery_phone: '0412 345 678',
    prescription_handling: 'pharmacy',
    consult_type: 'telehealth_express_consultation',
    seen_by: 'Dr. Alex Watson',
    completion_type: 'complete',
    missed_behaviour: 'cancelled',
    assigned: 'unassigned'
  },
  {
    status_code: 'incoming',
    time_in_queue: 136,
    created_at: '2023-04-17T00:53:45.179Z',
    delivery_firstname: 'Jaidyn',
    delivery_lastname: 'Carrillo',
    delivery_email: 'jaidyn.carrillo@gmail.com',
    delivery_street: '["144 Hawthorne Parade"]',
    delivery_city: 'Haberfield',
    delivery_postcode: '2045',
    delivery_state_code: 'NSW',
    patient_medicare: '1234 56789 1 1',
    patient_sex: 'Male',
    patient_dob: '22/05/2002',
    patient_aboriginal_torres_strait_islander: 'No',
    patient_dva: 'No',
    patient_chronic_conditions: 'N/A',
    patient_history: 'N/A',
    patient_allergies: 'N/A',
    patient_medications: 'N/A',
    delivery_phone: '0412 345 678',
    // prescription_handling: 'escript',
    prescription_handling: 'pharmacy',
    consult_type: 'telehealth_script_renewal',
    seen_by: 'Dr. Maysa Wozeer',
    completion_type: 'complete',
    missed_behaviour: 'notified',
    assigned: 'unassigned'
  },
  // patient queue
  {
    status_code: 'in_queue',
    time_in_queue: 198,
    created_at: '2023-04-17T00:52:45.179Z',
    delivery_firstname: 'Mckenzie',
    delivery_lastname: 'George',
    delivery_email: 'mckenzie.george@gmail.com',
    delivery_street: '["393 Galston Road"]',
    delivery_city: 'Arcadia',
    delivery_postcode: '2159',
    delivery_state_code: 'NSW',
    patient_medicare: '1234 56789 1 1',
    patient_sex: 'Female',
    patient_dob: '04/09/1991',
    patient_aboriginal_torres_strait_islander: 'No',
    patient_dva: 'No',
    patient_chronic_conditions: 'N/A',
    patient_history: 'N/A',
    patient_allergies: 'N/A',
    patient_medications: 'N/A',
    delivery_phone: '0412 345 678',
    prescription_handling: 'pharmacy',
    consult_type: 'telehealth_script_renewal',
    seen_by: 'Dr. Timothy Busuttil',
    completion_type: 'complete',
    missed_behaviour: 'notified',
    assigned: 'unassigned'
  },
  {
    status_code: 'in_queue',
    time_in_queue: 269,
    created_at: '2023-04-17T00:51:45.179Z',
    delivery_firstname: 'Michelle',
    delivery_lastname: 'Ochoa',
    delivery_email: 'michelle.ochoa@gmail.com',
    delivery_street: '["11 Sutherland Road"]',
    delivery_city: 'Beecroft',
    delivery_postcode: '2119',
    delivery_state_code: 'NSW',
    patient_medicare: '1234 56789 1 1',
    patient_sex: 'Female',
    patient_dob: '25/06/1994',
    patient_aboriginal_torres_strait_islander: 'No',
    patient_dva: 'Yes',
    patient_chronic_conditions: 'N/A',
    patient_history: 'N/A',
    patient_allergies: 'N/A',
    patient_medications: 'N/A',
    delivery_phone: '0412 345 678',
    prescription_handling: 'pharmacy',
    consult_type: 'telehealth_express_consultation',
    seen_by: 'Dr. Maysa Wozeer',
    completion_type: 'complete',
    missed_behaviour: 'cancelled',
    assigned: 'unassigned'
  },
  {
    status_code: 'in_queue',
    time_in_queue: 314,
    created_at: '2023-04-17T00:50:45.179Z',
    delivery_firstname: 'Antonio',
    delivery_lastname: 'Sylvester',
    delivery_email: 'antonio.s@gmail.com',
    delivery_street: '["20 John Street"]',
    delivery_city: 'Annangrove',
    delivery_postcode: '2156',
    delivery_state_code: 'NSW',
    patient_medicare: '1234 56789 1 1',
    patient_sex: 'Male',
    patient_dob: '25/07/1988',
    patient_aboriginal_torres_strait_islander: 'Yes, Torres Strait Islander',
    patient_dva: 'No',
    patient_chronic_conditions: 'N/A',
    patient_history: 'N/A',
    patient_allergies: 'N/A',
    patient_medications: 'N/A',
    delivery_phone: '0412 345 678',
    prescription_handling: 'pharmacy',
    consult_type: 'telehealth_script_renewal',
    seen_by: 'Dr. Timothy Busuttil',
    completion_type: 'complete',
    missed_behaviour: 'notified',
    assigned: 'unassigned'
  },
  {
    status_code: 'in_queue',
    time_in_queue: 382,
    created_at: '2023-04-17T00:49:45.179Z',
    delivery_firstname: 'Jazlene',
    delivery_lastname: 'Sweeney',
    delivery_email: 'jazlene.sweeney@gmail.com',
    delivery_street: '["104 Melba Drive"]',
    delivery_city: 'East Ryde',
    delivery_postcode: '2113',
    delivery_state_code: 'NSW',
    patient_medicare: '1234 56789 1 1',
    patient_sex: 'Female',
    patient_dob: '08/07/1995',
    patient_aboriginal_torres_strait_islander: 'No',
    patient_dva: 'No',
    patient_chronic_conditions: 'N/A',
    patient_history: 'N/A',
    patient_allergies: 'N/A',
    patient_medications: 'N/A',
    delivery_phone: '0412 345 678',
    prescription_handling: 'pharmacy',
    consult_type: 'telehealth_express_consultation',
    seen_by: 'Dr. Alex Watson',
    completion_type: 'complete',
    missed_behaviour: 'notified',
    assigned: 'unassigned'
  },
  {
    status_code: 'in_queue',
    time_in_queue: 428,
    created_at: '2023-04-17T00:48:45.179Z',
    delivery_firstname: 'Liam',
    delivery_lastname: 'Mcmahon',
    delivery_email: 'liam.mcmahon@gmail.com',
    delivery_street: '["9 Vicar Street"]',
    delivery_city: 'Coogee',
    delivery_postcode: '2034',
    delivery_state_code: 'NSW',
    patient_medicare: '1234 56789 1 1',
    patient_sex: 'Male',
    patient_dob: '10/03/1989',
    patient_aboriginal_torres_strait_islander: 'Yes, Both',
    patient_dva: 'No',
    patient_chronic_conditions: 'N/A',
    patient_history: 'N/A',
    patient_allergies: 'N/A',
    patient_medications: 'N/A',
    delivery_phone: '0412 345 678',
    prescription_handling: 'pharmacy',
    consult_type: 'telehealth_express_consultation',
    seen_by: 'Dr. Maysa Wozeer',
    completion_type: 'complete',
    missed_behaviour: 'notified',
    assigned: 'unassigned'
  },
  {
    status_code: 'in_queue',
    time_in_queue: 502,
    created_at: '2023-04-17T00:47:45.179Z',
    delivery_firstname: 'Giselle',
    delivery_lastname: 'Solomon',
    delivery_email: 'giselle.solomon@gmail.com',
    delivery_street: '["12 Sunden Way"]',
    delivery_city: 'Epping',
    delivery_postcode: '2121',
    delivery_state_code: 'NSW',
    patient_medicare: '1234 56789 1 1',
    patient_sex: 'Female',
    patient_dob: '25/07/1989',
    patient_aboriginal_torres_strait_islander: 'No',
    patient_dva: 'No',
    patient_chronic_conditions: 'N/A',
    patient_history: 'N/A',
    patient_allergies: 'N/A',
    patient_medications: 'N/A',
    delivery_phone: '0412 345 678',
    // prescription_handling: 'escript',
    prescription_handling: 'pharmacy',
    consult_type: 'telehealth_script_renewal',
    seen_by: 'Dr. Maysa Wozeer',
    completion_type: 'missed',
    missed_behaviour: 'notified',
    assigned: 'unassigned'
  },
  // complete patients
  {
    status_code: 'complete',
    time_in_queue: 628,
    created_at: '2023-04-16T05:11:45.180Z',
    delivery_firstname: 'Ana',
    delivery_lastname: 'Lambert',
    delivery_email: 'ana.lambert@gmail.com',
    delivery_street: '["162 Pretoria Parade"]',
    delivery_city: 'Hornsby',
    delivery_postcode: '2077',
    delivery_state_code: 'NSW',
    patient_medicare: '1234 56789 1 1',
    patient_sex: 'Female',
    patient_dob: '30/06/1985',
    patient_aboriginal_torres_strait_islander: 'No',
    patient_dva: 'Yes',
    patient_chronic_conditions: 'N/A',
    patient_history: 'N/A',
    patient_allergies: 'N/A',
    patient_medications: 'N/A',
    delivery_phone: '0412 345 678',
    prescription_handling: 'pharmacy',
    consult_type: 'telehealth_express_consultation',
    seen_by: 'Dr. Timothy Busuttil',
    completion_type: 'complete',
    missed_behaviour: 'cancelled',
    assigned: 'unassigned'
  },
  {
    status_code: 'complete',
    time_in_queue: 1083,
    created_at: '2023-04-16T01:30:45.180Z',
    delivery_firstname: 'Mallory',
    delivery_lastname: 'Massey',
    delivery_email: 'mallory.massey@gmail.com',
    delivery_street: '["39 Delmar Parade"]',
    delivery_city: 'Gladesville',
    delivery_postcode: '2111',
    delivery_state_code: 'NSW',
    patient_medicare: '1234 56789 1 1',
    patient_sex: 'Female',
    patient_dob: '14/04/1991',
    patient_aboriginal_torres_strait_islander: 'No',
    patient_dva: 'No',
    patient_chronic_conditions: 'N/A',
    patient_history: 'N/A',
    patient_allergies: 'N/A',
    patient_medications: 'N/A',
    delivery_phone: '0412 345 678',
    prescription_handling: 'pharmacy',
    consult_type: 'telehealth_script_renewal',
    seen_by: 'Dr. Alex Watson',
    completion_type: 'complete',
    missed_behaviour: 'notified',
    assigned: 'unassigned'
  },
  {
    status_code: 'complete',
    time_in_queue: 980,
    created_at: '2023-04-16T20:16:45.180Z',
    delivery_firstname: 'Jeffrey',
    delivery_lastname: 'Fuller',
    delivery_email: 'jeffrey.fuller@gmail.com',
    delivery_street: '["355 Blaxland Road"]',
    delivery_city: 'Ryde',
    delivery_postcode: '2112',
    delivery_state_code: 'NSW',
    patient_medicare: '1234 56789 1 1',
    patient_sex: 'Male',
    patient_dob: '08/09/1973',
    patient_aboriginal_torres_strait_islander: 'No',
    patient_dva: 'No',
    patient_chronic_conditions: 'N/A',
    patient_history: 'N/A',
    patient_allergies: 'N/A',
    patient_medications: 'N/A',
    delivery_phone: '0412 345 678',
    // prescription_handling: 'escript',
    prescription_handling: 'pharmacy',
    consult_type: 'telehealth_express_consultation',
    seen_by: 'Dr. Maysa Wozeer',
    completion_type: 'complete',
    missed_behaviour: 'notified',
    assigned: 'unassigned'
  },
  {
    status_code: 'complete',
    time_in_queue: 401,
    created_at: '2023-04-16T16:22:45.180Z',
    delivery_firstname: 'Alexzander',
    delivery_lastname: 'Hatfield',
    delivery_email: 'alexzander.hatfield@gmail.com',
    delivery_street: '["22 Darnley Street"]',
    delivery_city: 'Gordon',
    delivery_postcode: '2072',
    delivery_state_code: 'NSW',
    patient_medicare: '1234 56789 1 1',
    patient_sex: 'Male',
    patient_dob: '28/05/1991',
    patient_aboriginal_torres_strait_islander: 'No',
    patient_dva: 'No',
    patient_chronic_conditions: 'N/A',
    patient_history: 'N/A',
    patient_allergies: 'N/A',
    patient_medications: 'N/A',
    delivery_phone: '0412 345 678',
    prescription_handling: 'pharmacy',
    consult_type: 'telehealth_script_renewal',
    seen_by: 'Dr. Alex Watson',
    completion_type: 'missed',
    missed_behaviour: 'notified',
    assigned: 'unassigned'
  },
  {
    status_code: 'complete',
    time_in_queue: 511,
    created_at: '2023-04-16T10:58:45.180Z',
    delivery_firstname: 'Dayami',
    delivery_lastname: 'Ellison',
    delivery_email: 'dayami.ellison@gmail.com',
    delivery_street: '["27 Hill Crest Avenue"]',
    delivery_city: 'Gladesville',
    delivery_postcode: '2111',
    delivery_state_code: 'NSW',
    patient_medicare: '1234 56789 1 1',
    patient_sex: 'Female',
    patient_dob: '01/01/1977',
    patient_aboriginal_torres_strait_islander: 'No',
    patient_dva: 'Yes',
    patient_chronic_conditions: 'N/A',
    patient_history: 'N/A',
    patient_allergies: 'N/A',
    patient_medications: 'N/A',
    delivery_phone: '0412 345 678',
    prescription_handling: 'pharmacy',
    consult_type: 'telehealth_script_renewal',
    seen_by: 'Dr. Maysa Wozeer',
    completion_type: 'complete',
    missed_behaviour: 'notified',
    assigned: 'unassigned'
  },
  {
    status_code: 'complete',
    time_in_queue: 709,
    created_at: '2023-04-16T16:45:45.180Z',
    delivery_firstname: 'Christian',
    delivery_lastname: 'White',
    delivery_email: 'christian.white@gmail.com',
    delivery_street: '["4 Hurlstone Avenue"]',
    delivery_city: 'Summer Hill',
    delivery_postcode: '2130',
    delivery_state_code: 'NSW',
    patient_medicare: '1234 56789 1 1',
    patient_sex: 'Male',
    patient_dob: '05/08/1990',
    patient_aboriginal_torres_strait_islander: 'Yes, Aboriginal',
    patient_dva: 'No',
    patient_chronic_conditions: 'N/A',
    patient_history: 'N/A',
    patient_allergies: 'N/A',
    patient_medications: 'N/A',
    delivery_phone: '0412 345 678',
    prescription_handling: 'pharmacy',
    consult_type: 'telehealth_script_renewal',
    seen_by: 'Dr. Alex Watson',
    completion_type: 'missed',
    missed_behaviour: 'notified',
    assigned: 'unassigned'
  },
  {
    status_code: 'complete',
    time_in_queue: 438,
    created_at: '2023-04-16T14:46:45.180Z',
    delivery_firstname: 'Caitlyn',
    delivery_lastname: 'Lawrence',
    delivery_email: 'caitlyn.lawrence@gmail.com',
    delivery_street: '["101 Schofields Road"]',
    delivery_city: 'The Ponds',
    delivery_postcode: '2769',
    delivery_state_code: 'NSW',
    patient_medicare: '1234 56789 1 1',
    patient_sex: 'Female',
    patient_dob: '06/08/1977',
    patient_aboriginal_torres_strait_islander: 'No',
    patient_dva: 'No',
    patient_chronic_conditions: 'N/A',
    patient_history: 'N/A',
    patient_allergies: 'N/A',
    patient_medications: 'N/A',
    delivery_phone: '0412 345 678',
    // prescription_handling: 'escript',
    prescription_handling: 'pharmacy',
    consult_type: 'telehealth_script_renewal',
    seen_by: 'Dr. Alex Watson',
    completion_type: 'complete',
    missed_behaviour: 'notified',
    assigned: 'unassigned'
  },
  {
    status_code: 'complete',
    time_in_queue: 1138,
    created_at: '2023-04-16T18:41:45.180Z',
    delivery_firstname: 'Izabella',
    delivery_lastname: 'Bruce',
    delivery_email: 'izabella.bruce@gmail.com',
    delivery_street: '["32 David Avenue"]',
    delivery_city: 'East Ryde',
    delivery_postcode: '2113',
    delivery_state_code: 'NSW',
    patient_medicare: '1234 56789 1 1',
    patient_sex: 'Female',
    patient_dob: '02/06/1974',
    patient_aboriginal_torres_strait_islander: 'No',
    patient_dva: 'No',
    patient_chronic_conditions: 'N/A',
    patient_history: 'N/A',
    patient_allergies: 'N/A',
    patient_medications: 'N/A',
    delivery_phone: '0412 345 678',
    // prescription_handling: 'escript',
    prescription_handling: 'pharmacy',
    consult_type: 'telehealth_script_renewal',
    seen_by: 'Dr. Timothy Busuttil',
    completion_type: 'complete',
    missed_behaviour: 'notified',
    assigned: 'unassigned'
  },
  {
    status_code: 'complete',
    time_in_queue: 154,
    created_at: '2023-04-16T12:33:45.180Z',
    delivery_firstname: 'Tate',
    delivery_lastname: 'Swanson',
    delivery_email: 'tate.swanson@gmail.com',
    delivery_street: '["26 Duncan Crescent"]',
    delivery_city: 'Collaroy',
    delivery_postcode: '2097',
    delivery_state_code: 'NSW',
    patient_medicare: '1234 56789 1 1',
    patient_sex: 'Male',
    patient_dob: '20/09/2000',
    patient_aboriginal_torres_strait_islander: 'No',
    patient_dva: 'Yes',
    patient_chronic_conditions: 'N/A',
    patient_history: 'N/A',
    patient_allergies: 'N/A',
    patient_medications: 'N/A',
    delivery_phone: '0412 345 678',
    prescription_handling: 'pharmacy',
    consult_type: 'telehealth_express_consultation',
    seen_by: 'Dr. Maysa Wozeer',
    completion_type: 'complete',
    missed_behaviour: 'notified',
    assigned: 'unassigned'
  },
  {
    status_code: 'complete',
    time_in_queue: 896,
    created_at: '2023-04-16T20:04:45.180Z',
    delivery_firstname: 'Brenda',
    delivery_lastname: 'Mendoza',
    delivery_email: 'brenda.mendoza@gmail.com',
    delivery_street: '["720A Old Northern Road"]',
    delivery_city: 'Dural',
    delivery_postcode: '2158',
    delivery_state_code: 'NSW',
    patient_medicare: '1234 56789 1 1',
    patient_sex: 'Female',
    patient_dob: '05/05/1983',
    patient_aboriginal_torres_strait_islander: 'No',
    patient_dva: 'No',
    patient_chronic_conditions: 'N/A',
    patient_history: 'N/A',
    patient_allergies: 'N/A',
    patient_medications: 'N/A',
    delivery_phone: '0412 345 678',
    // prescription_handling: 'escript',
    prescription_handling: 'pharmacy',
    consult_type: 'telehealth_express_consultation',
    seen_by: 'Dr. Maysa Wozeer',
    completion_type: 'missed',
    missed_behaviour: 'cancelled',
    assigned: 'unassigned'
  },
  {
    status_code: 'complete',
    time_in_queue: 617,
    created_at: '2023-04-16T16:32:45.180Z',
    delivery_firstname: 'Anastasia',
    delivery_lastname: 'Tucker',
    delivery_email: 'anastasia.tucker@gmail.com',
    delivery_street: '["10 Duntroon Avenue"]',
    delivery_city: 'Crows Nest',
    delivery_postcode: '2065',
    delivery_state_code: 'NSW',
    patient_medicare: '1234 56789 1 1',
    patient_sex: 'Female',
    patient_dob: '02/09/1989',
    patient_aboriginal_torres_strait_islander: 'No',
    patient_dva: 'No',
    patient_chronic_conditions: 'N/A',
    patient_history: 'N/A',
    patient_allergies: 'N/A',
    patient_medications: 'N/A',
    delivery_phone: '0412 345 678',
    // prescription_handling: 'escript',
    prescription_handling: 'pharmacy',
    consult_type: 'telehealth_script_renewal',
    seen_by: 'Dr. Alex Watson',
    completion_type: 'complete',
    missed_behaviour: 'notified',
    assigned: 'unassigned'
  }
];

// const currentMinutes = new Date().getMinutes();
// const getTimestamp = (hourOffset: number): string => {
//   const date = new Date();
//   date.setMinutes(currentMinutes + (hourOffset * 60));
//   return date.toISOString();
// }

const generateDummyData = (dummyPatients: any): Array<any> => {
  const result: Array<any> = [];
  dummyPatients.forEach((patient: any, i: number) => {
    const id = i + 1;

    // let timeInQueue = 0;
    // let createdAt = '';
    // if (patient.status_code === 'complete') {
    //   timeInQueue = Math.floor(randomRange(135, 1200));
    //   createdAt = getTimestamp(randomRange(-0.1, -24));
    // } else {
    //   timeInQueue = i * 60 + Math.floor(randomRange(0, 30));
    //   createdAt = getTimestamp(-(timeInQueue / 60 / 60))
    // }

    const newPatient = {
      ...JSON.parse(JSON.stringify(patient)),
      id: id,
      platform_order_no: String(id + 200).padStart(8, '0'),
      script_email: `${String(id * id * 34231231231233).substring(2, 8)}@scripts.scription.au`,
      // time_in_queue: timeInQueue,
      // created_at: createdAt,
    }
    result.push(newPatient);
  });
  return result;
}


type TelehealthPageProps = {
  loggedin: boolean,
  user: IUser | null,
  location: any,
  onSelectLocation: Function,
  onSelectUser: Function,
  onLogout: Function
}

export const TelehealthPage: FunctionComponent<TelehealthPageProps> = ({ loggedin, user, location, onSelectLocation, onSelectUser, onLogout }) => {
  const [category, setCategory] = useState('incoming');
  const [subcategory, setSubcategory] = useState('complete');
  const [query, setQuery] = useState('');
  const [patients, setPatients] = useState(generateDummyData(dummyPatients));

  const categoryPatients = () => {
    const byQuery = filterOrdersByQuery(patients, query);
    const byCategory = byQuery.filter(patient => patient.status_code === category);
    return byCategory;
  }

  const filteredPatients = category === 'complete' || category === 'in_queue'
    ? categoryPatients().filter((patient: any) => {
      if (category === 'complete') {
        return patient.completion_type === subcategory
      } else if (category === 'in_queue') {
        return patient.assigned === subcategory
      }
    })
    : categoryPatients();
  const filteredSortedPatients = sortOrders(filteredPatients, category === 'complete' ? ['created-reverse'] : ['created']);

  const completeTabOptions: Array<TabOption> = [
    {
      id: 'complete',
      value: 'complete',
      label: 'Successfully Completed',
      count: categoryPatients().filter((patient: any) => patient.completion_type === 'complete').length
    },
    {
      id: 'missed',
      value: 'missed',
      label: 'Missed Appointments',
      count: categoryPatients().filter((patient: any) => patient.completion_type === 'missed').length
    }
  ]
  
  const queueTabOptions: Array<TabOption> = [
    {
      id: 'assigned',
      value: 'assigned',
      label: 'My Appointments',
      count: categoryPatients().filter((patient: any) => patient.assigned === 'assigned').length
    },
    {
      id: 'unassigned',
      value: 'unassigned',
      label: 'All Appointments',
      count: categoryPatients().filter((patient: any) => patient.assigned === 'unassigned').length
    }
  ]

  const handleSetCategory = (category: string): void => {
    if (category === 'complete') setSubcategory('complete');
    if (category === 'in_queue') setSubcategory('assigned');
    setCategory(category);
  }

  const handleAssignPatient = (): void => {
    const mutatedPatients = JSON.parse(JSON.stringify(patients));
    const patientIndex = mutatedPatients.findIndex((patient: any) => {
      return patient.status_code === 'in_queue' && patient.assigned === 'unassigned'
    });
    mutatedPatients[patientIndex].assigned = 'assigned';
    setPatients(mutatedPatients);
  }

  return (
    <>
      {!loggedin || !location ? 
        <Navigate to="/login" />
      : 
        <>
          <Body loggedin={loggedin} user={user} location={location} onSelectLocation={onSelectLocation} onSelectUser={onSelectUser} onLogout={onLogout}>
            <Heading heading="SparrowConsult" />
            
            <StyledTelehealthPage>
              <TelehealthSearch orders={patients} setQuery={setQuery} setCategory={handleSetCategory} />
              <TelehealthDashboard patients={patients} category={category} query={query} setCategory={handleSetCategory} />
              {category === 'complete' && <Tabs options={completeTabOptions} current={subcategory} onSelectTab={setSubcategory} /> }
              {category === 'in_queue' &&
                <>
                  <Tabs options={queueTabOptions} current={subcategory} onSelectTab={setSubcategory} />
                  {subcategory === 'assigned' &&
                    <div style={{ marginTop: '25px' }}>
                      <Button type={ButtonType.Primary} text="Add Patient to My Queue" onClick={handleAssignPatient} disabled={queueTabOptions[0].count !== 0} />
                    </div>
                  }
                </>
              }

              {filteredSortedPatients.map((patient: any) => {
                return (
                  <div key={`${patient.id}-${patient.customer_firstname}`}>
                    <TelehealthTile order={patient} patients={patients} setPatients={setPatients} />
                  </div>
                )
              })}

            </StyledTelehealthPage>
          </Body>
        </>
      }
    </>
  );
}

const StyledTelehealthPage = styled.div`
  
`