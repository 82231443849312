import { FunctionComponent } from 'react';
import styled from 'styled-components';

import backspaceIcon from "../../assets/images/icons/Backspace.svg";

interface KeypadKey {
  value: string
  label: string
}

const keypadKeys: Array<KeypadKey> = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '+', label: '+' },
  { value: '0', label: '0' },
  { value: 'DEL', label: 'DEL' },
]

type KeypadInputProps = {
  onInput: Function
}

export const KeypadInput: FunctionComponent<KeypadInputProps> = ({ onInput }) => {
  const handleInput = (value: string): void => {
    if (onInput) onInput(value);
  }

  return (
    <>
      <StyledKeypadInput>
        <div className="Keypad_grid">
          {keypadKeys.map((key: KeypadKey, i: number) => {
            return (
              <div className="Keypad_key button" key={`keypadKey_${i}`} onClick={() => handleInput(key.value)}>
                {key.label === 'DEL' ?
                  <img src={backspaceIcon} alt="" draggable="false" />
                :
                  <p className="bold">{key.label}</p>
                }
              </div>
            )
          })}
        </div>
      </StyledKeypadInput>
    </>
  );
}

const StyledKeypadInput = styled.div`
  width: 100%;
  margin: 0 auto;

  .Keypad_grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    grid-gap: 10px;

    .Keypad_key {
      padding: 20px;
      text-align: center;
      background: #EFEFEF;
      border-radius: 8px;

      p {
        font-size: 1.5625rem; // 25px
        margin: 0;
      }
    }
  }
`