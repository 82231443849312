import { FunctionComponent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { transitions } from '../../assets/css/variables';

import { filterOrdersByDate, parseTimestamp } from '../../helpers/utils';

import { QueueElement } from 'sparrowhub-client-axios';

import refreshIcon from '../../assets/images/icons/Refresh.svg';

enum PeriodOptions {
  Today = 'today',
  Yesterday = 'yesterday',
  Last3Hours = 'last 3 hours'
}

type ScriptQueueStatsProps = {
  orders: Array<QueueElement> | null
}

export const ScriptQueueStats: FunctionComponent<ScriptQueueStatsProps> = ({ orders }) => {
  // state
  const [refresh, setRefresh] = useState(0);
  const [waitTimeToday, setWaitTimeToday] = useState<number | null>(null);
  const [waitTimeYesterday, setWaitTimeYesterday] = useState<number | null>(null);
  const [difference, setDifference] = useState<number | null>(null);
  const [ordersProcessed, setOrdersProcessed] = useState<number | null>(null);
  const [scriptsProcessed, setScriptsProcessed] = useState<number | null>(null);

  // methods
  const getProcessedOrdersBetweenDates = (orders: Array<QueueElement> | null, periodStart: Date | null, periodEnd: Date | null): Array<QueueElement> => {
    if (orders === null) return [];
    const filteredOrders = orders.filter(order => {
      if (!order.processed_at) return false;
      const processedSameDay = parseTimestamp(order.created_at).setHours(0, 0, 0, 0) === parseTimestamp(order.processed_at).setHours(0, 0, 0, 0);
      return processedSameDay && !order.was_on_hold;
    });
    return filterOrdersByDate(filteredOrders, periodStart, periodEnd);
  }

  const getAverageWaitTime = (orders: Array<QueueElement>): number => {
    let totalWaitTime = 0;
    let totalOrders = 0;

    orders.forEach(order => {
      if (order.processed_at) {
        const waitTime = parseTimestamp(order.processed_at).getTime() - parseTimestamp(order.created_at).getTime();
        totalWaitTime += waitTime;
        totalOrders += 1;
      }
    });

    if (totalWaitTime === 0 && totalOrders === 0) {
      return 0;
    } else {
      return totalWaitTime / totalOrders;
    }
  }

  const getStartEndDates = (period: PeriodOptions): [Date, Date] => {
    let endDate = new Date();
    let startDate = new Date();

    switch (period) {
      case PeriodOptions.Today:
        // set start date to 00:00, end date is now
        startDate.setHours(0, 0, 0, 0);
        break;
      case PeriodOptions.Yesterday:
        // set start date to 00:00 yesterday, end date is 00:00 today
        startDate.setHours(-24, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);
        break;
      case PeriodOptions.Last3Hours:
        // set start date to 3 hours ago, end date is now
        startDate.setHours(startDate.getHours() - 3, startDate.getMinutes(), startDate.getSeconds(), startDate.getMilliseconds());
        break;
    }

    return [startDate, endDate];
  }

  const formatTime = (millis: number): string => {
    const hours = Math.floor(Math.abs(millis) / (1000 * 60 * 60));
    const minutes = Math.floor((Math.abs(millis) % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.ceil((Math.abs(millis) % (1000 * 60)) / 1000);

    const hoursPad = hours.toString().padStart(2, '0');
    const minutesPad = minutes.toString().padStart(2, '0');
    const secondsPad = seconds.toString().padStart(2, '0');
    
    return hours === 0
      ? `${minutesPad}:${secondsPad}`
      : `${hoursPad}:${minutesPad}:${secondsPad}`;
  };

  const updateState = (): void => {
    // get dates
    const [ todayStart, todayEnd ] = getStartEndDates(PeriodOptions.Today);
    const [ yesterdayStart, yesterdayEnd ] = getStartEndDates(PeriodOptions.Yesterday);

    // get filtered orders
    const filteredOrdersToday = getProcessedOrdersBetweenDates(orders, todayStart, todayEnd);
    const filteredOrdersYesterday = getProcessedOrdersBetweenDates(orders, yesterdayStart, yesterdayEnd);

    // calculate scripts processed today
    let scriptsProcessedToday = 0;
    filteredOrdersToday.forEach(order => {
      order.queue_items?.forEach(item => {
        scriptsProcessedToday += item.qty;
      })
    })

    // calculate wait times
    const waitTimeToday = getAverageWaitTime(filteredOrdersToday);
    const waitTimeYesterday = getAverageWaitTime(filteredOrdersYesterday);

    // set state
    setWaitTimeToday(waitTimeToday);
    setWaitTimeYesterday(waitTimeYesterday);
    setDifference(waitTimeToday - waitTimeYesterday);
    setOrdersProcessed(filteredOrdersToday.length);
    setScriptsProcessed(scriptsProcessedToday);
  }

  const refreshStats = (): void => {
    setRefresh(val => val + 1);
    const el = document.getElementById('spinner');
    if (el) {
      el.classList.toggle('animate');
      setTimeout(() => {
        el.classList.toggle('animate');
      }, 0);
    }
  }

  // hooks
  useEffect(() => {
    updateState();
  }, [orders, refresh])

  return (
    <StyledScriptQueueStats className="ScriptQueueStats_parent">
      <div className="ScriptQueueStats">
        <div className="ScriptQueueStats_container">
          <p className="heading semibold">Average wait time</p>
          <p className="value medium">{waitTimeToday !== null ? formatTime(waitTimeToday) : formatTime(0)}</p>
          <p className={`difference semibold ${difference !== null && difference > 0 ? 'red' : 'green'}`}>{difference !== null ? `${difference > 0 ? '+' : '-'}${formatTime(difference)}` : formatTime(0)}</p>
          <p className="differenceCaption semibold">vs yesterday's average</p>

          <div className="ScriptQueueStats_tooltip">
            <p className="bold">Average Wait Time</p>
            <p className="medium">This is a measurement of time between order created and customer notified.</p>
            <p className="medium">The average excludes orders that have been placed on hold, or that were created and processed on different days.</p>
          </div>
        </div>

        <div className="ScriptQueueStats_container">
          <p className="heading semibold">Total orders processed</p>
          <p className="value medium secondary">{ordersProcessed}</p>

          <div className="ScriptQueueStats_tooltip">
            <p className="bold">Total orders processed</p>
            <p className="medium">This is a count of Queue orders that have been created and marked as ready for collection since 00:00 today.</p>
          </div>
        </div>

        <div className="ScriptQueueStats_container">
          <p className="heading semibold">Total scripts processed</p>
          <p className="value medium secondary">{scriptsProcessed}</p>

          <div className="ScriptQueueStats_tooltip">
            <p className="bold">Total scripts processed</p>
            <p className="medium">This is a count of all prescriptions in orders processed since 00:00 today.</p>
          </div>
        </div>

        <div className="ScriptQueueStats_refresh">
          <p onClick={refreshStats}>
            <img id="spinner" className="animate" src={refreshIcon} alt="" />
            Refresh
          </p>
        </div>
      </div>
    </StyledScriptQueueStats>
  );
}

const StyledScriptQueueStats = styled.div`
  position: sticky;
  top: 191px;

  .ScriptQueueStats {
    position: absolute;
    width: 113px;
    right: -149px;

    display: flex;
    flex-direction: column;
    gap: 5px;

    .ScriptQueueStats_container {
      position: relative;
      background: #202A49;
      color: white;
      border-radius: 6px;
      padding: 12px 10px 10px 10px;

      display: flex;
      gap: 2px;
      flex-direction: column;
      align-items: center;
      text-align: center;

      cursor: default;

      p {
        margin: 0;
      }

      .heading {
        text-transform: uppercase;
        font-size: 0.5rem; // 8px
        letter-spacing: 0.5px;
      }

      .value {
        color: #5DD0F5;
        font-size: 1.875rem; // 30px

        &.secondary {
          font-size: 1.1875rem; // 19px
          margin-top: 3px;
        }
      }

      .difference {
        &.red {
          color: #FF5F5F;
        }

        &.green {
          color: #25DE7C;
        }
      }

      .differenceCaption {
        font-size: 0.4375rem; // 7px
        margin-top: 3px;
        margin-bottom: 2px;
      }

      .ScriptQueueStats_tooltip {
        position: absolute;
        right: -190px;
        top: 50%;
        width: 180px;
        pointer-events: none;

        opacity: 0;
        background: white;
        border: 2px solid #D5DEE3;
        border-radius: 6px;
        padding: 12px;
        transition: opacity ${transitions.default};
        transform: translateY(-50%);

        p {
          color: #202A49;
          text-align: left;
          margin-bottom: 4px;

          &.bold {
            font-size: 0.5625rem; // 9px
          }

          &.medium {
            font-size: 0.5rem; // 8px
          }
        }
      }
      
      &:hover {
        .ScriptQueueStats_tooltip {
          opacity: 1;
        }
      }
    }
    
    .ScriptQueueStats_refresh {
      .animate {
        animation-name: rotate;
        animation-duration: 1s;
        animation-timing-function: ease;
        animation-iteration-count: infinte;
      }

      p {
        margin: 0;
        padding: 5px 0;
        float: right;
        font-size: 0.625rem; // 10px
        text-decoration: underline;
        cursor: pointer;
        display: flex;
        gap: 4px;
        user-select: none;
      }
    }
  }
`