import { FunctionComponent, useEffect, useState } from "react";
import { Navigate, useNavigate, Params, useParams } from "react-router-dom";
import styled from 'styled-components';

import { ApiHelper } from "../common/ApiHelper/ApiHelper";
import { SparrowHubApiInterface } from 'sparrowhub-client-axios';
import { useApi } from "../context/ApiProvider";
import { filterQueueElementsByCategory, filterQueueElementsByQuery, sortQueueElements } from "../helpers/utils";

import { l18n } from '../common/l18n';
import { IUser } from "../types/IUsers";
import { ScriptQueueCategory, ScriptQueueSubcategory } from "../types/DashboardCategories";
import { IScriptQueueConfig } from "../types/IScriptQueueConfig";
import { QueueElement, QueueStatuses } from 'sparrowhub-client-axios';
import { ScriptQueueStats } from "../components/ScriptQueueStats/ScriptQueueStats";

import { Body } from "../components/Body/Body";
import { Heading } from "../components/Heading/Heading";
import { ScriptQueueSearch } from "../components/ScriptQueueSearch/ScriptQueueSearch";
import { ScriptQueueDashboard } from "../components/ScriptQueueDashboard/ScriptQueueDashboard";
import { ScriptQueueTile } from "../components/ScriptQueueTile/ScriptQueueTile";
import { Button, ButtonColour, ButtonType } from "../components/Button/Button";
import { Tabs, TabOption } from "../components/Tabs/Tabs";
import { Alert, AlertIcon, AlertType } from "../components/Alert/Alert";

const useLocalDummyData = false;

const dummyOrders = [
  // incoming patients
  {
    status_code: 'new',
    type: 'scriptQueue_inStore',
    time_in_queue: 20,
    created_at: '2023-04-17T00:55:45.179',
    numScripts: 2,
    numEscripts: 2,
    delivery_firstname: 'Gabriela',
    delivery_lastname: 'Friedman',
    delivery_email: 'gabriela.friedman@gmail.com',
    delivery_phone: '0412 345 678',
    notes: [{ note: 'Customer wants to purchase [enter over the counter product here]. They are experiencing a severe headache.' }]
  },
  {
    status_code: 'new',
    type: 'scriptQueue_inStore',
    time_in_queue: 68,
    created_at: '2023-04-17T00:54:45.179',
    numScripts: 1,
    numEscripts: 1,
    delivery_firstname: 'Isabela',
    delivery_lastname: 'Brennan',
    delivery_email: 'isabela.brennan@gmail.com',
    delivery_phone: '0412 345 678',
    notes: []
  },
  // dispensing
  {
    status_code: 'awaiting_processing',
    type: 'scriptQueue_inStore',
    time_in_queue: 136,
    created_at: '2023-04-17T00:53:45.179',
    numScripts: 1,
    numEscripts: 1,
    delivery_firstname: 'Jaidyn',
    delivery_lastname: 'Carrillo',
    delivery_email: 'jaidyn.carrillo@gmail.com',
    delivery_phone: '0412 345 678',
    notes: []
  },
  // on_hold
  {
    status_code: 'on_hold',
    type: 'scriptQueue_inStore',
    time_in_queue: 136,
    created_at: '2023-04-17T00:53:15.179',
    numScripts: 1,
    numEscripts: 1,
    delivery_firstname: 'Grant',
    delivery_lastname: 'Burgess',
    delivery_email: 'grant.burgess@gmail.com',
    delivery_phone: '0412 345 678',
    notes: []
  },
  // pre collection
  {
    status_code: 'awaiting_review',
    type: 'scriptQueue_inStore',
    time_in_queue: 198,
    created_at: '2023-04-17T00:52:45.179',
    numScripts: 3,
    numEscripts: 3,
    delivery_firstname: 'Mckenzie',
    delivery_lastname: 'George',
    delivery_email: 'mckenzie.george@gmail.com',
    delivery_phone: '0412 345 678',
    notes: []
  },
  {
    status_code: 'awaiting_review',
    type: 'scriptQueue_inStore',
    time_in_queue: 269,
    created_at: '2023-04-17T00:51:45.179',
    numScripts: 2,
    numEscripts: 2,
    delivery_firstname: 'Michelle',
    delivery_lastname: 'Ochoa',
    delivery_email: 'michelle.ochoa@gmail.com',
    delivery_phone: '0412 345 678',
    notes: []
  },
  {
    status_code: 'awaiting_review',
    type: 'scriptQueue_inStore',
    time_in_queue: 314,
    created_at: '2023-04-17T00:50:45.179',
    numScripts: 1,
    numEscripts: 1,
    delivery_firstname: 'Antonio',
    delivery_lastname: 'Sylvester',
    delivery_email: 'antonio.s@gmail.com',
    delivery_phone: '0412 345 678',
    notes: []
  },
  // awaiting collection
  {
    status_code: 'awaiting_pickup',
    type: 'scriptQueue_inStore',
    time_in_queue: 382,
    created_at: '2023-04-17T00:49:45.179',
    numScripts: 1,
    numEscripts: 1,
    delivery_firstname: 'Jazlene',
    delivery_lastname: 'Sweeney',
    delivery_email: 'jazlene.sweeney@gmail.com',
    delivery_phone: '0412 345 678',
    notes: []
  },
  {
    status_code: 'awaiting_pickup',
    type: 'scriptQueue_inStore',
    time_in_queue: 428,
    created_at: '2023-04-17T00:48:45.179',
    numScripts: 2,
    numEscripts: 2,
    delivery_firstname: 'Liam',
    delivery_lastname: 'Mcmahon',
    delivery_email: 'liam.mcmahon@gmail.com',
    delivery_phone: '0412 345 678',
    notes: []
  },
  // complete
  {
    status_code: 'complete',
    type: 'scriptQueue_inStore',
    time_in_queue: 896,
    created_at: '2023-04-16T20:04:45.180',
    numScripts: 2,
    numEscripts: 2,
    delivery_firstname: 'Brenda',
    delivery_lastname: 'Mendoza',
    delivery_email: 'brenda.mendoza@gmail.com',
    delivery_phone: '0412 345 678',
    notes: []
  },
  {
    status_code: 'complete',
    type: 'scriptQueue_inStore',
    time_in_queue: 617,
    created_at: '2023-04-16T16:32:45.180',
    numScripts: 1,
    numEscripts: 1,
    delivery_firstname: 'Anastasia',
    delivery_lastname: 'Tucker',
    delivery_email: 'anastasia.tucker@gmail.com',
    delivery_phone: '0412 345 678',
    notes: []
  }
];

// const currentMinutes = new Date().getMinutes();
// const getTimestamp = (hourOffset: number): string => {
//   const date = new Date();
//   date.setMinutes(currentMinutes + (hourOffset * 60));
//   return date.toISOString();
// }

const generateDummyData = (dummyOrders: any): Array<any> => {
  const result: Array<any> = [];
  dummyOrders.forEach((patient: any, i: number) => {
    const id = i + 1;

    // let timeInQueue = 0;
    // let createdAt = '';
    // if (patient.status_code === 'complete') {
    //   timeInQueue = Math.floor(randomRange(135, 1200));
    //   createdAt = getTimestamp(randomRange(-0.1, -24));
    // } else {
    //   timeInQueue = i * 60 + Math.floor(randomRange(0, 30));
    //   createdAt = getTimestamp(-(timeInQueue / 60 / 60))
    // }

    const newPatient = {
      ...JSON.parse(JSON.stringify(patient)),
      id: id,
      platform_order_no: String(id).padStart(5, '0'),
      // time_in_queue: timeInQueue,
      // created_at: createdAt,
    }
    result.push(newPatient);
  });
  return result;
}


type ScriptQueuePageProps = {
  archived?: boolean
  orders: Array<QueueElement> | null
  setOrders: Function
  loggedin: boolean
  user: IUser | null
  location: any
  onSelectLocation: Function
  onSelectUser: Function
  onLogout: Function
  config: IScriptQueueConfig
}

const categoryFromParam = (categoryParam: string | undefined, validParams: Array<string>): ScriptQueueCategory => {
  if (categoryParam === undefined) return ScriptQueueCategory.New;
  const paramIndex = validParams.findIndex(param => param === categoryParam);
  if (paramIndex !== -1) {
    return Object.values(ScriptQueueCategory)[paramIndex];
  } else {
    window.history.replaceState(null, l18n.label.website_name, '/queue/orders');
    return ScriptQueueCategory.New;
  }
}

const defaultSubcategory = (category: ScriptQueueCategory): ScriptQueueSubcategory => {
  switch (category) {
    case ScriptQueueCategory.New:
      return ScriptQueueSubcategory.AwaitingProcessing;
    default:
      return ScriptQueueSubcategory.AwaitingProcessing;
  }
}

export const ScriptQueuePage: FunctionComponent<ScriptQueuePageProps> = ({ archived, orders, setOrders, loggedin, user, location, onSelectLocation, onSelectUser, onLogout, config }) => {
  const { categoryParam }: Readonly<Params<string>> = useParams();
  const navigate = useNavigate();

  // converted from snake case to kebab case
  const validParams = Object.values(ScriptQueueCategory).map((category: ScriptQueueCategory) => {
    return (category as string).replaceAll('_', '-');
  });

  const { apiHelper, api }: { apiHelper: ApiHelper; api: SparrowHubApiInterface } = useApi();

  const [category, setCategory] = useState(categoryFromParam(categoryParam, validParams));
  const [subcategory, setSubcategory] = useState(defaultSubcategory(category));
  const [query, setQuery] = useState('');
  const [ordersKey, setOrdersKey] = useState(0);
  const [refreshKey, setRefreshKey] = useState(1);
  const [lastSync, setLastSync] = useState(0);
  const [initialRefresh, setInitialRefresh] = useState(true);
  const [searchInput, setSearchInput] = useState('');

  const [errorMessage, setErrorMessage] = useState('');

  // confirm user is valid
  useEffect(() => {
    apiHelper.getCurrentUser()
      .then(response => {
        if (response.status !== 200) {
          onLogout();
          navigate('/logout/auto');
        }
      })
      .catch(error => {
        onLogout();
        navigate('/logout/auto');
      })
  }, [])

  // retrieve order data
  useEffect(() => {
    (async () => {
      if (!useLocalDummyData) {
        if (!location) {
          setOrders([]);
        } else {
          if (ordersKey !== refreshKey) {
            const orderRefreshDelay = 5 * 1000;
            const delay = (orders === null || orders.length === 0 || !initialRefresh) ? 0 : orderRefreshDelay;
            setTimeout(() => {
              setErrorMessage('');
              api.getQueueElementsByLocationID(location.id)
                .then(response => {
                  const data = JSON.parse((response as any).data.data);
                  setOrders(data);
                  setOrdersKey(refreshKey);
                  setInitialRefresh(false);
                })
                .catch(error => {
                  console.log('error');
                  console.log(error);
                  setErrorMessage('Error retrieving orders.')
                  setOrdersKey(refreshKey);
                  setInitialRefresh(false);
                })
            }, delay);
          }
        }

      } else {
        const dOrders = generateDummyData(dummyOrders);
        setOrders(generateDummyData(dOrders));
      }
    })();
  }, [ordersKey, refreshKey]);

  // init rolling order sync
  const refreshInterval = 1000 * 60 * 1;
  const pingInterval = 1000 * 20;

  const safeToRefresh = (): boolean => {
    // don't refresh again if already refreshing
    const notRefreshing = ordersKey === refreshKey;

    // don't refresh if user is currently searching
    // const notSearching = searchInput === '' && query === '';

    // don't refresh if any modals or dropdowns are open
    // const noModals = !showScheduleDeliveryModal;
    // const noTileModals = !tileModalOpen;

    return (
      notRefreshing
      // notSearching &&
      // noModals &&
      // noTileModals
    );
  }

  // automatically refresh orders
  useEffect(() => {
    const interval = setInterval(() => {
      const timeSinceLast = new Date().getTime() - lastSync;

      if (timeSinceLast > refreshInterval && safeToRefresh()) {
        apiHelper.getCurrentUser().then(response => {
          if (response.status === 200) {
            refreshOrders();
          } else {
            onLogout();
            navigate('/logout/auto');
          }
        });
      }
    }, pingInterval);
    return () => clearInterval(interval);
  }, [
    // deps must include all props referenced in safeToRefresh
    lastSync,
    ordersKey,
    refreshKey,
    // query,
    // searchInput,
    // showScheduleDeliveryModal,
    // tileModalOpen
  ])

  useEffect(() => {
    if (ordersKey === refreshKey) {
      setLastSync(new Date().getTime());
    }
  }, [ordersKey, refreshKey])

  const refreshOrders = (): void => {
    setRefreshKey(ordersKey + 1);
  }

  const categoryOrders = (categoryArg?: string): Array<QueueElement> => {
    if (!orders) return [];
    const cat = categoryArg || category;
    const byQuery = filterQueueElementsByQuery(orders, query);
    const byCategory = filterQueueElementsByCategory(byQuery, cat);
    // const byCategory = byQuery.filter(order => {
    //   if (cat === 'archived') {
    //     return order.queue_status_code === QueueStatuses.Collected || order.queue_status_code === QueueStatuses.Cancelled;
    //   } else {
    //     return order.queue_status_code === cat
    //   }
    // });
    return byCategory;
  }

  const filteredOrders = (): Array<QueueElement> => {
    if (archived) {
      return categoryOrders(ScriptQueueCategory.Archived);
    } else if (category === QueueStatuses.New) {
      switch (subcategory) {
        case ScriptQueueSubcategory.AwaitingProcessing:
          return categoryOrders().filter(element => element.is_on_hold === false);
        case ScriptQueueSubcategory.OnHold:
          return categoryOrders().filter(element => element.is_on_hold);
        case ScriptQueueSubcategory.PreCollection:
          return categoryOrders(QueueStatuses.Dispensed);
        default:
          return [];
      }
    } else {
      return categoryOrders();
    }
  }

  const filteredSortedOrders = sortQueueElements(filteredOrders(), ['created']);

  const handleAddOrder = (): void => {
    navigate(`/queue/process/add-script`);
  }

  const dispensingTabOptions: Array<TabOption> = [
    {
      id: 'awaiting_processing',
      value: 'awaiting_processing',
      label: 'Active',
      count: categoryOrders(QueueStatuses.New).filter(element => !element.is_on_hold).length
    },
    {
      id: 'on_hold',
      value: 'on_hold',
      label: 'On Hold',
      count: categoryOrders(QueueStatuses.New).filter(element => element.is_on_hold).length
    },
    {
      id: 'pre_collection',
      value: 'pre_collection',
      label: 'Pre-Collection',
      count: categoryOrders(QueueStatuses.Dispensed).length
    }
  ]

  return (
    <>
      {!loggedin || !location ?
        <Navigate to="/login" />
        :
        <>
          <Body loggedin={loggedin} user={user} location={location} onSelectLocation={onSelectLocation} onSelectUser={onSelectUser} onLogout={onLogout}>
            <StyledScriptQueuePage>
              <Heading heading={archived ? 'Archived Orders' : location.name} label={archived ? ' ' : 'You are dispatching from:'} showChangeLocation onSelectLocation={onSelectLocation} showRefreshOrders onRefresh={refreshOrders} refreshKey={refreshKey} ordersKey={ordersKey} />
              <ScriptQueueSearch setSearchInput={setSearchInput} setQuery={setQuery} setCategory={setCategory} setSubcategory={setSubcategory} orders={orders} config={config} />
              {!archived &&
                <>
                  {(orders !== null && orders.length !== 0 && orders[0] !== null) &&
                    <ScriptQueueStats orders={orders} />
                  }
                  <ScriptQueueDashboard orders={orders || []} category={category} query={query} setCategory={setCategory} setSubcategory={setSubcategory} config={config} />
                  <Button type={ButtonType.Primary} colour={ButtonColour.Green} text="Add New Order" onClick={handleAddOrder} disabled={!orders} />
                </>
              }

              {(orders !== null && orders.length !== 0 && orders[0] !== null) &&
                <>
                  {!archived &&
                    <>
                      {category === QueueStatuses.New &&
                        <Tabs options={dispensingTabOptions} current={subcategory} onSelectTab={setSubcategory} />
                      }
                    </>
                  }

                  {filteredSortedOrders.map((order: any) => {
                    return (
                      <div key={`${order.id}`}>
                        <ScriptQueueTile order={order} location={location} orders={orders || []} setOrders={setOrders} user={user} config={config} />
                      </div>
                    )
                  })}
                </>
              }

              {errorMessage &&
                <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                  <p>{errorMessage}<br />Please try again or contact Rival Software <a href="mailto:support@sparrowhub.com.au?subject=SparrowHub%20Support%20Request" target="_blank" rel="noreferrer">here</a>.</p>
                </Alert>
              }
            </StyledScriptQueuePage>
          </Body>
        </>
      }
    </>
  );
}

const StyledScriptQueuePage = styled.div`
  .Button_Green {
    margin-bottom: 35px;
  }
`