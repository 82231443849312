"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * SparrowHub API
 * <br> <img src=\"https://cdn.sparrowhub.com.au/assets/headers/sparrowhub-api-header.png\" alt=\"SparrowHub API by Rival Software\" style=\"max-width: 400px;margin-left: -1.25rem;\" />  The SparrowHub API, developed by Rival Software, is a comprehensive solution designed to streamline the operations of e-commerce pharmacies. It provides a robust platform for managing various aspects of the e-commerce process, including order fulfillment, refunds, notifications, prescription processing, and more.  The API is organized into several collections, each focusing on a specific area of functionality. The \'Authentication\' collection handles user registration and login, while the \'Users\' and \'Locations\' collections manage user profiles and location data respectively.  The \'Orders\' collection is central to the API, providing endpoints for creating, retrieving, and updating orders. It also includes functionality for managing order notes and pickups. The \'Refunds\' collection provides endpoints for processing refunds and managing refund notes.  The \'Couriers\' and \'Deliveries\' collections handle all aspects of delivery, from courier management to delivery batches and parcels.  The \'Scripts\' collection is specifically designed for the SparrowScripts frontend application, and proxies for the ScriptServe API.  The SparrowHub API uses OpenAPI specification v3.0.3 and is available in three environments: a local docker dev container for testing, an internal staging server for testing, and a main production server. This ensures a smooth transition from development to production, and allows for thorough testing at each stage.
 *
 * The version of the OpenAPI document: 0.32.0
 * Contact: dev@rivalsoftware.com.au
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.operationServerMap = exports.RequiredError = exports.BaseAPI = exports.COLLECTION_FORMATS = exports.BASE_PATH = void 0;
var axios_1 = require("axios");
exports.BASE_PATH = "https://s.sparrowhub.com.au/api/v1".replace(/\/+$/, "");
/**
 *
 * @export
 */
exports.COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};
/**
 *
 * @export
 * @class BaseAPI
 */
var BaseAPI = /** @class */ (function () {
    function BaseAPI(configuration, basePath, axios) {
        if (basePath === void 0) { basePath = exports.BASE_PATH; }
        if (axios === void 0) { axios = axios_1.default; }
        var _a;
        this.basePath = basePath;
        this.axios = axios;
        if (configuration) {
            this.configuration = configuration;
            this.basePath = (_a = configuration.basePath) !== null && _a !== void 0 ? _a : basePath;
        }
    }
    return BaseAPI;
}());
exports.BaseAPI = BaseAPI;
;
/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
var RequiredError = /** @class */ (function (_super) {
    __extends(RequiredError, _super);
    function RequiredError(field, msg) {
        var _this = _super.call(this, msg) || this;
        _this.field = field;
        _this.name = "RequiredError";
        return _this;
    }
    return RequiredError;
}(Error));
exports.RequiredError = RequiredError;
/**
 *
 * @export
 */
exports.operationServerMap = {};
