import { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { UserRoleCode } from '../../types/IUsers';

import { l18n } from '../../common/l18n'
import { useDummyData } from '../../context/DummyDataProvider';

import rivalLogo from '../../assets/images/logos/Rival.png';

/**
 * footer copyright
 * 
 * @returns string
 */
function Copyright(props: any): React.ReactElement {
  return (
    <p className="medium">
      &copy;{' '}{new Date().getFullYear()}{' '}
      <a color="inherit" href="https://sparrowhub.com.au/" target="_blank" rel="noreferrer">
        {l18n.label.website_name}
      </a>
      {'. All rights reserved.'}
    </p>
  );
}

type FooterProps = {
  userRole: UserRoleCode | undefined
}

export const Footer: FunctionComponent<FooterProps> = ({ userRole }) => {
  const dummyData: any = useDummyData();

  const toggleUseDummyData = (value: any): void => {
    dummyData.mutations.setUseDummyData(!dummyData.state.useDummyData);
  }
  
  return (
    <StyledFooter>
      <div className="footer">
        <div>
          <Copyright />
          {userRole === UserRoleCode.Admin &&
            <p className="dummyDataToggle" onClick={toggleUseDummyData}>Using {dummyData.state.useDummyData ? 'Test' : 'Live'} Data</p>
          }
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p className="medium">Made by</p>
            <a href="https://rivalsoftware.com.au/" target="_blank" rel="noreferrer" style={{ height: '20px' }}>
              <img src={rivalLogo} alt="Rival Software" />
            </a>
        </div>
      </div>
    </StyledFooter>
  );
}

const StyledFooter = styled.footer`
  background: white;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);

  .footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 15px 20px;

    p {
      margin: 0;
      font-size: 0.625rem; // 10px
      display: inline;
    }

    a {
      font-size: 0.625rem; // 10px
    }

    img {
      margin-left: 5px;
      height: 20px;
      width: auto;
    }

    .dummyDataToggle {
      display: inline;
      margin-left: 5px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`