import { createContext, useContext, FunctionComponent, useState } from "react";

import { ILocation } from "../types/ILocations";
import { IOrders, OrderDeliveryType, OrderStatus, AddressCountry, AddressState, DeliveryModelCode, OrderState, CourierType } from "../types/IOrders";
import { IUser, UserRoleCode } from "../types/IUsers";
import { getCurrentTimestamp } from "../helpers/utils";

import smithsLogo from '../assets/images/logos/PlaceholderPharmacy.svg';
import pricelineLogo from '../assets/images/logos/logo-priceline-rounded.png';

const testDataItem = localStorage.getItem('Scription_useTestData');
const defaultUseDummyData = testDataItem !== null && testDataItem !== undefined;

if (defaultUseDummyData) {
  console.log('Using Test Data');
}

// supports 'smiths' or 'priceline'
const placeholderPharmacy = 'smiths'

const DummyDataContext = createContext({});

type DummyDataProviderProps = {
  children: any,
}

export const dummyUser: IUser = {
  id: 20,
  first_name: "Test",
  last_name: "User",
  email: "testuser@rivalsoftware.com.au",
  created_at: "2023-04-04T03:50:32",
  is_account_disabled: false,
  is_email_verified: false,
  last_seen: "2023-04-04T03:50:32",
  partner_id: 3,
  // role_code: UserRoleCode.User,
  role_code: UserRoleCode.Admin,
  telephone: null,
  image_profile: null
};

const getDummyLocationData = (locationKey: 'priceline' | 'smiths'): any => {
  if (locationKey === 'smiths') {
    return {
      name: 'Smith\'s Pharmacy York Street',
      pharmacyName: 'Smith\'s Pharmacy',
      email: 'yorkstreet@smithspharmacy.com.au',
      domain: 'smithspharmacy.com.au',
      street: '["624 York St"]',
      city: 'Sydney',
      postcode: '2000',
      logo: smithsLogo,
    }
  }

  if (locationKey === 'priceline') {
    return {
      name: 'Priceline Broadway',
      pharmacyName: 'Priceline',
      email: 'broadway@priceline.com.au',
      domain: 'priceline.com.au',
      street: '["Shop G20A/1 Bay St"]',
      city: 'Ultimo',
      postcode: '2007',
      logo: pricelineLogo
    }
  }
}

const dummyLocationData = getDummyLocationData(placeholderPharmacy);
export const dummyLocation: ILocation = {
  id: 3,
  created_at: '2023-04-13 00:57:42',
  updated_at: null,
  code: 'cw-broadway',
  name: dummyLocationData.name,
  contact_name: dummyLocationData.name,
  contact_email: dummyLocationData.email,
  contact_phone: '0412 345 678',
  is_pickup_location: true,
  is_enabled: true,
  address_id: 3,
  partner_id: 3,
  address: {
    id: 3,
    created_at: '2023-04-13 00:57:42',
    updated_at: null,
    name: dummyLocationData.name,
    partner_id: 3,
    street: dummyLocationData.street,
    city: dummyLocationData.city,
    postcode: dummyLocationData.postcode,
    region_code: AddressState.NSW,
    country_code: AddressCountry.AU
  },
  image_dropoff: null,
  courier_location_id: null
}

const dummyOrders = [
  // -------------------------------------------------- EMPTY ORDER (FOR CREATE)
  {
    id: 0,
    partner_id: 3,
    created_offset: 0,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: false,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.Standard,
    platform_order_no: '000000000',
    status_code: OrderStatus.New,
    customer_firstname: 'Place',
    customer_lastname: 'Holder',
    delivery_phone: '',
    customer_email: '',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '[""]',
    delivery_city: '',
    delivery_postcode: '',
    total: '0',
    items: [],
    notes: []
  },
  // --------------------------------------------------------- OPEN ORDERS (2/2)
  {
    id: 113,
    partner_id: 3,
    created_offset: -21,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.Pickup,
    platform_order_no: '000000119',
    status_code: OrderStatus.Complete,
    customer_firstname: 'Cat',
    customer_lastname: 'Bronger',
    delivery_phone: '0412 518 296',
    customer_email: 'cat@rivalsoftware.com.au',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["20 John Street"]',
    delivery_city: 'Annangrove',
    delivery_postcode: '2156',
    total: '42.97',
    items: [
      {
        sku: '5123442',
        name: 'Difflam Forte Throat Spray 15ml',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '16.99'
      },
      {
        sku: '9182744',
        name: 'Betadine Ready to Use Sore Throat Gargle 120ml',
        qty_ordered: 2,
        qty_refunded: 0,
        total: '12.99'
      }
    ],
    notes: []
  },
  {
    id: 112,
    partner_id: 3,
    // created_offset: -0.01,
    created_offset: 0,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.Express,
    platform_order_no: '000000122',
    status_code: OrderStatus.New,
    customer_firstname: 'Hamish',
    customer_lastname: 'Henderson',
    delivery_phone: '0421148166',
    customer_email: 'hamish@rivalsoftware.com.au',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["100 Harris Street"]',
    delivery_city: 'Pyrmont',
    delivery_postcode: '2009',
    total: '32.98',
    items: [
      {
        sku: '65152760',
        name: 'Elocon Alcohol Free continuous 1 mg/g 1',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '12.98',
        // prescription
        is_prescription: true,
        prescription_first_name: 'Hamish',
        prescription_last_name: 'Henderson',
        prescription_concession: 'Medicare',
        prescription_entitlement_number: '1234 56789 1',
        prescription_token_number: '2Q0K8B4CQTRN9JBY7A',
      },
      {
        sku: '9350299000351',
        name: 'Rizatriptan Wafers-10mg wafer 10 mg/each 4',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '20.00',
        // prescription
        is_prescription: true,
        prescription_first_name: 'Hamish',
        prescription_last_name: 'Henderson',
        prescription_concession: 'Medicare',
        prescription_token_number: '2Q0K8B7MNR273PCY52',
      }
    ],
    notes: []
  },
  {
    id: 444,
    partner_id: 3,
    created_offset: -0.03,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.Custom,
    platform_order_no: '000000444',
    status_code: OrderStatus.AwaitingCourier,
    customer_firstname: 'Rajesh',
    customer_lastname: 'Khan',
    delivery_phone: '0412 345 678',
    customer_email: 'raj.khan@gmail.com',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["25 Glevel Street"]',
    delivery_city: 'Glebe',
    delivery_postcode: '2037',
    total: '66.98',
    items: [
      {
        sku: '2457254',
        name: 'Dermalogica Clear Start Breakout Clearing Foaming Wash 295ml',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '47.00'
      },
      {
        sku: '1254732',
        name: 'Medichoice Eucalyptus Spray 200g',
        qty_ordered: 2,
        qty_refunded: 0,
        total: '9.99'
      },
    ],
    notes: []
  },
  // --------------------------------------------------- AWAITING DELIVERY (4/4)
  {
    id: 152,
    partner_id: 3,
    created_offset: -11,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Dropoff,
    delivery_type_code: OrderDeliveryType.Express,
    platform_order_no: '000000129',
    status_code: OrderStatus.AwaitingProcessing,
    customer_firstname: 'Gabriela',
    customer_lastname: 'Friedman',
    delivery_phone: '0412 345 678',
    customer_email: 'gabriela.friedman@gmail.com',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["8 Iris Lane"]',
    delivery_city: 'Chatswood',
    delivery_postcode: '2067',
    total: '19.99',
    items: [
      {
        sku: '134563',
        name: 'Painaway Sport Cream 70g',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '19.99'
      },
    ],
    notes: []
  },
  {
    id: 119,
    partner_id: 3,
    created_offset: -22,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Dropoff,
    delivery_type_code: OrderDeliveryType.Standard,
    platform_order_no: '000000136',
    status_code: OrderStatus.AwaitingProcessing,
    customer_firstname: 'Isabela',
    customer_lastname: 'Brennan',
    delivery_phone: '0412 345 678',
    customer_email: 'isabela.brennan@gmail.com',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["2 Taylor Street"]',
    delivery_city: 'Gordon',
    delivery_postcode: '2072',
    total: '35.98',
    items: [
      {
        sku: '13541234',
        name: 'Voltaren Emulgel 100g',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '28.99'
      },
      {
        sku: '4978643',
        name: 'Ego Aqium Hand Sanitiser Aloe 375ml',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '6.99'
      },
    ],
    notes: []
  },
  {
    id: 120,
    partner_id: 3,
    created_offset: -5,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.SameDay,
    platform_order_no: '000000131',
    status_code: OrderStatus.AwaitingCourier,
    customer_firstname: 'Jaidyn',
    customer_lastname: 'Carrillo',
    delivery_phone: '0412 345 678',
    customer_email: 'jaidyn.carrillo@gmail.com',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["144 Hawthorne Parade"]',
    delivery_city: 'Haberfield',
    delivery_postcode: '2045',
    total: '10.98',
    items: [
      {
        sku: '7568345',
        name: 'Panadol Tablets 20 Pack',
        qty_ordered: 2,
        qty_refunded: 0,
        total: '5.49'
      },
    ],
    notes: []
  },
  {
    id: 111,
    partner_id: 3,
    created_offset: -25,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Dropoff,
    delivery_type_code: OrderDeliveryType.Standard,
    platform_order_no: '000000123',
    status_code: OrderStatus.AwaitingProcessing,
    customer_firstname: 'Liam',
    customer_lastname: 'Bray',
    delivery_phone: '0432 098 532',
    customer_email: 'liam@rivalsoftware.com.au',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["20 John Street"]',
    delivery_city: 'Annangrove',
    delivery_postcode: '2156',
    total: '326.75',
    items: [
      {
        sku: '123456',
        name: 'Glasshouse Tahaa Gift Pack Small',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '326.75'
      },
    ],
    notes: []
  },
  // ------------------------------------------------- AWAITING COLLECTION (2/2)
  {
    id: 136,
    partner_id: 3,
    created_offset: -37,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: false,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.Pickup,
    platform_order_no: '000000135',
    status_code: OrderStatus.AwaitingPickup,
    customer_firstname: 'Mckenzie',
    customer_lastname: 'George',
    delivery_phone: '0412 345 678',
    customer_email: 'mckenzie.george@gmail.com',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["393 Galston Road"]',
    delivery_city: 'Arcadia',
    delivery_postcode: '2159',
    total: '76.98',
    items: [
      {
        sku: '2457254',
        name: 'Dermalogica Clear Start Breakout Clearing Foaming Wash 295ml',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '47.00'
      },
      {
        sku: '1254732',
        name: 'Medichoice Eucalyptus Spray 200g',
        qty_ordered: 2,
        qty_refunded: 0,
        total: '9.99'
      },
    ],
    notes: []
  },
  {
    id: 137,
    partner_id: 3,
    created_offset: -15,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.ExternalPickup,
    platform_order_no: '000000153',
    status_code: OrderStatus.AwaitingPickup,
    customer_firstname: 'Michelle',
    customer_lastname: 'Ochoa',
    delivery_phone: '0412 345 678',
    customer_email: 'michelle.ochoa@gmail.com',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["11 Sutherland Road"]',
    delivery_city: 'Beecroft',
    delivery_postcode: '2119',
    total: '119.99',
    items: [
      {
        sku: '52547245',
        name: 'Rossmax Blood Pressure Monitor X5',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '119.99'
      },
    ],
    notes: []
  },
  // ---------------------------------------------------- COMPLETED ORDERS (19/200)
  {
    id: 115,
    partner_id: 3,
    created_offset: -16,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.Express,
    platform_order_no: '000000212',
    status_code: OrderStatus.New,
    customer_firstname: 'Antonio',
    customer_lastname: 'Sylvester',
    delivery_phone: '0412 345 678',
    customer_email: 'antonio.s@gmail.com',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["20 John Street"]',
    delivery_city: 'Annangrove',
    delivery_postcode: '2156',
    total: '17.45',
    items: [
      {
        sku: '3126673',
        name: 'Elastoplast 48497 Skin Tone Dark 20 Pack',
        qty_ordered: 5,
        qty_refunded: 0,
        total: '3.49'
      },
    ],
    notes: []
  },
  {
    id: 114,
    partner_id: 3,
    created_offset: -8,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.SameDay,
    platform_order_no: '000000115',
    status_code: OrderStatus.Complete,
    customer_firstname: 'Alex',
    customer_lastname: 'Watson',
    delivery_phone: '0412 345 678',
    customer_email: 'alex@rivalsoftware.com.au',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["20 John Street"]',
    delivery_city: 'Annangrove',
    delivery_postcode: '2156',
    total: '28.98',
    items: [
      {
        sku: '3414512',
        name: 'Codral Dry Cough + Cold 200ml',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '15.99'
      },
      {
        sku: '2432344',
        name: 'Sudocrem Baby Cream 125g',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '12.99'
      }
    ],
    notes: [
      { date: '24/01/2023', note: 'Face wash was out of stock and there were no alternatives available at other locations so the customer requested a different product instead.' }
    ]
  },
  {
    id: 129,
    partner_id: 3,
    created_offset: -23,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.Standard,
    platform_order_no: '000000181',
    status_code: OrderStatus.New,
    customer_firstname: 'Jazlene',
    customer_lastname: 'Sweeney',
    delivery_phone: '0412 345 678',
    customer_email: 'jazlene.sweeney@gmail.com',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["104 Melba Drive"]',
    delivery_city: 'East Ryde',
    delivery_postcode: '2113',
    total: '26.97',
    items: [
      {
        sku: '7509873',
        name: 'Demazin 12 Hour Nasal Decongestant Spray 20ml',
        qty_ordered: 2,
        qty_refunded: 0,
        total: '9.99',
        drug_schedule: 'S3',
        gtin: '1289371293',
        pde_id: '141232'
      },
      {
        sku: '4978643',
        name: 'Ego Aqium Hand Sanitiser Aloe 375ml',
        qty_ordered: 1,
        qty_refunded: 1,
        total: '6.99'
      },
    ],
    notes: []
  },
  {
    id: 130,
    partner_id: 3,
    created_offset: -14,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.ExternalPickup,
    platform_order_no: '000000185',
    status_code: OrderStatus.New,
    customer_firstname: 'Liam',
    customer_lastname: 'McMahon',
    delivery_phone: '0412 345 678',
    // customer_email: 'liam.mcmahon@gmail.com',
    customer_email: 'dev@rivalsoftware.com.au',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["9 Vicar Street"]',
    delivery_city: 'Coogee',
    delivery_postcode: '2034',
    total: '5.99',
    items: [
      {
        sku: '43974062',
        name: 'Lucas Pawpaw 25g',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '5.99'
      },
    ],
    notes: [],
    reservation_id: 'reservation-7b3f1011-d72e-43f3-bf50-5dce949004fe'
  },
  {
    id: 131,
    partner_id: 3,
    created_offset: -0.5,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.SameDay,
    platform_order_no: '000000191',
    status_code: OrderStatus.New,
    customer_firstname: 'Giselle',
    customer_lastname: 'Solomon',
    delivery_phone: '0412 345 678',
    customer_email: 'giselle.solomon@gmail.com',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["12 Sunden Way"]',
    delivery_city: 'Epping',
    delivery_postcode: '2121',
    platform_payment_method: 'amazon',
    total: '24.99',
    items: [
      {
        sku: '47023907',
        name: 'Dermaveen Daily Nourish Oatmeal Shampoo 1 Litre',
        qty_ordered: 15,
        qty_refunded: 0,
        total: '24.99',
        requires_contact: true,
        drug_schedule: '',
        gtin: '12098371129837'
      },
      {
        sku: '4235627',
        name: 'Dermaveen Daily Nourish Oatmeal Conditioner 1 Litre',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '24.99',
        drug_schedule: 'S3'
      },
    ],
    notes: []
  },
  {
    id: 132,
    partner_id: 3,
    created_offset: -13,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.SameDay,
    platform_order_no: '000000192',
    status_code: OrderStatus.Complete,
    customer_firstname: 'Ana',
    customer_lastname: 'Lambert',
    delivery_phone: '0412 345 678',
    customer_email: 'ana.lambert@gmail.com',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["162 Pretoria Parade"]',
    delivery_city: 'Hornsby',
    delivery_postcode: '2077',
    total: '46.97',
    items: [
      {
        sku: '134678',
        name: 'Panadol Mini Caps | 20 Pack',
        qty_ordered: 2,
        qty_refunded: 0,
        total: '5.99'
      },
      {
        sku: '2457245',
        name: 'Zyrtec 10mg 50 Tablets',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '34.99'
      },
    ],
    notes: []
  },
  {
    id: 133,
    partner_id: 3,
    created_offset: -41,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.Standard,
    platform_order_no: '000000198',
    status_code: OrderStatus.Complete,
    customer_firstname: 'Mallory',
    customer_lastname: 'Massey',
    delivery_phone: '0412 345 678',
    customer_email: 'mallory.massey@gmail.com',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["39 Delmar Parade"]',
    delivery_city: 'Gladesville',
    delivery_postcode: '2111',
    total: '29.99',
    items: [
      {
        sku: '7254883',
        name: 'Zyrtec Tablets 10mg 30 Pack',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '29.99'
      },
    ],
    notes: []
  },
  {
    id: 134,
    partner_id: 3,
    created_offset: -1.2,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.ExternalPickup,
    platform_order_no: '000000133',
    status_code: OrderStatus.Complete,
    customer_firstname: 'Jeffrey',
    customer_lastname: 'Fuller',
    delivery_phone: '0412 345 678',
    customer_email: 'jeffrey.fuller@gmail.com',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["355 Blaxland Road"]',
    delivery_city: 'Ryde',
    delivery_postcode: '2112',
    total: '15.99',
    items: [
      {
        sku: '6429384',
        name: 'Telfast Hayfever Tablets 180mg 10 Pack',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '15.99'
      },
    ],
    notes: []
  },
  {
    id: 135,
    partner_id: 3,
    created_offset: -41,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.Pickup,
    platform_order_no: '000000130',
    status_code: OrderStatus.Complete,
    customer_firstname: 'Alexzander',
    customer_lastname: 'Hatfield',
    delivery_phone: '0412 345 678',
    customer_email: 'alexzander.hatfield@gmail.com',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["22 Darnley Street"]',
    delivery_city: 'Gordon',
    delivery_postcode: '2072',
    total: '19.99',
    items: [
      {
        sku: '4235677',
        name: 'Colgate Slim Soft Designer Ultra Soft 3pk',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '19.99'
      },
    ],
    notes: []
  },
  {
    id: 138,
    partner_id: 3,
    created_offset: -38,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.Standard,
    platform_order_no: '000000156',
    status_code: OrderStatus.Complete,
    customer_firstname: 'Dayami',
    customer_lastname: 'Ellison',
    delivery_phone: '0412 345 678',
    customer_email: 'dayami.ellison@gmail.com',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["27 Hill Crest Avenue"]',
    delivery_city: 'Gladesville',
    delivery_postcode: '2111',
    total: '119.99',
    items: [
      {
        sku: '77542526',
        name: 'Omron Wrist Blood Pressure Monitor Hem-6161',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '119.99'
      },
    ],
    notes: []
  },
  {
    id: 139,
    partner_id: 3,
    created_offset: -21,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.Standard,
    platform_order_no: '000000163',
    status_code: OrderStatus.Complete,
    customer_firstname: 'Christian',
    customer_lastname: 'White',
    delivery_phone: '0412 345 678',
    customer_email: 'christian.white@gmail.com',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["4 Hurlstone Avenue"]',
    delivery_city: 'Summer Hill',
    delivery_postcode: '2130',
    total: '54.99',
    items: [
      {
        sku: '4234266',
        name: 'Caruso’s Herb Turmeric 50 Tabs',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '54.99'
      },
    ],
    notes: []
  },
  {
    id: 140,
    partner_id: 3,
    created_offset: -2,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.Standard,
    platform_order_no: '000000161',
    status_code: OrderStatus.Complete,
    customer_firstname: 'Caitlyn',
    customer_lastname: 'Lawrence',
    delivery_phone: '0412 345 678',
    customer_email: 'caitlyn.lawrence@gmail.com',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["101 Schofields Road"]',
    delivery_city: 'The Ponds',
    delivery_postcode: '2769',
    total: '99.98',
    items: [
      {
        sku: '2346273',
        name: 'Ensure Powder Vanilla 850g',
        qty_ordered: 2,
        qty_refunded: 0,
        total: '49.99'
      },
    ],
    notes: []
  },
  {
    id: 145,
    partner_id: 3,
    created_offset: -26,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.Standard,
    platform_order_no: '000000169',
    status_code: OrderStatus.Complete,
    customer_firstname: 'Izabella',
    customer_lastname: 'Bruce',
    delivery_phone: '0412 345 678',
    customer_email: 'izabella.bruce@gmail.com',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["32 David Avenue"]',
    delivery_city: 'East Ryde',
    delivery_postcode: '2113',
    total: '45.99',
    items: [
      {
        sku: '7098654',
        name: 'Claratyne Tablets 60 Pack',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '45.99'
      },
    ],
    notes: []
  },
  {
    id: 146,
    partner_id: 3,
    created_offset: -19,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.Standard,
    platform_order_no: '000000173',
    status_code: OrderStatus.Complete,
    customer_firstname: 'Tate',
    customer_lastname: 'Swanson',
    delivery_phone: '0412 345 678',
    customer_email: 'tate.swanson@gmail.com',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["26 Duncan Crescent"]',
    delivery_city: 'Collaroy',
    delivery_postcode: '2097',
    total: '6.99',
    items: [
      {
        sku: '4978643',
        name: 'Ego Aqium Hand Sanitiser Aloe 375ml',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '6.99'
      },
    ],
    notes: []
  },
  {
    id: 147,
    partner_id: 3,
    created_offset: -26,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.Standard,
    platform_order_no: '000000179',
    status_code: OrderStatus.Complete,
    customer_firstname: 'Brenda',
    customer_lastname: 'Mendoza',
    delivery_phone: '0412 345 678',
    customer_email: 'brenda.mendoza@gmail.com',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["720A Old Northern Road"]',
    delivery_city: 'Dural',
    delivery_postcode: '2158',
    total: '16.49',
    items: [
      {
        sku: '3701293',
        name: 'Ego Aqium Hand Sanitiser Aloe 1 Litre',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '16.49'
      },
    ],
    notes: []
  },
  {
    id: 148,
    partner_id: 3,
    created_offset: -2,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.Standard,
    platform_order_no: '000000171',
    status_code: OrderStatus.Complete,
    customer_firstname: 'Anastasia',
    customer_lastname: 'Tucker',
    delivery_phone: '0412 345 678',
    customer_email: 'anastasia.tucker@gmail.com',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["10 Duntroon Avenue"]',
    delivery_city: 'Crows Nest',
    delivery_postcode: '2065',
    total: '19.98',
    items: [
      {
        sku: '1254732',
        name: 'Medichoice Eucalyptus Spray 200g',
        qty_ordered: 2,
        qty_refunded: 0,
        total: '9.99'
      },
    ],
    notes: []
  },
  {
    id: 149,
    partner_id: 3,
    created_offset: -3,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.Standard,
    platform_order_no: '000000180',
    status_code: OrderStatus.Complete,
    customer_firstname: 'Todd',
    customer_lastname: 'Richard',
    delivery_phone: '0412 345 678',
    customer_email: 'todd.richard@gmail.com',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["99 Peel Street"]',
    delivery_city: 'Cabramatta',
    delivery_postcode: '2166',
    total: '11.99',
    items: [
      {
        sku: '6492834',
        name: 'Panadol Mini Caplet 48 Pack',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '11.99'
      },
    ],
    notes: []
  },
  {
    id: 150,
    partner_id: 3,
    created_offset: -43,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.Standard,
    platform_order_no: '000000182',
    status_code: OrderStatus.Complete,
    customer_firstname: 'Helen',
    customer_lastname: 'Zuniga',
    delivery_phone: '0412 345 678',
    customer_email: 'helen.zuniga@gmail.com',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["338 Mowbray Road"]',
    delivery_city: 'Artarmon',
    delivery_postcode: '2064',
    total: '56.97',
    items: [
      {
        sku: '47023907',
        name: 'Dermaveen Daily Nourish Oatmeal Shampoo 1 Litre',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '24.99'
      },
      {
        sku: '4235627',
        name: 'Dermaveen Daily Nourish Oatmeal Conditioner 1 Litre',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '24.99'
      },
      {
        sku: '4978643',
        name: 'Ego Aqium Hand Sanitiser Aloe 375ml',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '6.99'
      },
    ],
    notes: []
  },
  {
    id: 151,
    partner_id: 3,
    created_offset: -30,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.Standard,
    platform_order_no: '000000199',
    status_code: OrderStatus.Complete,
    customer_firstname: 'Izaiah',
    customer_lastname: 'Church',
    delivery_phone: '0412 345 678',
    customer_email: 'izaiah.church@gmail.com',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["331 Galston Road"]',
    delivery_city: 'Arcadia',
    delivery_postcode: '2159',
    total: '19.99',
    items: [
      {
        sku: '786429',
        name: 'Advil Liquid Capsules | 90 Pack',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '19.99'
      }
    ],
    notes: []
  },
  // ------------------------------------------- CANCELLED/REFUNDED ORDERS (11/18)
  {
    id: 116,
    partner_id: 3,
    created_offset: -23,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.Standard,
    platform_order_no: '000000124',
    status_code: OrderStatus.Cancelled,
    customer_firstname: 'Mary',
    customer_lastname: 'Chen',
    delivery_phone: '0412 345 678',
    customer_email: 'mary.chen@gmail.com',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["20 John Street"]',
    delivery_city: 'Annangrove',
    delivery_postcode: '2156',
    total: '12.99',
    items: [
      {
        sku: '65152760',
        name: 'Pigeon Training Teether Step 1',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '12.99'
      },
    ],
    notes: [
      { date: '3/03/2023', note: 'Customer requested a refund as the product was not what they had expected.' }
    ]
  },
  {
    id: 117,
    partner_id: 3,
    created_offset: -44,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.Standard,
    platform_order_no: '000000128',
    status_code: OrderStatus.Cancelled,
    customer_firstname: 'Daria',
    customer_lastname: 'Winters',
    delivery_phone: '0412 345 678',
    customer_email: 'dwinters@gmail.com',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["52 Apple Street"]',
    delivery_city: 'Glebe',
    delivery_postcode: '2037',
    total: '34.97',
    items: [
      {
        sku: '492384',
        name: 'Bepanthen Ointment 100g',
        qty_ordered: 2,
        qty_refunded: 0,
        total: '9.99'
      },
      {
        sku: '492385',
        name: 'Ego Qv Bath Oil 500ml',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '14.99'
      },
    ],
    notes: [
      { date: '5/03/2023', note: 'Change of mind.' }
    ]
  },
  {
    id: 118,
    partner_id: 3,
    created_offset: -40,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.Pickup,
    platform_order_no: '000000178',
    status_code: OrderStatus.Cancelled,
    customer_firstname: 'Martin',
    customer_lastname: 'Nguyen',
    delivery_phone: '0412 345 678',
    customer_email: 'martinng@gmail.com',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["12 Short Street"]',
    delivery_city: 'Revesby',
    delivery_postcode: '2212',
    total: '34.97',
    items: [
      {
        sku: '492384',
        name: 'Bepanthen Ointment 100g',
        qty_ordered: 2,
        qty_refunded: 0,
        total: '9.99'
      },
      {
        sku: '492385',
        name: 'Ego Qv Bath Oil 500ml',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '14.99'
      },
    ],
    notes: [
      { date: '5/03/2023', note: 'Change of mind.' }
    ]
  },
  {
    id: 121,
    partner_id: 3,
    created_offset: -41,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.Standard,
    platform_order_no: '000000150',
    status_code: OrderStatus.Cancelled,
    customer_firstname: 'Ronan',
    customer_lastname: 'Soto',
    delivery_phone: '0412 345 678',
    customer_email: 'ronan.soto@gmail.com',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["2 Hampshire Avenue"]',
    delivery_city: 'Pymble',
    delivery_postcode: '2073',
    total: '6.99',
    items: [
      {
        sku: '6345645',
        name: 'Panadol Rapid Caplets 20 Pack',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '6.99'
      },
    ],
    notes: [
      { date: '7/03/2023', note: 'Product was damaged.' }
    ]
  },
  {
    id: 122,
    partner_id: 3,
    created_offset: -1,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.Express,
    platform_order_no: '000000159',
    status_code: OrderStatus.Cancelled,
    customer_firstname: 'Alejandro',
    customer_lastname: 'Schaefer',
    delivery_phone: '0412 345 678',
    customer_email: 'alejandro.schaefer@gmail.com',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["26 Norfolk Street"]',
    delivery_city: 'Gordon',
    delivery_postcode: '2072',
    total: '25.96',
    items: [
      {
        sku: '134678',
        name: 'Panadol Mini Caps | 20 Pack',
        qty_ordered: 2,
        qty_refunded: 0,
        total: '5.99'
      },
      {
        sku: '4978643',
        name: 'Ego Aqium Hand Sanitiser Aloe 375ml',
        qty_ordered: 2,
        qty_refunded: 0,
        total: '6.99'
      },
    ],
    notes: [
      { date: '7/03/2023', note: 'Product was damaged.' }
    ]
  },
  {
    id: 123,
    partner_id: 3,
    created_offset: -39,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.Pickup,
    platform_order_no: '000000162',
    status_code: OrderStatus.Cancelled,
    customer_firstname: 'Marco',
    customer_lastname: 'Estes',
    delivery_phone: '0412 345 678',
    customer_email: 'marco.estes@gmail.com',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["37 Dobell Circuit"]',
    delivery_city: 'Erskine Park',
    delivery_postcode: '2759',
    total: '47.98',
    items: [
      {
        sku: '923407',
        name: 'Nurofen Zavance Fast Pain Relief Tablets 256mg Ibuprofen 96 Pack',
        qty_ordered: 2,
        qty_refunded: 0,
        total: '23.99'
      },
    ],
    notes: [
      { date: '7/03/2023', note: 'Customer received incorrect product.' }
    ]
  },
  {
    id: 124,
    partner_id: 3,
    created_offset: -7,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.Standard,
    platform_order_no: '000000168',
    status_code: OrderStatus.Cancelled,
    customer_firstname: 'Randall',
    customer_lastname: 'Lin',
    delivery_phone: '0412 345 678',
    customer_email: 'randall.lin@gmail.com',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["30 Champion Road"]',
    delivery_city: 'Gladesville',
    delivery_postcode: '2111',
    total: '49.95',
    items: [
      {
        sku: '7568345',
        name: 'Panadol Tablets 20 Pack',
        qty_ordered: 2,
        qty_refunded: 0,
        total: '5.49'
      },
      {
        sku: '872364',
        name: 'Nurofen Tablets 48 200mg Ibuprofen Anti-inflammatory Pain Relief',
        qty_ordered: 3,
        qty_refunded: 0,
        total: '12.99'
      },
    ],
    notes: [
      { date: '8/03/2023', note: 'Customer received incorrect product.' }
    ]
  },
  {
    id: 125,
    partner_id: 3,
    created_offset: -41,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.Standard,
    platform_order_no: '000000166',
    status_code: OrderStatus.Cancelled,
    customer_firstname: 'Miriam',
    customer_lastname: 'Ayers',
    delivery_phone: '0412 345 678',
    customer_email: 'miriam.ayers@gmail.com',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["8 Arilla Road"]',
    delivery_city: 'Pymble',
    delivery_postcode: '2073',
    total: '22.99',
    items: [
      {
        sku: '',
        name: 'Nurofen for Children 3 Months – 5 Years Orange 200ml',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '22.99'
      },
    ],
    notes: []
  },
  {
    id: 126,
    partner_id: 3,
    created_offset: -23,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.Standard,
    platform_order_no: '000000170',
    status_code: OrderStatus.Cancelled,
    customer_firstname: 'Grant',
    customer_lastname: 'Burgess',
    delivery_phone: '0412 345 678',
    customer_email: 'grant.burgess@gmail.com',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["3 Bellevue Street"]',
    delivery_city: 'Noth Parramatta',
    delivery_postcode: '2151',
    total: '33.00',
    items: [
      {
        sku: '235523',
        name: 'Dermalogica Clear Start Breakout Clearing Booster 30ml',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '33.00'
      },
    ],
    notes: []
  },
  {
    id: 127,
    partner_id: 3,
    created_offset: -5,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.SameDay,
    platform_order_no: '000000172',
    status_code: OrderStatus.Cancelled,
    customer_firstname: 'Noemi',
    customer_lastname: 'Greer',
    delivery_phone: '0412 345 678',
    customer_email: 'noemi.greer@gmail.com',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["16 Kentia Parade"]',
    delivery_city: 'Cherrybrook',
    delivery_postcode: '2126',
    total: '70.49',
    items: [
      {
        sku: '748296',
        name: 'Dermalogica Multi-active Toner 250ml',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '61.50'
      },
      {
        sku: '1254732',
        name: 'Medichoice Eucalyptus Spray 200g',
        qty_ordered: 1,
        qty_refunded: 0,
        total: '9.99'
      },
    ],
    notes: []
  },
  {
    id: 128,
    partner_id: 3,
    created_offset: -15,
    created_at: '',
    updated_at: '',
    ordered_at: '',
    is_visible: true,
    location_code: 'cw-broadway',
    delivery_model_code: DeliveryModelCode.Pickup,
    delivery_type_code: OrderDeliveryType.Standard,
    platform_order_no: '000000174',
    status_code: OrderStatus.Cancelled,
    customer_firstname: 'Chris',
    customer_lastname: 'Bass',
    delivery_phone: '0412 345 678',
    customer_email: 'chris.bass@gmail.com',
    delivery_country_code: AddressCountry.AU,
    delivery_state_code: AddressState.NSW,
    delivery_street: '["21 Archer Street"]',
    delivery_city: 'Concord',
    delivery_postcode: '2137',
    total: '31.98',
    items: [
      {
        sku: '297037',
        name: 'Codral Dry Cough + Cold 200ml',
        qty_ordered: 2,
        qty_refunded: 0,
        total: '15.99'
      },
    ],
    notes: []
  },
];

// generate timestamps
const currentMinutes = new Date().getMinutes();
const getTimestamp = (hourOffset: number): string => {
  const date = new Date();
  date.setSeconds(currentMinutes + (hourOffset * 60 * 60));
  return date.toISOString().substring(0, 19);
}

// generate orders with all fields
const generatedOrders: IOrders = dummyOrders.map(order => {
  // create order
  const newOrder = JSON.parse(JSON.stringify(order));
  // generate timestamp
  const timestamp = getTimestamp(newOrder.created_offset);
  newOrder.created_at = timestamp;
  newOrder.updated_at = timestamp;
  newOrder.ordered_at = timestamp;
  // set default fields
  newOrder.completed_at = null;
  newOrder.location_code = 'cw-broadway';
  newOrder.platform_customer_id = order.id;
  newOrder.platform_order_id = order.id;
  newOrder.platform_payment_method = order.platform_payment_method;
  newOrder.platform_delivery_method = 'tablerate_bestway';
  newOrder.customer_ip = null;
  newOrder.total_paid = order.total;
  newOrder.total_owing = "0.0000";
  newOrder.total_weight = "0.0000";
  newOrder.tax = "0.9100";
  newOrder.discount = "0.0000";
  newOrder.discount_tax_offset = "0.0000";
  newOrder.delivery = "10.0000";
  newOrder.delivery_tax = "0.9100";
  newOrder.delivery_discount = "0.0000";
  newOrder.delivery_discount_tax_offset = "0.0000";
  newOrder.billing_firstname = order.customer_firstname;
  newOrder.billing_lastname = order.customer_lastname;
  newOrder.billing_email = order.customer_email;
  newOrder.billing_phone = order.delivery_phone;
  newOrder.billing_street = order.delivery_street;
  newOrder.billing_city = order.delivery_city;
  newOrder.billing_state_code = order.delivery_state_code;
  newOrder.billing_postcode = order.delivery_postcode;
  newOrder.billing_country_code = order.delivery_country_code;
  newOrder.delivery_firstname = order.customer_firstname;
  newOrder.delivery_lastname = order.customer_lastname;
  newOrder.delivery_email = order.customer_email;
  newOrder.integration_id = 30;
  // return dummy order
  return newOrder;
});

// check state for problematic duplicate order fields
const fields = {
  id: [] as Array<number>,
  orderNumber: [] as Array<string>
}
generatedOrders.forEach(order => {
  if (fields.id.includes(order.id)) console.warn(`Duplicate dummy order id ${order.id}`);
  fields.id.push(order.id);

  if (fields.orderNumber.includes(order.platform_order_no)) console.warn(`Duplicate dummy order number ${order.platform_order_no}`);
  fields.orderNumber.push(order.platform_order_no);
});

const initialState = {
  useDummyData: defaultUseDummyData,
  placeholderPharmacy: placeholderPharmacy,
  orders: generatedOrders,
  dummyUser: dummyUser,
  dummyLocation: dummyLocation,
  dummyLogo: dummyLocationData.logo,
  locations: [
    {
      id: 99,
      partner_id: 3,
      name: `${dummyLocationData.pharmacyName} Broadway`,
      code: 'cw-broadway',
      contact_phone: '02 9212 4377',
      contact_email: `broadway@${dummyLocationData.domain}`,
      is_enabled: true,
      address: {
        id: 1,
        created_at: '2023-04-13 00:57:42',
        updated_at: null,
        name: `${dummyLocationData.pharmacyName} Broadway`,
        partner_id: 3,
        street: '["Shop G20A/1 Bay St"]',
        city: 'Ultimo',
        postcode: '2007',
        region_code: AddressState.NSW,
        country_code: AddressCountry.AU
      }
    },
    {
      id: 98,
      partner_id: 998,
      name: `${dummyLocationData.pharmacyName} Edmondson Park`,
      code: 'cw-edmondsonpark',
      contact_phone: '02 8417 8085',
      contact_email: `edmondsonpark@${dummyLocationData.domain}`,
      is_enabled: true,
      address: {
        id: 1,
        created_at: '2023-04-13 00:57:42',
        updated_at: null,
        name: `${dummyLocationData.pharmacyName} Edmondson Park`,
        partner_id: 3,
        street: '["52 Soldiers Parade","Shop AG65"]',
        city: 'Edmondson Park',
        postcode: '2174',
        region_code: AddressState.NSW,
        country_code: AddressCountry.AU
      }
    },
    {
      id: 97,
      partner_id: 997,
      name: `${dummyLocationData.pharmacyName} Glendale`,
      code: 'cw-glendale',
      contact_phone: '02 4956 5644',
      contact_email: `glendale@${dummyLocationData.domain}`,
      is_enabled: true,
      address: {
        id: 1,
        created_at: '2023-04-13 00:57:42',
        updated_at: null,
        name: `${dummyLocationData.pharmacyName} Glendale`,
        partner_id: 3,
        street: '["387 Lake Road","Shop 22"]',
        city: 'Glendale',
        postcode: '2285',
        region_code: AddressState.NSW,
        country_code: AddressCountry.AU
      }
    },
    {
      id: 96,
      partner_id: 996,
      name: `${dummyLocationData.pharmacyName} Wetherill Park`,
      code: 'cw-wetherillpark',
      postcode: '2164',
      contact_phone: '02 9609 7239',
      contact_email: `wetherillpark@${dummyLocationData.domain}`,
      is_enabled: true,
      address: {
        id: 1,
        created_at: '2023-04-13 00:57:42',
        updated_at: null,
        name: `${dummyLocationData.pharmacyName} Wetherill Park`,
        partner_id: 3,
        street: '["561 Polding Street","Shop MM05"]',
        city: 'Wetherill Park',
        postcode: '2164',
        region_code: AddressState.NSW,
        country_code: AddressCountry.AU
      }
    }
  ],
  manifests: [
    {
      courier_ref: 'CB29022023',
      createdString: '29/02/2023 12:22:24',
      orders: 5
    },
    {
      courier_ref: 'CB28022023',
      createdString: '28/02/2023 10:32:09',
      orders: 2
    },
    {
      courier_ref: 'CB27022023',
      createdString: '27/02/2023 14:59:10',
      orders: 1
    },
    {
      courier_ref: 'CB26022023',
      createdString: '26/02/2023 11:36:52',
      orders: 9
    },
    {
      courier_ref: 'CB25022023',
      createdString: '25/02/2023 12:05:20',
      orders: 4
    }
  ],
  dashboard: {
    numOrders: {
      open: -1,
      manifest: -1,
      dispatch: -1,
      closed: -1,
      // open: -1,
      // overdue: -1,
      // awaitingDelivery: -1,
      // awaitingCollect: -1,
      // completed: -1,
      // cancelled: -1,
    }
  },
  australiaPost: {
    pickupScheduled: false,
    dropoffOrganised: false,
    phone: '0412 345 678',
    deadline: '10:00',
    hours: [
      // starts sunday to match Date.getDay()
      { open: false },
      { open: '9:00', close: '17:00' },
      { open: '9:00', close: '17:00' },
      { open: '9:00', close: '17:00' },
      { open: '9:00', close: '17:00' },
      { open: '9:00', close: '17:00' },
      { open: false }
    ]
  }
};

export const DummyDataProvider: FunctionComponent<DummyDataProviderProps> = ({ children }) => {
  // init state
  const [state, setState] = useState(initialState);

  // define getters
  const getters = {
    orderById: (id: string): any => {
      const order = state.orders.find((order: any) => order.id === id || order.id.toString() === id);
      if (order) {
        return order;
      } else {
        console.warn(`No order found with id ${id}.`);
      }
    },
    locationById: (id: string): any => {
      const location = state.locations.find((location: any) => location.partner_id === id);
      if (location) {
        return location;
      } else {
        console.warn(`No location found with id ${id}.`);
      }
    }
  }

  // define mutations
  const mutations = {
    setUseDummyData: (value: boolean): void => {
      if (value === true) {
        localStorage.setItem('Scription_useTestData', 'true');
      } else {
        localStorage.removeItem('Scription_useTestData');
      }
      setState((state: any) => ({ ...state, useDummyData: value }));
    },
    setOrderStatus: (id: string, status: OrderStatus): void => {
      const newOrders = JSON.parse(JSON.stringify(state.orders));
      const order = newOrders.find((order: any) => order.id === id);
      if (order) {
        order.status_code = status;
        order.updated_at = getCurrentTimestamp();
        setState((state: any) => ({ ...state, orders: newOrders }))
      } else {
        console.warn(`No order found with id ${id}.`);
      }
    },
    setOrderState: (id: string, orderState: OrderState): void => {
      const newOrders = JSON.parse(JSON.stringify(state.orders));
      const order = newOrders.find((order: any) => order.id === id);
      if (order) {
        order.state_code = orderState;
        order.updated_at = getCurrentTimestamp();
        setState((state: any) => ({ ...state, orders: newOrders }))
      } else {
        console.warn(`No order found with id ${id}.`);
      }
    },
    setCreatedAt: (id: string, createdAt?: string): void => {
      const newOrders = JSON.parse(JSON.stringify(state.orders));
      const order = newOrders.find((order: any) => order.id === id);
      if (order) {
        if (createdAt === undefined) createdAt = getCurrentTimestamp();
        order.created_at = createdAt;
        setState((state: any) => ({ ...state, orders: newOrders }));
      } else {
        console.warn(`No order found with id ${id}.`);
      }
    },
    setUpdatedAt: (id: string, updatedAt?: string): void => {
      const newOrders = JSON.parse(JSON.stringify(state.orders));
      const order = newOrders.find((order: any) => order.id === id);
      if (order) {
        if (updatedAt === undefined) updatedAt = getCurrentTimestamp();
        order.updated_at = updatedAt;
        setState((state: any) => ({ ...state, orders: newOrders }));
      } else {
        console.warn(`No order found with id ${id}.`);
      }
    },
    setDashboardNumOrders: (numOrdersObject: Map<string, number>): void => {
      const newDashboard = JSON.parse(JSON.stringify(state.dashboard));
      newDashboard.numOrders = numOrdersObject;
      setState((state: any) => ({ ...state, dashboard: newDashboard }));
    },
    setPickupScheduled: (value: boolean): void => {
      const newAustraliaPost = JSON.parse(JSON.stringify(state.australiaPost));
      newAustraliaPost.pickupScheduled = value;
      setState((state: any) => ({ ...state, australiaPost: newAustraliaPost }));
    }
  };

  const contextData = {
    state,
    getters,
    mutations
  }

  return (
    <DummyDataContext.Provider value={contextData}>
      {children}
    </DummyDataContext.Provider>
  );
}

// Api hook
export function useDummyData(): any {
  return useContext(DummyDataContext);
}