import { FunctionComponent } from 'react';
import styled from 'styled-components';


type ScheduleIndicatorProps = {
  schedule: 'string'
}

export const ScheduleIndicator: FunctionComponent<ScheduleIndicatorProps> = ({ schedule }) => {
  return (
    <StyledScheduleIndicator className={`ScheduleIndicator ${schedule} bold`}>
      {schedule}
    </StyledScheduleIndicator>
  );
}

const StyledScheduleIndicator = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem; // 20px
  height: 1.25rem; // 20px
  border-radius: 100%;
  margin: 0 !important;
  font-size: 0.625rem; // 10px
  text-align: center;

  background: #D3FAFF;
  color: #0092A5;

  /* background: #DADADA;
  color: #000000;

  &[class*="2"] {
    background: #D3FAFF;
    color: #0092A5;
  }
  
  &[class*="3"] {
    background: #EED3FF;
    color: #6700A5;
  } */
`