import { FunctionComponent } from 'react';
import styled from 'styled-components';

import { useDummyData } from '../../context/DummyDataProvider';

import { IOrder, OrderDeliveryType } from '../../types/IOrders';
import { ILocations } from '../../types/ILocations';

import { OrderDetails } from '../OrderDetails/OrderDetails';
import { LocationDetails } from '../LocationDetails/LocationDetails';

import chemistworksLogo from '../../assets/images/logos/Chemistworks.png';
import catClaudiaLogo from '../../assets/images/logos/logo-catandclaudias.png';
import smithsLogo from '../../assets/images/logos/PlaceholderPharmacy.svg';
import southcityLogo from '../../assets/images/logos/logo-southcity.png';
import pricelineLogo from '../../assets/images/logos/logo-priceline-rounded.png';


type CollectionLabelProps = {
  order: IOrder
  numItems: number
  bags?: number | string
  showAddress?: boolean
  partnerLocations: ILocations
}

export const CollectionLabel: FunctionComponent<CollectionLabelProps> = ({ order, numItems, bags, showAddress, partnerLocations }) => {
  const dummyData: any = useDummyData();

  let numBags: number | undefined = undefined;
  if (typeof bags === 'number') {
    numBags = bags;
  } else if (typeof bags === 'string') {
    numBags = parseInt(bags);
  }

  // computed
  const partnerLogo = (): string => {
    if (dummyData.state.useDummyData) {
      return dummyData.state.dummyLogo;
    } else {
      switch (order.partner_id) {
        case 3:
          return chemistworksLogo;
        case 4:
          return catClaudiaLogo;
        case 5:
        case 6:
          return southcityLogo;
        case 7: // Baldwin Dev
        case 8: // Smith's Pharmacy
          return smithsLogo;
        case 11:
          return pricelineLogo;
        default:
          return '';
      }
    }
  }

  return (
    <StyledCollectionLabel>
      {Array.from(Array(numBags || 1)).map((empty, i: number) => {
        return (
          <div className="CollectionLabel" key={`collectionLabel_${i}`}>
            <div className="divider">
              <img className="CollectionLabel_logo" src={partnerLogo()} alt="" />
            </div>
            <div className="divider">
              <p className="CollectionLabel_header bold">
                {order.delivery_type_code === OrderDeliveryType.Pickup && <>Click and Collect</>}
                {order.delivery_type_code === OrderDeliveryType.Custom && <>Custom Courier</>}
                {order.delivery_type_code === OrderDeliveryType.SameDay && <>DoorDash</>}
              </p>
              <p className="CollectionLabel_header bold">
                <span>{numItems} {numItems === 1 ? 'item' : 'items'} total</span>
                {bags &&
                  <>
                    <span style={{ margin: '0 12px' }}>|</span>
                    <span>{i + 1} out of {numBags} {OrderDeliveryType.Custom ? numBags === 1 ? 'package' : 'packages' : numBags === 1 ? 'bag' : 'bags'}</span>
                  </>
                }
              </p>
            </div>
            <div>
              <p className="CollectionLabel_headerLarge bold">To</p>
              <OrderDetails order={order} phone email address={showAddress} />
              <p className="CollectionLabel_headerLarge bold">From</p>
              <LocationDetails location={partnerLocations.find(location => location.code === order.location_code)!} phone={order.delivery_type_code === OrderDeliveryType.SameDay} address />
            </div>
          </div>
        )
      })}
    </StyledCollectionLabel>
  )
}

CollectionLabel.defaultProps = {
  showAddress: false
}

const StyledCollectionLabel = styled.div`
  .CollectionLabel {
    display: inline-block;
    width: 680px;
    /* height: 1024px; // height adjusted for Safari print margins */
    height: 974px;
    /* padding: 110px 55px; */
    padding: 20px 55px;
    background: white;
    break-after: page;
    page-break-after: always;

    div {
      .CollectionLabel_logo {
        max-width: 320px;
        max-height: 100px;
      }
        
      .CollectionLabel_header {
        font-size: 1.875rem !important; // 30px
        margin: 10px 0;
      }
      
      .CollectionLabel_headerLarge {
        font-size: 2.75rem !important; // 44px
        margin-top: 50px !important;
        margin-bottom: 20px !important;
      }
    }
    
    .divider {
      padding: 50px 0 !important;
      margin: 0 !important;
    }
  }
`