import { FunctionComponent } from 'react';
import styled from 'styled-components';

import { IOrder } from '../../types/IOrders';
import { ILocations } from '../../types/ILocations';
import { formatLocationAddress } from '../../helpers/utils';

import { useApi } from '../../context/ApiProvider';
import { ApiHelper } from '../../common/ApiHelper/ApiHelper';
import { SparrowHubApiInterface } from 'sparrowhub-client-axios';

import { OrderDetails } from '../OrderDetails/OrderDetails';

import sparrowhubLogo from '../../assets/images/logos/sparrowhub-lockup.svg';

import phoneIcon from '../../assets/images/icons/Phone.svg';
import mailIcon from '../../assets/images/icons/Mail.svg';
import pinIcon from '../../assets/images/icons/Pin.svg';


type LochtingBoxLabelProps = {
  order: IOrder
  numItems: number
  partnerLocations: ILocations
  overboxCodeSrc: string
}

export const LochtingBoxLabel: FunctionComponent<LochtingBoxLabelProps> = ({ order, numItems, partnerLocations, overboxCodeSrc }) => {
  const orderLocation = partnerLocations?.find(location => location.code === order.location_code);
  const { apiHelper, api }: { apiHelper: ApiHelper; api: SparrowHubApiInterface } = useApi();

  return (
    <StyledLochtingBoxLabel className="LochtingBoxLabel">
      <div className="LochtingBoxLabel_qrContainer">
        <img className="logo" src={sparrowhubLogo} alt="" draggable="false" />
        {/* <img src={exampleDataMatrix} className="qrcode" alt="" draggable="false" /> */}
        <img src={overboxCodeSrc} className="qrcode" alt="" draggable="false" />
        <h2>
          <span>Click and Collect</span><br />
          <span>{numItems} {numItems === 1 ? 'item' : 'items'} total</span>
        </h2>
      </div>

      <div className="LochtingBoxLabel_customerDetails">
        <OrderDetails order={order} />
        <div className="detail">
          <img src={pinIcon} alt="Address" style={{ padding: '0 3px' }} />
          <p>{orderLocation?.name}</p>
          <p>{formatLocationAddress(orderLocation!)}</p>
        </div>
        <div className="detail">
          <img src={phoneIcon} alt="Address" style={{ padding: '0 3px' }} />
          <p>{order.delivery_phone}</p>
        </div>
        <div className="detail">
          <img src={mailIcon} alt="Address" style={{ padding: '0 3px' }} />
          <p>{order.delivery_email}</p>
        </div>
      </div>
    </StyledLochtingBoxLabel>
  )
}

const StyledLochtingBoxLabel = styled.div`
  display: inline-block;
  width: 680px;
  /* height: 1024px; // height adjusted for Safari print margins */
  height: 974px;
  /* padding: 110px 55px; */
  padding: 20px 55px;
  background: white;
  break-after: page;
  page-break-after: always;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .LochtingBoxLabel_qrContainer {
    border-bottom: 1px solid #DADADA;
    padding-bottom: 15px;
    padding-top: 20px;

    img.logo {
      max-width: 258px;
      max-height: 33px;
    }

    .qrcode {
      width: 400px;
      height: auto;
      margin-top: 61px;
      margin-bottom: 30px;
    }

    h2 {
      font-size: 1.875rem; // 30px
      line-height: 1.3;
    }
  }

  .LochtingBoxLabel_customerDetails {
    .OrderDetails_id {
      width: 100%;
      text-align: center;
      margin-top: 40px;
      font-size: 1.375rem; // 22px
    }

    .detail {
      margin-top: 28px;

      img {
        height: 28px;
        width: auto;
      }

      p {
        margin: 0;
        font-size: 1.25rem; // 20px
        line-height: 1.5;
      }
    }
  }
`