import { FunctionComponent } from "react";
import { Location, useLocation } from 'react-router-dom'
import styled from 'styled-components';

import { IUser } from "../../types/IUsers";
import { ILocation } from "../../types/ILocations";

import { Header } from '../Header/Header';
import { Footer } from '../Footer/Footer';
// import { HelpButton } from "../HelpButton/HelpButton";
import { ScrollToTopButton } from "../ScrollToTopButton/ScrollToTopButton";


type BodyProps = {
  children: any,
  loggedin: boolean,
  user: IUser | null,
  location: ILocation | null,
  onSelectLocation?: Function,
  onSelectUser?: Function,
  onLogout?: Function
}

export const Body: FunctionComponent<BodyProps> = ({ children, loggedin, user, location, onSelectLocation, onSelectUser, onLogout }) => {
  const domLocation: Location = useLocation();
  const layoutType = (): string => {
    return [
      '/login',
      '/logout',
      '/logout/auto',
      '/reset-password',
      '/reset-password/confirm',
      '/select-store',
      '/select-user'
    ].includes(domLocation.pathname) ? 'login' : 'default';
  }

  const showScrollToTopButton = (): boolean => {
    return domLocation.pathname.includes('/orders');
  }

  return (
    <>
      <Header loggedin={loggedin} user={user} location={location} type={layoutType()} onSelectLocation={onSelectLocation} onSelectUser={onSelectUser} onLogout={onLogout} />

      <StyledMain className={layoutType()}>
        <article>
          {children}
        </article>
      </StyledMain>

      {/* <HelpButton location={domLocation} /> */}
      
      {showScrollToTopButton() &&
        <ScrollToTopButton />
      }

      <Footer userRole={user?.role_code} />
    </>
  );
}

const StyledMain = styled.main`
  flex-grow: 1;
  padding: 0 20px 25px 20px;

  &.login {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &.default {
    
  }

  .recaptcha_disclaimer {
    p, a {
      font-size: 0.625rem !important; // 10px
      text-align: center;
      margin: 30px 0 -43px;
      color: #555555;
    }
  }
`