import { ChangeEvent, FunctionComponent, useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
import { containers, transitions, colours } from '../../assets/css/variables';
import { useDummyData } from '../../context/DummyDataProvider';

import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import { useApi } from '../../context/ApiProvider';
import { ApiHelper } from '../../common/ApiHelper/ApiHelper';
import { AddressRegions, CourierTypes, CreateCartRequest, DeliveryMethod, DeliveryTypes, EmailTypes, NotificationTypes, ProcessNotificationNewRequest, ProcessNotificationNewRequestContact, PublicGetDeliveryMethodsRequest, Cart, SparrowHubApiInterface, IntegrationTypes } from 'sparrowhub-client-axios';

import { IUser, UserRoleCode } from "../../types/IUsers";
import {
  IRequestBodyUpdateOrder,
  IRequestBodyProcessOrderRefund,
  IRequestBodyProcessPickupOrderCompletion,
  IRequestBodyProcessPickupOrderCreation,
  IRequestBodyProcessDeliveryCreation,
  IRequestBodySendNotification,
} from "../../types/IRequests";
import {
  IOrder,
  IOrders,
  ParcelType,
  OrderDeliveryType,
  OrderStatus,
  AddressState,
  AddressCountry,
  DeliveryModelCode,
  CourierType,
} from "../../types/IOrders";
import { ILocation, ILocations } from "../../types/ILocations";
import { RefundReason, RefundItems } from "../../types/IRefunds";
import { IOrderItem } from '../../types/IOrderItems';
import {
  formatOrderAddress,
  getCurrentTimestamp,
  formatPrice,
  formatLocationAddress,
  orderRequiresContact,
  orderQuantityRequiresContact,
  orderScheduleRequiresContact,
  formatCartLink,
} from "../../helpers/utils";
import { NotificationType } from '../../types/INotifications';
import { IResponseSendNotification } from '../../types/IResponses';

import { Process, ProcessOrderStep, processSteps } from "../../pages/ProcessOrderPage";
import { ProcessSteps } from "../../components/ProcessSteps/ProcessSteps";
import { Heading } from "../Heading/Heading";
import { OrderBadges } from '../OrderBadges/OrderBadges';
import { OrderDetails } from "../OrderDetails/OrderDetails";
import { OrderItems } from "../OrderItems/OrderItems";
import { Alert, AlertIcon, AlertType } from '../Alert/Alert';
import { Button, ButtonIcon, ButtonType, ButtonColour } from '../Button/Button';
import { SelectionButton } from '../SelectionButton/SelectionButton';
import { SelectionGroup, SelectionGroupInputType, SelectionGroupType, SelectionGroupOption } from '../SelectionGroup/SelectionGroup';
import { Modal } from '../Modal/Modal';
import { MultiModal } from '../MultiModal/MultiModal';
import { InputField } from '../InputField/InputField';
import { SelectInput, SelectInputOption } from '../SelectInput/SelectInput';
import { TextArea } from '../TextArea/TextArea';
import { CustomParcelInput } from '../CustomParcelInput/CustomParcelInput';
import { PickList } from '../PickList/PickList';
import { CollectionLabel } from '../CollectionLabel/CollectionLabel';
import { QuantitySelector } from '../QuantitySelector/QuantitySelector';
import { SearchBar } from '../SearchBar/SearchBar';
import { CustomProductModal } from '../CustomProductModal/CustomProductModal';
import { CustomerDetails } from '../CustomerDetails/CustomerDetails';
import { LochtingBoxLabel } from '../LochtingBoxLabel/LochtingBoxLabel';
import { Checkbox } from '../Checkbox/Checkbox';
import { PaymentAddress, PaymentInput } from '../PaymentInput/PaymentInput';
import { AddressInput } from '../AddressInput/AddressInput';

import prescriptionIcon from '../../assets/images/icons/Prescription.svg';
import perfumeIcon from '../../assets/images/icons/Perfume.svg';
import nailPolishIcon from '../../assets/images/icons/NailPolish.svg';
import aerosolIcon from '../../assets/images/icons/Aerosol.svg';
import loosePowderIcon from '../../assets/images/icons/LoosePowder.svg';
import flammableIcon from '../../assets/images/icons/Flammable.svg';
import trashIcon from '../../assets/images/icons/Trash.svg';
import shoppingBagIcon from '../../assets/images/icons/ShoppingBag.svg';
import shippingIcon from '../../assets/images/icons/Shipping.svg';
import dollarIcon from '../../assets/images/icons/Dollar.svg';
import personIcon from '../../assets/images/icons/Person.svg';
import pickupIcon from '../../assets/images/icons/Pickup.svg';
import dropoffIcon from '../../assets/images/icons/Dropoff.svg';
import collectAsapIcon from '../../assets/images/icons/Bolt.svg';
import collectLaterIcon from '../../assets/images/icons/Later.svg';

import auspostLogo from '../../assets/images/logos/AustraliaPost.png';
import doordashLogo from '../../assets/images/logos/Doordash.png';

import boxA4Image from '../../assets/images/parcels/box-a4.png';
import boxCubeImage from '../../assets/images/parcels/box-cube.png';
import satchelImage from '../../assets/images/parcels/satchel.png';

import dummyShippingLabel from '../../assets/demoDocuments/label-smiths.pdf';
import placeholderOverboxCode from '../../assets/images/graphics/placeholder_overbox_code.png';

// eslint-disable-next-line
import pickupEmailTemplate from '!!raw-loader!../../assets/emails/hub-pickup-matic-content.html';
import cartEmailTemplate from '!!raw-loader!../../assets/emails/hub-pending-cart-content.html';

// configure react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';
const options = {
  cMapUrl: 'cmaps/',
  standardFontDataUrl: 'standard_fonts/',
};

enum customBox {
  CustomBox = "box-custom"
}

enum CollectionTime {
  Custom = "custom-courier",
  Asap = "asap",
}

const dummyProducts = [
  {
    id: 0,
    sku: 37168330,
    name: 'Rizatriptan Wafer 10mg x 4',
    tag: 'Private Prescription Pricing',
    price: 19.98,
    qty: 1
  },
  {
    id: 1,
    sku: 37168331,
    name: 'Rizatriptan Wafer 10mg x 4',
    tag: 'PBS Pricing',
    price: 19.98,
    qty: 1
  },
  {
    id: 2,
    sku: 37168332,
    name: 'Rizatriptan Wafer 10mg x 4',
    tag: 'Concession PBS Pricing',
    price: 10.22,
    qty: 1
  },
  {
    id: 3,
    sku: 37168333,
    name: 'Rizatriptan Wafer 10mg x 4',
    tag: 'Safety Net Price',
    price: 3.92,
    qty: 1
  }
]

// selection group options
const stateOptions: Array<SelectInputOption> = [
  {
    value: undefined,
    label: ''
  },
  ...Object.values(AddressState).map((state) => {
    return {
      value: state,
      label: state
    }
  })
];

const countryOptions: Array<SelectInputOption> = Object.values(AddressCountry).map((country) => {
  return {
    value: country,
    label: country
  }
});

const declareOptions: Array<SelectionGroupOption> = [
  {
    id: 'prescriptionMedication',
    value: 'prescriptionMedication',
    label: 'Prescription Medication',
    iconSrc: prescriptionIcon
  },
  {
    id: 'perfume',
    value: 'perfume',
    label: 'Perfume',
    iconSrc: perfumeIcon
  },
  {
    id: 'nailPolish',
    value: 'nailPolish',
    label: `Nail Polish /\nNail Polish Remover`,
    iconSrc: nailPolishIcon
  },
  {
    id: 'aerosol',
    value: 'aerosol',
    label: `Aerosol Can\n(e.g. hairspray)`,
    iconSrc: aerosolIcon
  },
  {
    id: 'loosePowder',
    value: 'loosePowder',
    label: 'Loose Powder',
    iconSrc: loosePowderIcon
  },
  {
    id: 'otherFlammable',
    value: 'otherFlammable',
    label: 'Other Flammable Liquid',
    iconSrc: flammableIcon
  },
  {
    id: 'none',
    value: 'none',
    label: 'None of the above'
  },
];

const orderBagOptions: Array<SelectionGroupOption> = [
  {
    id: '1',
    value: '1',
    label: '1'
  },
  {
    id: '2',
    value: '2',
    label: '2'
  },
  {
    id: '3',
    value: '3',
    label: '3'
  },
  {
    id: 'custom',
    value: 'custom',
    label: 'Custom'
  }
];

const lochtingBoxOptions: Array<SelectionGroupOption> = [
  {
    id: 'small',
    value: 'small',
    label: 'Small'
  },
  {
    id: 'large',
    value: 'large',
    label: 'Large'
  },
];

const ausPostDispatchTypeOptions: Array<SelectionGroupOption> = [
  {
    id: "collection",
    value: DeliveryModelCode.Pickup,
    label: "Pickup",
    subtitle: "Order will be collected at the next pickup organised",
    iconSrc: pickupIcon,
  },
  {
    id: "dropoff",
    value: DeliveryModelCode.Dropoff,
    label: "Drop Off",
    subtitle: "Order must be dropped off at local Australia Post",
    iconSrc: dropoffIcon,
  },
];

const collectionTimeOptions: Array<SelectionGroupOption> = [
  {
    id: 'asap',
    value: 'asap',
    label: 'As soon as possible',
    iconSrc: collectAsapIcon
  },
  {
    id: 'later',
    value: 'custom-courier',
    label: 'Schedule for later',
    iconSrc: collectLaterIcon
  }
]

const confirmIdOptions: Array<SelectionGroupOption> = [
  {
    id: 'drivers-license',
    value: 'drivers-license',
    label: 'Driver\'s License'
  },
  {
    id: 'student-id',
    value: 'student-id',
    label: 'Student ID'
  },
  {
    id: 'nsw-photo-card',
    value: 'nsw-photo-card',
    label: 'NSW Photo Card'
  },
  {
    id: 'passport',
    value: 'passport',
    label: 'Passport'
  },
  {
    id: 'seniors-card',
    value: 'seniors-card',
    label: 'Seniors Card'
  },
  {
    id: 'custom',
    value: 'custom',
    label: 'Other'
  }
];

const refundReasonOptions: Array<SelectionGroupOption> = [
  {
    id: 'change-of-mind',
    value: RefundReason.CustomerChangeMind,
    label: 'Change of mind'
  },
  {
    id: 'received-incorrect-product',
    value: RefundReason.FulfilmentWrongProduct,
    label: 'Received incorrect product'
  },
  {
    id: 'product-faulty',
    value: RefundReason.FulfilmentFaultyProduct,
    label: 'Product was faulty or damaged'
  },
  {
    id: 'ordered-incorrect-product',
    value: RefundReason.CustomerMistake,
    label: 'Customer ordered the\nwrong product'
  },
  {
    id: 'custom',
    value: RefundReason.Other,
    label: 'Other'
  }
];

const deliveryRefundReasonOptions: Array<SelectionGroupOption> = [
  {
    id: 'did-not-receive',
    value: RefundReason.Delivery,
    label: 'Did not receive product'
  },
  {
    id: 'convert-click-collect',
    value: RefundReason.CustomerRequest,
    label: 'Decided to Click & Collect'
  },
  {
    id: 'custom',
    value: RefundReason.Other,
    label: 'Other'
  }
];

const deliveryTypeOptions: Array<SelectionGroupOption> = [
  {
    id: 'delivery',
    value: 'delivery',
    label: 'Delivery',
  },
  {
    id: 'clickcollect',
    value: 'clickcollect',
    label: 'Click & Collect',
  }
];

const deliveryProviderOptions: Array<SelectionGroupOption> = [
  {
    id: 'standard',
    value: 'standard',
    label: 'Standard Shipping',
    subtitle: 'Delivered in 5–7 business days',
    price: formatPrice(10),
    provider: 'Australia Post',
    iconSrc: auspostLogo,
    colour: '#E12626'
  },
  {
    id: 'express',
    value: 'express',
    label: 'Express Shipping',
    subtitle: 'Delivered in 1–3 business days',
    price: formatPrice(15),
    provider: 'Australia Post',
    iconSrc: auspostLogo,
    colour: '#E12626'
  },
  {
    id: 'sameday',
    value: 'sameday',
    label: 'Same Day Shipping',
    subtitle: 'Delivered within 5 hours',
    price: formatPrice(12),
    provider: 'DoorDash',
    iconSrc: doordashLogo,
    colour: '#FF2C00'
  },
];

const enableInSituPartialRefunds = false;

type ProcessOrderModalProps = {
  user: IUser
  location: ILocation
  orderId: string
  process: Process
  steps: Array<ProcessOrderStep>
  currentStepIndex: number
  next: Function
  back?: Function
  setStep: Function
  orders: IOrders
  partnerLocations: ILocations
  createOrderItems: Array<any>
  setCreateOrderItems: Function
  setShowSearchResults: Function
  searchQuery: string
  setSearchQuery: Function
  onLogout: Function
}


export const ProcessOrderModal: FunctionComponent<ProcessOrderModalProps> = ({ user, location, orderId, process: orderProcess, steps, currentStepIndex, next, back, setStep, orders, partnerLocations, createOrderItems, setCreateOrderItems, setShowSearchResults, searchQuery, setSearchQuery, onLogout }) => {
  const navigate = useNavigate();
  const transitionRef = useRef(null);
  const printRef = useRef(null);
  const printShippingLabelRef = useRef(null);

  const { apiHelper, api }: { apiHelper: ApiHelper; api: SparrowHubApiInterface, basePath: string } = useApi();

  // get dummy data context
  const dummyData: any = useDummyData();

  const order: IOrder = dummyData.state.useDummyData
    ? orderProcess === Process.CreateOrder
      ? dummyData.getters.orderById(0)
      : dummyData.getters.orderById(orderId)
    : orderProcess === Process.CreateOrder
      ? dummyData.getters.orderById(0)
      : orders.find(order => order.id === parseInt(orderId));

  const orderLocation: ILocation = partnerLocations.find(location => location.code === order.location_code)!;

  // modals
  const [showPartialRefundModal, setShowPartialRefundModal] = useState(false);
  const [showPerfumeModal, setShowPerfumeModal] = useState(false);
  const [showFlammableModal, setShowFlammableModal] = useState(false);
  const [showAerosolModal, setShowAerosolModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showCustomProductModal, setShowCustomProductModal] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showEjectMessage, setShowEjectMessage] = useState(false);

  // process data
  const [declaredCategories, setDeclaredCategories] = useState([] as Array<string>);
  const [perfumeConfirmation, setPerfumeConfirmation] = useState(undefined as boolean | undefined);
  const [aerosolConfirmation, setAerosolConfirmation] = useState(undefined as boolean | undefined);
  const [flammableConfirmation, setFlammableConfirmation] = useState(undefined as boolean | undefined);
  const [parcelTypes, setParcelTypes] = useState([''] as Array<string>);
  const [customParcelDimensions, setCustomParcelDimensions] = useState([[undefined, undefined, undefined]] as Array<Array<number | undefined>>);
  const [parcelWeights, setParcelWeights] = useState([undefined] as Array<number | undefined>);
  const [orderBags, setOrderBags] = useState('');
  const [customOrderBags, setCustomOrderBags] = useState(undefined as number | undefined);
  const [confirmIdMethod, setConfirmIdMethod] = useState('');
  const [customConfirmIdMethod, setCustomConfirmIdMethod] = useState('');
  const [additionalNotes, setAdditionalNotes] = useState('');
  const [refundType, setRefundType] = useState('');
  const [partialRefundItems, setPartialRefundItems] = useState(order.items.map((item: any) => { return { ...item, qtyToRefund: item.qty_refunded || 0 } }));
  const [tempPartialRefundItems, setTempPartialRefundItems] = useState(order.items.map((item: any) => { return { ...item, qtyToRefund: item.qty_refunded || 0 } }));
  const [partialRefundItemIds, setPartialRefundItemIds] = useState([] as Array<string>);
  const [refundReason, setRefundReason] = useState('');
  const [customRefundReason, setCustomRefundReason] = useState('');
  const [refundAmount, setRefundAmount] = useState(undefined as number | undefined);
  const [ausPostDispatchType, setAusPostDispatchType] = useState(undefined as DeliveryModelCode | '' | undefined);
  const [collectionTime, setCollectionTime] = useState('');
  const [customCollectionTime, setCustomCollectionTime] = useState('');
  const [finalReservationId, setFinalReservationId] = useState('');
  const [deliveryOptions, setDeliveryOptions] = useState<Array<SelectionGroupOption>>([]);
  const [showDeliveryOptions, setShowDeliveryOptions] = useState(false);
  const [selectedDeliveryOption, setSelectedDeliveryOption] = useState('');
  const [deliveryObjects, setDeliveryObjects] = useState<Array<any>>([]);
  const [generatedCart, setGeneratedCart] = useState<Cart | null>(null);
  const [cartNotificationsSent, setCartNotificationsSent] = useState<Array<string>>([]);

  const [deliveryType, setDeliveryType] = useState('');
  const [deliveryProvider, setDeliveryProvider] = useState('');

  const [deliveryFirstName, setDeliveryFirstName] = useState('');
  const [deliveryLastName, setDeliveryLastName] = useState('');
  const [deliveryEmail, setDeliveryEmail] = useState('');
  const [deliveryPhone, setDeliveryPhone] = useState('');
  const [deliveryStreet1, setDeliveryStreet1] = useState('');
  const [deliveryStreet2, setDeliveryStreet2] = useState('');
  const [deliverySuburb, setDeliverySuburb] = useState('');
  const [deliveryPostcode, setDeliveryPostcode] = useState('');
  const [deliveryState, setDeliveryState] = useState<AddressState | ''>('');
  const [deliveryCountry, setDeliveryCountry] = useState(AddressCountry.AU);
  const [billingFirstName, setBillingFirstName] = useState('');
  const [billingLastName, setBillingLastName] = useState('');
  const [billingEmail, setBillingEmail] = useState('');
  const [billingPhone, setBillingPhone] = useState('');
  const [billingStreet1, setBillingStreet1] = useState('');
  const [billingStreet2, setBillingStreet2] = useState('');
  const [billingSuburb, setBillingSuburb] = useState('');
  const [billingPostcode, setBillingPostcode] = useState('');
  const [billingState, setBillingState] = useState<AddressState | ''>('');
  const [billingCountry, setBillingCountry] = useState(AddressCountry.AU);
  const [useDeliveryForBilling, setUseDeliveryForBilling] = useState(true);

  const [paymentType, setPaymentType] = useState('');
  const [paymentReceived, setPaymentReceived] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showCustomErrorMessage, setShowCustomErrorMessage] = useState(false);
  const [parcelOptions, setParcelOptions] = useState([] as Array<SelectionGroupOption>);
  const [processDeliveryCreationData, setProcessDeliveryCreationData] = useState<any>([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [scheduleContact, setScheduleContact] = useState(false);

  const [shippingLabel, setShippingLabel] = useState(undefined as any | undefined);
  const [numPages, setNumPages] = useState(0);
  const [overboxCodeSrc, setOverboxCodeSrc] = useState('');
  const [lochtingDemoErrorState, setLochtingDemoErrorState] = useState(false);
  // const [shouldPrint, setShouldPrint] = useState(false);

  // TOTAL PRICE HANDLED HERE
  const totalPrice = order.items.reduce((acc, price: any) => {
    return acc + parseFloat(price?.total);
  }, 0);

  // create selection group options for partial refund items
  const partialRefundItemOptions: Array<SelectionGroupOption> = order.items.map((item: IOrderItem) => {
    return {
      id: item.sku,
      value: item.sku,
      label: item.name,
      subtitle: `SKU: ${item.sku}`,
      disabled: item.is_prescription
    }
  })

  const handleSetDeliveryAddress = (payload: Partial<PaymentAddress>): void => {
    if (Object.hasOwn(payload, 'first_name') && payload.first_name !== undefined ) setDeliveryFirstName(payload.first_name);
    if (Object.hasOwn(payload, 'last_name') && payload.last_name !== undefined ) setDeliveryLastName(payload.last_name);
    if (Object.hasOwn(payload, 'email') && payload.email !== undefined ) setDeliveryEmail(payload.email);
    if (Object.hasOwn(payload, 'phone') && payload.phone !== undefined ) setDeliveryPhone(payload.phone);
    if (Object.hasOwn(payload, 'street1') && payload.street1 !== undefined ) setDeliveryStreet1(payload.street1);
    if (Object.hasOwn(payload, 'street2') && payload.street2 !== undefined ) setDeliveryStreet2(payload.street2);
    if (Object.hasOwn(payload, 'suburb') && payload.suburb !== undefined ) setDeliverySuburb(payload.suburb);
    if (Object.hasOwn(payload, 'state') && payload.state !== undefined ) setDeliveryState(payload.state);
    if (Object.hasOwn(payload, 'postcode') && payload.postcode !== undefined ) setDeliveryPostcode(payload.postcode);
  }
  
  const handleSetBillingAddress = (payload: Partial<PaymentAddress>): void => {
    if (Object.hasOwn(payload, 'first_name') && payload.first_name !== undefined ) setBillingFirstName(payload.first_name);
    if (Object.hasOwn(payload, 'last_name') && payload.last_name !== undefined ) setBillingLastName(payload.last_name);
    if (Object.hasOwn(payload, 'email') && payload.email !== undefined ) setBillingEmail(payload.email);
    if (Object.hasOwn(payload, 'phone') && payload.phone !== undefined ) setBillingPhone(payload.phone);
    if (Object.hasOwn(payload, 'street1') && payload.street1 !== undefined ) setBillingStreet1(payload.street1);
    if (Object.hasOwn(payload, 'street2') && payload.street2 !== undefined ) setBillingStreet2(payload.street2);
    if (Object.hasOwn(payload, 'suburb') && payload.suburb !== undefined ) setBillingSuburb(payload.suburb);
    if (Object.hasOwn(payload, 'state') && payload.state !== undefined ) setBillingState(payload.state);
    if (Object.hasOwn(payload, 'postcode') && payload.postcode !== undefined ) setBillingPostcode(payload.postcode);
  }

  // computed properties
  const currentStep = (): ProcessOrderStep => {
    return steps[currentStepIndex]
  }

  const selectedDeliveryObject = (): any => {
    return deliveryObjects.find(object => object.code === selectedDeliveryOption);
  }
  
  const deliveryAddress = (): PaymentAddress => {
    return {
      first_name: deliveryFirstName,
      last_name: deliveryLastName,
      email: deliveryEmail,
      phone: deliveryPhone,
      street1: deliveryStreet1,
      street2: deliveryStreet2,
      suburb: deliverySuburb,
      state: deliveryState,
      postcode: deliveryPostcode
    }
  }
  
  const billingAddress = (): PaymentAddress => {
    return {
      first_name: billingFirstName,
      last_name: billingLastName,
      email: billingEmail,
      phone: billingPhone,
      street1: billingStreet1,
      street2: billingStreet2,
      suburb: billingSuburb,
      state: billingState,
      postcode: billingPostcode
    }
  }

  const requiresContactText = (): string => {
    const qtyFlag = orderQuantityRequiresContact(order);
    const scheduleFlag = orderScheduleRequiresContact(order);

    if (qtyFlag && scheduleFlag) {
      return 'Patient has received a pharmacist consultation regarding scheduled pharmacy medication AND the quantity of flagged items'
    } else if (qtyFlag) {
      return 'Patient has received a pharmacist consultation regarding the quantity of flagged items'
    } else if (scheduleFlag) {
      return 'Patient has received a pharmacist consultation regarding scheduled pharmacy medication'
    } else {
      return '';
    }
  }

  const ejectReservationLabel = (): string => {
    if (overboxCodeSrc) {
      return 'Order retrieved!';
    } else {
      return 'Retrieve from Robot';
    }
  }

  const createdOrder = (): any => {
    return {
      id: '000',
      platform_order_no: 'Phone Order',
      partner_id: '999',
      created_offset: 0,
      created_at: '',
      updated_at: '',
      is_visible: false,
      deliveryType: 'standard',
      orderNumber: 'Phone Order',
      dispatchOrganised: false,
      status: 'open',
      delivery_firstname: deliveryFirstName,
      delivery_lastname: deliveryLastName,
      delivery_phone: deliveryPhone,
      delivery_email: deliveryEmail,
      delivery_street: deliveryStreet2 !== '' ? `["${deliveryStreet1}","${deliveryStreet2}"]` : `["${deliveryStreet1}"]`,
      delivery_city: deliverySuburb,
      delivery_state_code: deliveryState,
      delivery_postcode: deliveryPostcode,
      delivery_country_code: deliveryCountry,
      totalPrice: 0,
      items: [],
      notes: []
    }
  }

  const getOrderTotal = (order: IOrder): number => {
    return parseFloat(order.total)
  }

  const getRefundTotal = (order: IOrder): number => {
    const refundAdjustment = order.refund_adjustment ? parseFloat(order.refund_adjustment) : 0;
    const refundDelivery = order.refund_delivery ? parseFloat(order.refund_delivery) : 0;
    let total = refundAdjustment + refundDelivery;
    order.items.forEach(item => {
      total += item.total * (item.qty_refunded || 0);
    });
    return total;
  }

  const getUnrefundedItemTotal = (order: IOrder): number => {
    let total = 0;
    order.items.forEach(item => {
      total += item.total * ((item.qty_ordered || 0) - (item.qty_refunded || 0));
    });
    return total;
  }

  const refundTypeOptions = (): Array<SelectionGroupOption> => {
    // calculate refund values
    const orderTotal = getOrderTotal(order);
    const refundTotal = getRefundTotal(order);
    const unrefundedItemTotal = getUnrefundedItemTotal(order);

    // check refund type validity
    const completeRefundDisabled = refundTotal > 0;
    const customRefundDisabled = refundTotal >= orderTotal;

    const partialCond1 = order.items.every(item => (item.qty_ordered || 0) > 0 && ((item.qty_refunded || 0) === (item.qty_ordered || 0)));
    const partialCond2 = (order.refund_adjustment ? parseFloat(order.refund_adjustment) : 0) > (parseFloat(order.delivery) - parseFloat(order.refund_delivery));
    const partialCond3 = customRefundDisabled;
    const partialRefundDisabled = partialCond1 || partialCond2 || partialCond3;

    const deliveryCond1 = (order.refund_delivery ? parseFloat(order.refund_delivery) : 0) > 0;
    const deliveryCond2 = (order.refund_adjustment ? parseFloat(order.refund_adjustment) : 0) > unrefundedItemTotal;
    const deliveryCond3 = customRefundDisabled;
    const deliveryCond4 = order.delivery_type_code === DeliveryTypes.Pickup;
    const deliveryRefundDisabled = deliveryCond1 || deliveryCond2 || deliveryCond3 || deliveryCond4;

    // console.log({
    //   orderValues: {
    //     order,
    //     orderItems: order.items,
    //     orderTotal,
    //     refundTotal
    //   },
    //   refundOptionsDisabled: {
    //     partial: {
    //       partialCond1,
    //       partialCond2,
    //       partialCond3,
    //       partialRefundDisabled
    //     },
    //     complete: {
    //       completeRefundDisabled,
    //     },
    //     custom: {
    //       customRefundDisabled
    //     },
    //     delivery: {
    //       deliveryCond1,
    //       deliveryCond2,
    //       deliveryCond3,
    //       deliveryCond4,
    //       deliveryRefundDisabled
    //     }
    //   }
    // })

    // return types
    return [
      {
        id: 'partial-refund',
        value: 'partial-refund',
        label: 'Partial Refund',
        subtitle: '(refund and return of certain items)',
        disabled: partialRefundDisabled
      },
      {
        id: 'complete-refund',
        value: 'complete-refund',
        label: 'Complete Refund',
        subtitle: '(refund and return of the whole order)',
        disabled: completeRefundDisabled
      },
      {
        id: 'custom-refund',
        value: 'custom-refund',
        label: 'Custom Refund',
        subtitle: '(refund of a custom value)',
        disabled: customRefundDisabled
      },
      {
        id: 'delivery-refund',
        value: 'delivery-refund',
        label: 'Delivery Only Refund',
        subtitle: '(refund of the delivery fee)',
        disabled: deliveryRefundDisabled
      }
    ]
  };

  const partnerName = (): string => {
    if (dummyData.state.useDummyData) {
      return dummyData.state.dummyLocation.name;
    } else {
      switch (order.partner_id) {
        case 3:
          return 'Chemistworks';
        case 4:
          return 'Cat & Claudia\'s Community Pharmacy';
        case 5:
        case 6:
          return 'Southcity Pharmacy';
        case 7: // Baldwin Dev
        case 8: // Smith's Pharmacy
          return 'Smith\'s Pharmacy';
        case 11:
          return 'Priceline Pharmacy';
        default:
          return '';
      }
    }
  }

  const customerDetailsAreValid = (): boolean => {
    const formEl = (document.getElementById('form_processOrderModal-delivery') as HTMLFormElement);
    
    const deliveryIsValid = (
      deliveryFirstName !== '' &&
      deliveryLastName !== '' &&
      deliveryEmail !== '' &&
      deliveryPhone !== '' &&
      deliveryStreet1 !== '' &&
      deliverySuburb !== '' &&
      deliveryPostcode !== '' && deliveryPostcode.length === 4 &&
      deliveryState !== '' &&
      deliveryCountry !== null
    )

    const billingIsValid = useDeliveryForBilling || (
      billingFirstName !== '' &&
      billingLastName !== '' &&
      billingEmail !== '' &&
      billingPhone !== '' &&
      billingStreet1 !== '' &&
      billingSuburb !== '' &&
      billingPostcode !== '' && billingPostcode.length === 4 &&
      billingState !== null &&
      billingCountry !== null
    )

    return deliveryIsValid
      && billingIsValid
      && (!formEl || (formEl && formEl.checkValidity()))
      && (!showDeliveryOptions || (showDeliveryOptions && selectedDeliveryOption !== ''))
  }
  
  const campaignDetailsAreValid = (): boolean => {
    const formEl = (document.getElementById('form_processOrderModal-campaign') as HTMLFormElement);
    
    return true;
  }

  const deliveryTypeLabel = (type: string): string => {
    let result = '';
    if (type === 'delivery') {
      result = 'Delivery';
    } else if (type === 'clickcollect') {
      result = 'Click & Collect'
    }
    return result;
  }

  const deliveryLabel = (type: string, provider: string): string => {
    if (type !== 'delivery') return 'N/A'
    const option = deliveryProviderOptions.find((option: any) => option.value === provider);
    if (!option) return '';
    return `${option.provider} - ${option.label}`;
  }

  const deliveryPrice = (type: string, provider: string): string => {
    if (type !== 'delivery') return '$0.00'
    const option = deliveryProviderOptions.find((option: any) => option.value === provider);
    if (!option) return '';
    return option.price!;
  }

  const partialRefundIncludesAnyItems = (): boolean => {
    return partialRefundItems.some(item => item.qtyToRefund !== 0);
  }

  const partialRefundIncludesAllItems = (): boolean => {
    return partialRefundItems.every(item => item.qty_ordered - item.qtyToRefund === 0);
  }


  const weightIsInvalid = (weight: any): boolean => {
    return weight === undefined || weight === 0 || isNaN(weight);
  }

  const formatParcelDimensions = (dimensions: Array<number>): string => {
    return `${dimensions[0]} x ${dimensions[1]} x ${dimensions[2]} cm`
  }

  const parcelSelectionIsInvalid = (): boolean => {
    return parcelTypes.some((type, index) => {
      if (type !== 'box-custom') {
        return type === '';
      } else {
        return customParcelDimensions[index].some(dimension => {
          return dimension === undefined || dimension === 0 || isNaN(dimension);
        });
      }
    })
  }

  const orderBagsIsInvalid = (): boolean => {
    if (orderBags === 'custom') {
      return customOrderBags === undefined || customOrderBags === 0 || isNaN(customOrderBags);
    } else {
      return orderBags === '';
    }
  }

  const customStringFieldIsInvalid = (string: string, customString: string): boolean => {
    if (string === 'custom' || string === 'other') {
      return customString.trim() === '';
    } else {
      return string === '';
    }
  }

  const refundAmountIsInvalid = (): boolean => {
    return refundAmount === undefined || refundAmount === 0 || isNaN(refundAmount);
  }

  const filteredRefundItems = (): Array<any> => {
    let result: Array<any> = [];
    let duplicateItems = JSON.parse(JSON.stringify(partialRefundItems));
    duplicateItems.forEach((item: any) => {
      if (item.qtyToRefund !== 0) {
        const clone = JSON.parse(JSON.stringify(item));
        clone.qty = clone.qtyToRefund;
        result.push(clone);
      }
    });
    return result;
  }

  const formattedRefundReason = (): string => {
    let result = '';
    switch (refundReason) {
      case RefundReason.CustomerChangeMind:
        result = 'Change of mind';
        break;
      case RefundReason.FulfilmentWrongProduct:
        result = 'Customer received incorrect product';
        break;
      case RefundReason.FulfilmentFaultyProduct:
        result = 'Product was faulty or damaged';
        break;
      case RefundReason.CustomerMistake:
        result = 'Customer ordered the wrong product';
        break;
      case RefundReason.Delivery:
        result = 'Customer did not receive product';
        break;
      case RefundReason.CustomerRequest:
        result = 'Customer decided to Click & Collect';
        break;
      case RefundReason.Other:
        result = customRefundReason;
        break;
    }
    return result;
  }

  const partialRefundCreated = (): boolean => {
    return !partialRefundItems.every((item: any) => item.qtyToRefund === 0);
  }

  const numItemsAfterRefund = (): number => {
    return (partialRefundItems as Array<any>).reduce((acc, item) => {
      return acc + (item.qty_ordered - item.qtyToRefund);
    }, 0);
  }

  const numRequiredParcels = (): number => {
    return perfumeConfirmation || aerosolConfirmation || flammableConfirmation
      ? 2
      : 0
  }

  const requiredParcelMessage = (): string => {
    const limits = [];
    if (aerosolConfirmation) limits.push('50mL of aerosol');
    if (perfumeConfirmation && !flammableConfirmation) limits.push('300mL of perfume');
    if (flammableConfirmation && !perfumeConfirmation) limits.push('300mL of flammable liquids');
    if (flammableConfirmation && perfumeConfirmation) limits.push('300mL of flammable liquids (including perfumes)');

    let joinedLimits = limits.join(' and ');
    return `Each parcel cannot contain more than ${joinedLimits}. This order may require more than 2 parcels.`;
  }

  const customCollectionTimeOptions = (): Array<SelectInputOption> => {
    const hours: Array<number> = [];
    for (let i = new Date().getHours() + 1; i < 24; i++) {
      hours.push(i);
    }

    // format?
    // store close?

    return [
      {
        value: '',
        label: ''
      },
      ...hours.map((hour) => {
        let modStart = hour % 12;
        if (modStart === 0) modStart = 12;
        let reModStart = modStart.toString().padStart(2, "0");
        let modEnd = (hour + 1) % 12;
        if (modEnd === 0) modEnd = 12;
        let reModEnd = modEnd.toString().padStart(2, "0");

        const startString = `${reModStart} ${hour < 12 ? 'am' : 'pm'}`;
        const endString = `${reModEnd} ${hour + 1 < 12 || hour + 1 === 24 ? 'am' : 'pm'}`;

        return {
          value: `${startString} – ${endString}`,
          label: `${startString} – ${endString}`
        }
      })
    ];
  }

  // methods
  const handleCreateCart = (): void => {
    setIsLoading(true);
    setErrorMessage('');

    const requestBody: CreateCartRequest = {
      location_code: location!.code,
      items: formattedCartItemData(),
      is_enabled: true,
      target_integration_type: IntegrationTypes.SparrowhubCart
    }

    // include delivery & billing data if available
    if (selectedDeliveryObject()) {
      requestBody.delivery = {
        first_name: deliveryFirstName,
        last_name: deliveryLastName,
        email: deliveryEmail,
        phone: deliveryPhone,
        address: {
          street: JSON.stringify([deliveryStreet1, deliveryStreet2]),
          city: deliverySuburb,
          state_code: deliveryState as AddressRegions,
          postcode: deliveryPostcode,
          country_code: AddressCountry.AU,
        },
        delivery_type_code: selectedDeliveryObject().delivery_type_code as DeliveryTypes,
        courier_type_code: selectedDeliveryObject().courier_type_code as CourierTypes,
        total: '0.0',
        tax: '0.0'
      };
      // requestBody.billing = {
      //   first_name: '',
      //   last_name: '',
      //   email: '',
      //   phone: '',
      //   address: {
      //     street: JSON.stringify([billingStreet1, billingStreet2]),
      //     city: billingSuburb,
      //     state_code: billingState as AddressRegions,
      //     postcode: billingPostcode,
      //     country_code: AddressCountry.AU,
      //   }
      // };
    }

    console.log('request body', requestBody);

    api.createCart(requestBody).then((response) => {
      console.log(response);
      // handle success
      setIsLoading(false);
      const cart: Cart = response.data.data;
      console.log(cart);
      setGeneratedCart(cart);

      // skip to send link step
      setStep(processSteps[orderProcess as keyof typeof processSteps].length - 1);
    })
    .catch(error => {
      // handle error
      setIsLoading(false);
      // setErrorMessage(error.response.data.message);
      setErrorMessage('Error creating cart for custom order.');

      console.log('error!')
      console.log(error)
    })
  }

  const handleSendCartLinkSMS = async (recaptcha: string): Promise<any> => {
    if (deliveryPhone !== '') {
      // set state
      setErrorMessage('');
      setIsLoading(true);

      // generate reCAPTCHA token
      await grecaptcha.enterprise.ready(async () => {
        const token = await grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_KEY, {action: 'contact'});
        sendCartLinkSMS(token);
      });
    }
  }

  const sendCartLinkSMS = async (recaptcha: string): Promise<any> => {
    let notificationText = `Your order is pending payment.\n\nPlease review and checkout at ${formatCartLink(generatedCart!.reference, false)} \n\nRegards, ${location!.name}`;
    const requestBody: ProcessNotificationNewRequest = {
      notification_type_code: NotificationTypes.Sms,
      security_token: recaptcha,
      message: notificationText,
      partner: {
        id: user.partner_id
      },
      contact: {
        first_name: deliveryFirstName || '',
        last_name: deliveryLastName || '',
        email: deliveryEmail || '',
        phone: deliveryPhone
      },
    }

    console.log('request body')
    console.log(requestBody)

    api.publicProcessNewNotification(requestBody)
      .then(response => {
        console.log('notification response')
        console.log(response)
        let mutatedNotificationList = JSON.parse(JSON.stringify(cartNotificationsSent));
        mutatedNotificationList.push('sms');
        setCartNotificationsSent(mutatedNotificationList);
        
        // TODO: show success message
      })
      .catch(error => {
        console.log('Error sending notification')
        console.log(error)
        setErrorMessage('Error sending customer notification.');
      })
  }
  
  const handleSendLinkBack = (): void => {
    if (back !== undefined) {
      if (deliveryFirstName !== '') {
        // if delivery details have been entered, return to delivery step
        back(2);
      } else {
        // else return to SelectItems step
        back(3);
      }
    }
  }

  const handleHideDeliveryOptions = (): void => {
    setDeliveryOptions([]);
    setDeliveryObjects([]);
    setSelectedDeliveryOption('');

    setShowDeliveryOptions(false);
  }
  
  const formattedDeliveryItemData = (): Array<any> => {
    return createOrderItems.map((item: any) => {
      return {
        sku: item.sku,
        name: item.name,
        qty: item.qty_ordered,
        price: item.total,
        tax: item.gst ? (item.total / 11).toFixed(2) : '0',
        is_prescription: false
      }
    })
  }
  
  const formattedCartItemData = (): Array<any> => {
    return createOrderItems.map((item: any) => {
      return {
        sku: item.sku,
        name: item.name,
        qty_ordered: item.qty_ordered,
        price: item.total,
        tax: item.gst ? (item.total / 11).toFixed(2) : '0',
        is_prescription: false,
        requires_contact: false
      }
    })
  }

  const handleShowDelivery = async (): Promise<void> => {
    // set state
    setErrorMessage('');
    setIsLoading(true);

    // generate reCAPTCHA token
    await grecaptcha.enterprise.ready(async () => {
      const token = await grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_KEY, {action: 'get_delivery_methods'});
      handleGetDeliveryMethods(token);
    });
  }

  const handleGetDeliveryMethods = async (token: string): Promise<void> => {
    const requestBody: PublicGetDeliveryMethodsRequest = {
      security_token: token,
      is_live_quote: true,
      delivery_method_codes: ['auspost-standard', 'auspost-express', 'doordash', 'pickup'],
      partner: {
        id: user.partner_id,
        location_code: location.code
      },
      customer: {
        first_name: deliveryFirstName,
        last_name: deliveryLastName,
        email: deliveryEmail,
        phone: deliveryPhone
      },
      delivery: {
        first_name: deliveryFirstName,
        last_name: deliveryLastName,
        email: deliveryEmail,
        phone: deliveryPhone,
        address: {
          street: JSON.stringify([deliveryStreet1, deliveryStreet2]),
          city: deliverySuburb,
          state_code: deliveryState as AddressRegions,
          postcode: deliveryPostcode,
          country_code: AddressCountry.AU,
        }
      },
      items: formattedDeliveryItemData()
    }

    console.log('request body', requestBody);

    api.publicGetDeliveryMethods(requestBody).then((response) => {
      // handle success
      setIsLoading(false);
      const data = JSON.parse(response.data.data as any);
      setDeliveryObjects(data);

      const mappedData: Array<SelectionGroupOption> = data.filter((method: DeliveryMethod) => method.is_enabled)
        .map((method: DeliveryMethod) => {
          return {
            id: method.code,
            value: method.code,
            label: method.name,
            subtitle: method.code === 'pickup' ? `Ready for collection in 3 hours` : method.delivery_estimate,
            price: formatPrice(method.total)
            // provider: 'Australia Post',
            // iconSrc: auspostLogo,
            // colour: '#E12626'
          }
        });
      setDeliveryOptions(mappedData);

      // set UI state
      setShowDeliveryOptions(true);
      setTimeout(() => {
        window.scrollTo({
          top: 9999,
          left: 0,
          behavior: 'smooth'
        });
      }, 100);
    })
    .catch(error => {
      // handle error
      setIsLoading(false);
      setErrorMessage(error.response.data.message);
      setDeliveryOptions([]);

      console.log('error!')
      console.log(error)
    })
  }

  const retrieveOverboxCodeSrc = (finalReservationId: string) => {
    api.getRobotOverboxCode(finalReservationId, { responseType: 'blob' })
      .then((response: any) => {
        // Create a Blob from the image data
        const blob = new Blob([response.data], { type: "image/png" });
        // Create an object URL for the Blob
        const objectURL = URL.createObjectURL(blob);
        // Set state
        setOverboxCodeSrc(objectURL);
      })
      .catch(error => {
        console.log('error')
        console.log(error);
      })
  }

  const handleAramexCourierNext = (): void => {
    setAusPostDispatchType(DeliveryModelCode.Pickup);
    next(2);
  }

  const getAramexSteps = (steps: Array<ProcessOrderStep>): Array<string> => {
    const mutatedSteps = JSON.parse(JSON.stringify(steps));
    const index = mutatedSteps.indexOf(ProcessOrderStep.Courier);
    if (index !== -1) {
      mutatedSteps.splice(index, 1);
    }
    return mutatedSteps;
  }

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages);
    // if (shouldPrint) {
    //   setTimeout(() => {
    //     printShippingLabel();
    //     setShouldPrint(false);
    //   }, 500);
    // }
  }

  const printShippingLabel = useReactToPrint({
    content: () => printShippingLabelRef.current,
    bodyClass: "printable_visible",
  });

  const addItemToOrder = (item: any): void => {
    const newOrderItems = JSON.parse(JSON.stringify(createOrderItems));
    const existingItem = newOrderItems.find((i: any) => i.sku === item.sku);
    if (existingItem) {
      existingItem.qty_ordered += 1;
    } else {
      newOrderItems.push(item);
    }
    setCreateOrderItems(newOrderItems);
  }

  const handleSearch = (query: string): void => {
    setSearchQuery(query);
    // if (query) setShowSearchResults(true);
    setShowSearchResults(true);
  }

  const handlePayment = (paymentType: string): void => {
    setPaymentType(paymentType);
    setShowPaymentModal(false);
    handleCompleteProcess();

    // setTimeout(() => {
    //   setShowConfirmationModal(true);
    // }, 300);
  }

  const handleSetPartialRefundItemIds = (itemSkus: Array<any>): void => {
    setPartialRefundItemIds(itemSkus.filter(sku => order.items.find(item => item.sku === sku)?.is_prescription === false));
  }

  const savePartialRefund = (): void => {
    setShowPartialRefundModal(false);
    const newPartialRefundItems = JSON.parse(JSON.stringify(tempPartialRefundItems));
    setPartialRefundItems(newPartialRefundItems);
  }

  const cancelPartialRefund = (): void => {
    setShowPartialRefundModal(false);
    const newTempPartialRefundItems = JSON.parse(JSON.stringify(partialRefundItems));
    setTempPartialRefundItems(newTempPartialRefundItems);
  }

  const handleTempPartialRefundChange = (index: number, value: number): void => {
    const newRefundQuantities = JSON.parse(JSON.stringify(tempPartialRefundItems));
    newRefundQuantities[index].qtyToRefund = value;
    newRefundQuantities.forEach((item: any) => {
      if (item.is_prescription) item.qtyToRefund = 0;
    });
    setTempPartialRefundItems(newRefundQuantities);
  }

  const handlePartialRefundChange = (index: number, value: number): void => {
    const newRefundQuantities = JSON.parse(JSON.stringify(partialRefundItems));
    newRefundQuantities[index].qtyToRefund = value;
    setPartialRefundItems(newRefundQuantities);
  }

  const setParcelType = (index: number, value: string): void => {
    const newParcelTypes = parcelTypes.slice();
    newParcelTypes[index] = value;
    setParcelTypes(newParcelTypes);
  }

  const setParcelWeight = (index: number, event: ChangeEvent): void => {
    const newParcelWeights = parcelWeights.slice();
    newParcelWeights[index] = parseFloat((event.target as HTMLInputElement).value);
    setParcelWeights(newParcelWeights);
  }

  const setCustomParcelDimension = (index: number, values: Array<number | undefined>): void => {
    const newParcelDimensions = customParcelDimensions.slice();
    newParcelDimensions[index] = values;
    setCustomParcelDimensions(newParcelDimensions);
  }

  const handlePrintPickList = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'printable_visible'
  })

  const handlePrintFunctionality = useReactToPrint({
    content: () => printRef.current,
    bodyClass: 'printable_visible',
  });

  const handlePrintCollectionLabel = (): void => {
    handlePrintFunctionality()
    setIsDisabled(false);
  };

  const handleEmailShippingLabel = (): void => {
    console.log('TODO: handle email shipping label');
  }

  const handleDeclareNext = (): void => {
    let modalRequired = false;

    // reset declarations
    setPerfumeConfirmation(false);
    setAerosolConfirmation(false);
    setFlammableConfirmation(false);

    // categories requiring follow-up modal
    if (declaredCategories.includes('perfume')) {
      setShowPerfumeModal(true);
      modalRequired = true;
    }
    if (declaredCategories.includes('otherFlammable')) {
      setShowFlammableModal(true);
      modalRequired = true;
    }
    if (declaredCategories.includes('aerosol')) {
      setShowAerosolModal(true);
      modalRequired = true;
    }

    // categories requiring note on shipping label
    if (declaredCategories.includes("loosePowder")) {
      // handleShippingLabelNote('loosePowder');
    }
    if (declaredCategories.includes("prescriptionMedication")) {
      // handleShippingLabelNote('prescriptionMedication');
    }

    // proceed if no modal required
    if (!modalRequired) {
      if (next) next();
    }
  }

  const handleDeclarationConfirmation = (type: 'perfume' | 'aerosol' | 'otherFlammable', value: boolean): void => {
    let showModals = [showPerfumeModal, showAerosolModal, showFlammableModal];

    switch (type) {
      case 'perfume':
        setPerfumeConfirmation(value);
        setShowPerfumeModal(false);
        showModals[0] = false;
        if (value === true && parcelTypes.length === 1) {
          handleAddParcel();
        }
        break;
      case 'aerosol':
        setAerosolConfirmation(value);
        setShowAerosolModal(false);
        showModals[1] = false;
        if (value === true && parcelTypes.length === 1) {
          handleAddParcel();
        }
        break;
      case 'otherFlammable':
        setFlammableConfirmation(value);
        setShowFlammableModal(false);
        showModals[2] = false;
        if (value === true && parcelTypes.length === 1) {
          handleAddParcel();
        }
        break;
    }

    // proceed if all modals cleared
    if (showModals.every(val => val === false)) {
      setTimeout(() => {
        if (next) next();
      }, 300);
    }
  }

  const handleAddParcel = (): void => {
    // add to parcel types
    const newParcelTypes = parcelTypes.slice();
    newParcelTypes.push('');
    setParcelTypes(newParcelTypes);

    // add to parcel weights
    const newParcelWeights = parcelWeights.slice();
    newParcelWeights.push(undefined);
    setParcelWeights(newParcelWeights);

    // add to parcel dimensions
    const newParcelDimensions = customParcelDimensions.slice();
    newParcelDimensions.push([undefined, undefined, undefined]);
    setCustomParcelDimensions(newParcelDimensions);
  }

  const handleRemoveParcel = (index: number): void => {
    // remove from parcel types
    const newParcelTypes = parcelTypes.slice();
    newParcelTypes.splice(index, 1);
    setParcelTypes(newParcelTypes);

    // remove from parcel weights
    const newParcelWeights = parcelWeights.slice();
    newParcelWeights.splice(index, 1);
    setParcelWeights(newParcelWeights);

    // remove from parcel dimensions
    const newParcelDimensions = customParcelDimensions.slice();
    newParcelDimensions.splice(index, 1);
    setCustomParcelDimensions(newParcelDimensions);
  }

  //  const handleShippingLabelNote = (declaredCategory: string) => {
  //   console.log(`todo: add note to shipping label -- order contains ${declaredCategory}`);
  //   }

  const handleSetOrderStatus = async (orderStatus: OrderStatus): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      setErrorMessage('');
      setShowCustomErrorMessage(false);
      if (dummyData.state.useDummyData) {
        dummyData.mutations.setOrderStatus(order.id, orderStatus);
        resolve('');
      } else {
        const requestBody: IRequestBodyUpdateOrder = {
          status_code: orderStatus,
          completed_at: orderStatus === OrderStatus.Complete ? getCurrentTimestamp() : undefined
        }

        apiHelper.updateOrder(order.id, requestBody).then((response) => {
          // console.log(response);
          if (response.status === 200) {
            resolve(response.body.data);
          } else {
            setErrorMessage(`Error updating order: ${response.body.message}`);
            reject(response.body.message);
          }
        });
      }
    });
  }

  const handleProcessPickupOrderCreation = async (): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      setErrorMessage('');
      setShowCustomErrorMessage(false);
      if (dummyData.state.useDummyData) {
        dummyData.mutations.setOrderStatus(order.id, OrderStatus.AwaitingPickup);
        resolve('');
      } else {
        const requestBody: IRequestBodyProcessPickupOrderCreation = {
          user_id: user.id
        }
        setIsLoading(true);
        apiHelper.processPickupOrderCreation(order.id, requestBody).then((response) => {
          setIsLoading(false);
          // console.log(response);
          if (response.status === 200) {
            resolve(response.body.data);
          } else {
            setErrorMessage(`Error processing order: ${response.body.message}`);
            reject(response.body.message);
          }
        });
      }
    });
  }

  const handleProcessPickupOrderCompletion = async (): Promise<any> => {
    setErrorMessage('');
    setShowCustomErrorMessage(false);
    return new Promise<any>((resolve, reject) => {
      if (dummyData.state.useDummyData) {
        dummyData.mutations.setOrderStatus(order.id, OrderStatus.Complete);
        resolve('');
      } else {
        const requestBody: IRequestBodyProcessPickupOrderCompletion = {
          ...(additionalNotes.trim() !== '' ? { order_note: additionalNotes } : undefined),
          user_id: user.id
        }
        setIsLoading(true);
        apiHelper.processPickupOrderCompletion(order.id, requestBody).then((response) => {
          setIsLoading(false);
          // console.log(response);
          if (response.status === 200) {
            resolve(response.body.data);
          } else {
            setErrorMessage(`Error processing customer collection: ${response.body.message}`);
            reject(response.body.message);
          }
        });
      }
    });
  };

  const handleProcessDeliveryCreation = async (): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      setErrorMessage('');
      setShowCustomErrorMessage(false);
      if (dummyData.state.useDummyData) {
        dummyData.mutations.setOrderStatus(order.id, OrderStatus.AwaitingCourier);
        resolve("");
      } else {
        // ------- HANDLED COLLECTION WINDOW TIME --------- //
        const collectionStart = customCollectionTime.slice(0, 3);
        const requiredStartTime = collectionStart.replace(" ", ":00 ").toUpperCase();
        const collectionEnd = customCollectionTime.substr(8, 3);
        const requiredEndTime = collectionEnd.replace(" ", ":00 ").toUpperCase();
        const date = new Date().toString();
        const requiredDate = date.slice(0, 16);
        // Time Format Conversion
        const convertTime = (timeStr: any) => {
          const [time, modifier] = timeStr.split(' ');
          let [hours, minutes] = time.split(':');
          if (hours === '12') {
            hours = '00';
          }
          if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
          }
          return `${hours}:${minutes}`;
        };
        const time24Start = convertTime(requiredStartTime).toString();
        const time24End = convertTime(requiredEndTime).toString();
        const concatedStartTime = collectionTime === "custom-courier" ? (requiredDate.concat(time24Start, " UTC")) : "";
        const concatedEndTime = collectionTime === "custom-courier" ? (requiredDate.concat(time24End, " UTC")) : "";
        const isoWindowStartTime = collectionTime === "custom-courier" ? (new Date(concatedStartTime)?.toISOString()) : "";
        const isoWindowEndTime = collectionTime === "custom-courier" ? (new Date(concatedEndTime)?.toISOString()) : "";
        const collectionWindows = collectionTime === CollectionTime.Custom ? { collection_window_start: isoWindowStartTime, collection_window_end: isoWindowEndTime } : null;

        const items = parcelTypes.map((type, i) => {

          let parcel = null;
          let parcelCode = null;
          let parcelLength = null;
          let parcelWidth = null;
          let parcelHeight = null;

          if ([OrderDeliveryType.Standard, OrderDeliveryType.Express].includes(order.delivery_type_code)) {
            parcel = parcelOptions.find((parcel) => parcel.value === type);
            parcelCode = type;
            parcelLength = parcel!.id === ParcelType.Custom ? customParcelDimensions[i][0] : parcel!.length;
            parcelWidth = parcel!.id === ParcelType.Custom ? customParcelDimensions[i][1] : parcel!.width;
            parcelHeight = parcel!.id === ParcelType.Custom ? customParcelDimensions[i][2] : parcel!.height;
          }

          const item = {
            order_items: order.items.map((item) => {
              return {
                id: item.id,
                qty_shipped: (item.qty_ordered || 0) - (item.qty_shipped || 0) - (item.qty_refunded || 0), // from the frontend it should be cherry picked
              };
            }),
            courier_dangerous:
              aerosolConfirmation ||
              perfumeConfirmation ||
              flammableConfirmation || false,
            delivery_parcel_code: parcelCode,
            length: parcelLength,
            width: parcelWidth,
            height: parcelHeight,
            weight: parcelWeights[i] || 0,
          };
          return item;
        });

        let deliveryModelCode = undefined;
        switch (order.delivery_type_code) {
          case OrderDeliveryType.Standard:
          case OrderDeliveryType.Express:
          case OrderDeliveryType.Free:
            deliveryModelCode = ausPostDispatchType;
            break;
          case OrderDeliveryType.SameDay:
            deliveryModelCode = DeliveryModelCode.Pickup;
            break;
          case OrderDeliveryType.Custom:
            deliveryModelCode = DeliveryModelCode.Self;
            break;
        }

        const requestBody: IRequestBodyProcessDeliveryCreation = {
          user_id: user.id,
          delivery_model_code: deliveryModelCode as DeliveryModelCode,
          items,
          collection_window: order.delivery_type_code === OrderDeliveryType.SameDay ? collectionWindows : null,
        };

        setIsLoading(true);
        apiHelper
          .processDeliveryCreation(order.id, requestBody)
          .then((response) => {
            setIsLoading(false);
            if (response.status === 200) {
              resolve(response.body.data);
              const data = typeof response.body.data === "string"
                ? JSON.parse(response.body.data)
                : response.body.data;
              setProcessDeliveryCreationData(data);

              if (orderProcess === Process.Delivery) {
                apiHelper.getDeliveryLabels(data?.id).then((response) => {
                  if (response.status === 200) {
                    const shippingLabelUrl = URL.createObjectURL(response.body);
                    window.open(shippingLabelUrl, '_blank')
                    // setShippingLabel(shippingLabelUrl);
                    // setShouldPrint(true);
                    resolve(shippingLabelUrl);
                  } else {
                    setErrorMessage(`Error retrieving shipping label.`);
                    reject('Error retrieving shipping label.');
                  }
                });
              };
            } else {
              setErrorMessage(`Error processing order: ${response.body.message}`);
              setShowCustomErrorMessage(true);
              reject(response.body.message);
            }
          });
      }
    });
  };

  const handleCreateRefund = async (process: Process): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      setErrorMessage('');
      setShowCustomErrorMessage(false);
      if (dummyData.state.useDummyData) {
        dummyData.mutations.setOrderStatus(order.id, OrderStatus.Refunded);
        resolve('');
      } else {
        // init default fields
        const requestBody: IRequestBodyProcessOrderRefund = {
          refund_reason: refundReason as RefundReason,
          notify_customer: true,
          refund_delivery: 0.0,
          refund_adjustment: 0.0,
        }
        // notes
        let note = additionalNotes || "";
        if (refundReason === RefundReason.Other) requestBody.order_note += ` Order refunded for the following reason: ${customRefundReason}`;
        requestBody.order_note = note !== "" ? note : undefined;

        // process-specific fields
        switch (process) {
          case Process.CompleteRefund:
            requestBody.refund_delivery = calculateDeliveryRefund(order);
            requestBody.refund_items = order.items.map(item => {
              return {
                order_item_id: item.id,
                refund_qty: (item.qty_ordered || 0) - (item.qty_refunded || 0)
              }
            }) as RefundItems;
            break;
          case Process.PartialRefund:
            requestBody.refund_items = partialRefundItems.map(item => {
              return {
                order_item_id: item.id,
                refund_qty: item.qtyToRefund
              }
            }) as RefundItems;
            break;
          case Process.DeliveryRefund:
            requestBody.refund_delivery = calculateDeliveryRefund(order);
            break;
          case Process.CustomRefund:
            requestBody.refund_adjustment = refundAmount!;
            break;
        }

        // console.log('refunding order:', order);
        // console.log('refund request payload:', requestBody);

        setIsLoading(true);
        apiHelper.processOrderRefund(order.id, requestBody)
          .then((response) => {
            setIsLoading(false);
            if (response.status === 200) {
              resolve(response.body.data);
            } else {
              setErrorMessage(`Error creating refund: ${response.body.message}`);
              // reject(response.body.message);
            }
          })
          .catch((error) => {
            setErrorMessage(`Error creating refund: ${error.message}`);
            // reject(error.message);
          })
      }
    });
  }

  const calculateDeliveryRefund = (order: IOrder): number => {
    return parseFloat(order.delivery) - parseFloat(order.delivery_discount) - (order.refund_delivery ? parseFloat(order.refund_delivery) : 0);
  }

  const calculateMaxRefund = (order: IOrder): number => {
    const preItemTotal = parseFloat(order.total)
      - (order.refund_delivery ? parseFloat(order.refund_delivery) : 0)
      - (order.refund_adjustment ? parseFloat(order.refund_adjustment) : 0)

    const refundedItemTotal = (order.items as Array<any>).reduce((acc, item) => {
      return acc + ((item.total || 0) * (item.qty_refunded || 0));
    }, 0);

    return preItemTotal - refundedItemTotal;
  }

  const sendNotificationLegacy = async (): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      const greeting = order.delivery_firstname !== '' ? `Hi ${order.delivery_firstname},\n\n` : '';
      const notificationText = `${greeting}Your order is on it’s way. You will receive a tracking link shortly.\n\nRegards, Smith’s Pharmacy`;

      const requestBody: IRequestBodySendNotification = {
        message: notificationText,
        contact: order.delivery_phone,
        notification_type_code: NotificationType.Sms,
        user_id: user!.id,
        partner_id: user!.partner_id
      }
      apiHelper.sendNotification(requestBody).then((response: IResponseSendNotification) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          console.warn(response)
          resolve(response);
        }
      });
    })
  }

  const handleCompleteProcess = (): void => {
    const successDuration = 4000;
    const transitionDuration = 300;

    if (orderProcess === Process.Delivery || orderProcess === Process.CustomDelivery || orderProcess === Process.SameDayDelivery) {
      // if (process === Process.Delivery || process === Process.SameDayDelivery) {
      if (!partialRefundIncludesAnyItems()) {
        // NO REFUND
        if (dummyData.state.useDummyData) {
          const status = orderProcess === Process.Delivery ? OrderStatus.AwaitingProcessing : OrderStatus.AwaitingCourier;
          handleSetOrderStatus(status).then((result) => {
            if (order.delivery_type_code === OrderDeliveryType.Express) {
              window.open(dummyShippingLabel, '_blank')
            }

            if (orderProcess === Process.Delivery || orderProcess === Process.SameDayDelivery) {
              sendNotificationLegacy();
            }

            setShowConfirmationModal(true);
            setShowSuccessMessage(true);
            setTimeout(() => {
              setShowConfirmationModal(false);
            }, successDuration);
            setTimeout(() => { navigate('/send/orders') }, successDuration + transitionDuration);
            // if(process === Process.CustomDelivery || process === Process.SameDayDelivery) {
            //   handlePrintCollectionLabel()
            //   setIsDisabled(false);
            // }
          })
        } else {
          handleProcessDeliveryCreation().then((result) => {
            setShowConfirmationModal(true);
            setShowSuccessMessage(true);
            setTimeout(() => {
              setShowConfirmationModal(false);
            }, successDuration);
            setTimeout(() => { navigate('/send/orders') }, successDuration + transitionDuration);
            // if(process === Process.CustomDelivery || process === Process.SameDayDelivery) {
            //   handlePrintCollectionLabel()
            //   setIsDisabled(false);
            // }
          })
        }
        // handleSetOrderStatus(status).then((result) => {
        //   setShowConfirmationModal(true);
        //   setShowSuccessMessage(true);
        //   setTimeout(() => {
        //     setShowConfirmationModal(false);
        //   }, successDuration);
        //   setTimeout(() => {
        //     navigate("/send/orders");
        //   }, successDuration + transitionDuration);
        // });
      } else if (!partialRefundIncludesAllItems()) {
        // PARTIAL REFUND
        // NOTE: this code block was originally written assuming it would catch IN-SITU partial refunds.
        // That is, processing orders where a refund has NOT been processed yet in the backend.
        // In-Situ partial refund sare currently unsupported/disabled, and this code block instead handles
        // the processing of orders where a separate partial refund has already been processed.

        handleProcessDeliveryCreation().then((result) => {
          setShowConfirmationModal(true);
          setShowSuccessMessage(true);
          setTimeout(() => {
            setShowConfirmationModal(false);
          }, successDuration);
          setTimeout(() => { navigate('/send/orders') }, successDuration + transitionDuration);
        })
      } else {
        // COMPLETE REFUND
        console.log('todo: process complete refund');
        setShowSuccessMessage(true);
        setTimeout(() => { setShowConfirmationModal(false) }, successDuration);
        setTimeout(() => { navigate('/send/orders') }, successDuration + transitionDuration);
      }
      // } else if (process === Process.CustomDelivery) {
      //   handleSetOrderStatus(OrderStatus.AwaitingCourier).then((result) => {
      //     setShowConfirmationModal(true);
      //     setShowSuccessMessage(true);
      //     setTimeout(() => {
      //       setShowConfirmationModal(false);
      //     }, successDuration);
      //     setTimeout(() => { navigate('/send/orders') }, successDuration + transitionDuration);
      //   })
    } else if (orderProcess === Process.ClickAndCollect) {
      handleProcessPickupOrderCreation().then((result) => {
        if (order.delivery_type_code === OrderDeliveryType.ExternalPickup) {
          handleSendPickupNotification();
        }
        setShowSuccessMessage(true);
        setShowConfirmationModal(true)
        setTimeout(() => { setShowConfirmationModal(false) }, successDuration);
        setTimeout(() => { navigate('/send/orders') }, successDuration + transitionDuration);
        // handlePrintCollectionLabel();
        // setIsDisabled(false);
      })
    } else if (orderProcess === Process.CustomerCollection) {
      handleProcessPickupOrderCompletion().then((result) => {
        setShowSuccessMessage(true);
        setShowConfirmationModal(true)
        setTimeout(() => { setShowConfirmationModal(false) }, successDuration);
        setTimeout(() => { navigate('/send/orders/awaiting-pickup') }, successDuration + transitionDuration);
        // handlePrintCollectionLabel();
        // setIsDisabled(false);
      })
    } else if (
      orderProcess === Process.PartialRefund ||
      orderProcess === Process.CompleteRefund ||
      orderProcess === Process.CustomRefund ||
      orderProcess === Process.DeliveryRefund
    ) {
      handleCreateRefund(orderProcess)
        .then((result: any) => {
          console.log('refund result:');
          console.log(result);
          setShowSuccessMessage(true);
          setShowConfirmationModal(true)
          setTimeout(() => { setShowConfirmationModal(false) }, successDuration);
          setTimeout(() => { navigate('/send/orders') }, successDuration + transitionDuration);
        })
    } else if (orderProcess === Process.CreateOrder) {
      setShowConfirmationModal(true);
      setShowSuccessMessage(true);
      setTimeout(() => { setShowConfirmationModal(false) }, successDuration);
      setTimeout(() => { navigate('/send/orders') }, successDuration + transitionDuration);
    }
  }

  const handleEjectReservation = (): void => {
    console.log('handleEjectReservation')
    setLochtingDemoErrorState(false);
    setOverboxCodeSrc('');
    if (order.initial_reservation_id !== undefined) {
      setIsLoading(true);
      api.dispenseRobot(order.initial_reservation_id)
        .then(response => {
          console.log('success response:')
          console.log(response);

          const responseFinalReservationId = JSON.parse((response as any).data.data);
          console.log("Overbox ID: " + responseFinalReservationId);

          setFinalReservationId(responseFinalReservationId)
          handleEjectReservationSuccess(responseFinalReservationId);
        })
        .catch(error => {
          console.log(error);
          handleEjectReservationError();
        })
    } else {
      handleEjectReservationError();
    }
  }

  const handleEjectReservationSuccess = (responseFinalReservationId: string): void => {
    console.log('handleEjectReservationSuccess', responseFinalReservationId)
    if (lochtingDemoErrorState || responseFinalReservationId === 'demo') {
      console.log('use placeholder overbox code')
      setOverboxCodeSrc(placeholderOverboxCode);
    } else {
      console.log('get real overbox code')
      retrieveOverboxCodeSrc(responseFinalReservationId);
    }

    setIsLoading(false);
    setShowEjectMessage(true);
    setShowSuccessMessage(true);
    setShowConfirmationModal(true);
    const successDuration = 4000;
    setTimeout(() => {
      setShowEjectMessage(false);
      setShowSuccessMessage(false);
      setShowConfirmationModal(false)
    }, successDuration);
  }

  const handleEjectReservationError = (): void => {
    console.log('handleEjectReservationError')
    // setIsLoading(false);
    // setErrorMessage('There was an error ejecting the order items from the robot.');

    // temp fake success for APP demo
    console.log('error ejecting reservation, showing demo state')
    setLochtingDemoErrorState(true);
    setTimeout(() => {
      handleEjectReservationSuccess('demo');
    }, 0);
  }

  const handleSendCartEmail = (): void => {
    // prepare notification data
    let message = cartEmailTemplate;
    message = message.replaceAll('{pharmacyName} {pharmacyLocation}', partnerName());
    message = message.replaceAll('{pharmacyName}', partnerName());
    message = message.replaceAll('{cartLink}', formatCartLink(generatedCart!.reference));

    const contact = {
      first_name: 'placeholder_first',
      last_name: 'placeholder_second',
      phone: '0491 570 006',
      email: deliveryEmail
    };
    const email_type_code = EmailTypes.PendingCart;
    const subject = `Your order from ${partnerName()} is awaiting completion`;

    handleSendNotification(contact, email_type_code, subject, message);
  }

  const handleSendPickupNotification = (): void => {
    // prepare notification data
    let qrcodeEndpoint = '';
    if (!lochtingDemoErrorState) {
      const basePath = `${process.env.REACT_APP_BACKEND_API_PROTOCOL}://${process.env.REACT_APP_BACKEND_API_HOST}/${process.env.REACT_APP_BACKEND_API_PREFIX}`;
      qrcodeEndpoint = `${basePath}/robots/qr-code/${finalReservationId}.png`;
    } else {
      qrcodeEndpoint = 'https://cdn.sparrowhub.com.au/demo/placeholder-data-matrix.png';
    }

    let message = pickupEmailTemplate;
    message = message.replaceAll('{orderNumber}', order.platform_order_no);
    message = message.replaceAll('{firstname}', order.delivery_firstname);
    message = message.replaceAll('{pharmacyName} {pharmacyLocation}', partnerName());
    message = message.replaceAll('{pharmacyName}', partnerName());
    message = message.replaceAll('{qrCodeSrc}', qrcodeEndpoint);

    const contact = {
      first_name: order.delivery_firstname,
      last_name: order.delivery_lastname,
      email: order.delivery_email
    };
    const email_type_code = 'pickup_matic';
    const subject = `Your order from ${partnerName()} is ready for pickup`;

    handleSendNotification(contact, email_type_code, subject, message);
  }
  
  const handleSendNotification = async (contact: ProcessNotificationNewRequestContact, email_type_code: EmailTypes, subject: string, message: string): Promise<any> => {
    await grecaptcha.enterprise.ready(async () => {
      const recaptcha = await grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_KEY, { action: 'contact' });
      sendEmailNotification(recaptcha, contact, email_type_code, subject, message);
    });
  }

  const sendEmailNotification = (securityToken: string, contact: ProcessNotificationNewRequestContact, email_type_code: EmailTypes, subject: string, message: string): void => {
    const requestBody: ProcessNotificationNewRequest = {
      security_token: securityToken,
      notification_type_code: NotificationTypes.Email,
      partner: {
        id: user!.partner_id
      },
      contact,
      email_type_code,
      subject,
      message
    }

    console.log('sendEmailNotification request body');
    console.log(requestBody);

    api.publicProcessNewNotification(requestBody).then(async (response) => {
      const data = JSON.parse((response.data as any).data);
      console.log(data);

      let mutatedNotificationList = JSON.parse(JSON.stringify(cartNotificationsSent));
      mutatedNotificationList.push('email');
      setCartNotificationsSent(mutatedNotificationList);
    })
      .catch(error => {
        console.log('error response')
        console.log(error);
      })
  }

  // set declared categories
  useEffect(() => {
    const containsPrescriptionItems = order.items.some((item: IOrderItem) => item.is_prescription);
    if (containsPrescriptionItems) {
      setDeclaredCategories(['prescriptionMedication']);
    }
  }, [order])
  
  // hide delivery options if valid fields change
  useEffect(() => {
    handleHideDeliveryOptions();
  }, [deliveryStreet1, deliveryStreet2, deliverySuburb, deliveryPostcode, deliveryState])

  // use useEffect hook to update the Orders state variables when data is returned from the API
  useEffect(() => {
    (async () => {
      // check current user token is valid before proceeding
      const currentUserResponse = await apiHelper.getCurrentUser();

      if (currentUserResponse.status === 200) {
        // request Orders data from API
        const response = await apiHelper.getDeliveryParcels();
        const data =
          typeof response.body.data === "string"
            ? JSON.parse(response.body.data)
            : response.body.data;

        if (data) {
          let parcelData = data
            .filter((parcel: any) => parcel.is_visible)
            .map((parcel: any): SelectionGroupOption => {
              const parcelWidth = parcel.code === ParcelType.Custom ? undefined : parseInt(parcel.width);
              const parcelHeight = parcel.code === ParcelType.Custom ? undefined : parseInt(parcel.height);
              const parcelLength = parcel.code === ParcelType.Custom ? undefined : parseInt(parcel.length);

              return {
                id: parcel.code,
                value: parcel.code,
                label: parcel.name,
                width: parcelWidth,
                height: parcelHeight,
                length: parcelLength,
                subtitle: parcel.code === ParcelType.Custom ? undefined : `(${parcelLength} cm x ${parcelWidth} cm x ${parcelHeight} cm)`,
                imageSrc: generateImage(parcel.image)
              }
            })

          // always show custom option last
          const customIndex = parcelData.findIndex((parcel: any) => parcel.id === ParcelType.Custom);
          if (customIndex !== -1) {
            const customParcel = parcelData.splice(customIndex, 1);
            parcelData = [...parcelData, ...customParcel];
          }
          setParcelOptions(parcelData);
        }
      } else {
        if (!dummyData.state.useDummyData) {
          apiHelper.logoutUser().then((response) => {
            onLogout();
            navigate('/logout/auto');
          });
        }
      }
    })();
  }, [apiHelper]);

  function generateImage(imageData: Uint8Array) {
    // Create a Blob from the image data
    const blob = new Blob([imageData], { type: "image/png" });
    // Create an object URL for the Blob
    const objectURL = URL.createObjectURL(blob);
    return objectURL
  }

  return (
    <StyledProcessOrderModal deliveryType={order.delivery_type_code}>
      {/* <button className="printable_dev" onClick={() => {setShowSuccessMessage(!showSuccessMessage)}}>Dev toggle success message</button> */}
      {currentStep() !== ProcessOrderStep.CreateRefund &&
        <div className="ProcessOrderModal_processSteps divider">
          <ProcessSteps steps={order.courier_type_code === CourierType.Aramex ? getAramexSteps(steps) : steps} current={currentStepIndex} />
        </div>
      }
      {orderProcess !== Process.CreateOrder &&
        <div className="ProcessOrderModal_orderDetails divider">
          <OrderBadges order={order} />
          <OrderDetails
            order={order}
            partnerLocations={partnerLocations}
            address={order.delivery_type_code !== OrderDeliveryType.Pickup}
            billingAddress={order.delivery_type_code === OrderDeliveryType.Pickup && orderProcess === Process.CustomerCollection}
            location={order.delivery_type_code === OrderDeliveryType.Pickup && orderProcess !== Process.CustomerCollection}
          />
        </div>
      }
      <div className="ProcessOrderModal_stepContents">
        {/* confirm ID (customer collection) */}
        {currentStep() === ProcessOrderStep.ConfirmID &&
          <>
            <div className="ProcessOrderModal_headingContainer">
              <Heading heading="How have you confirmed the customer's identity?" />
            </div>
            <div className="">
              <SelectionGroup inputType={SelectionGroupInputType.Radio} groupType={SelectionGroupType.Grid} options={confirmIdOptions} selected={confirmIdMethod} onChange={setConfirmIdMethod} />
              <div className={`customField_container ${confirmIdMethod === 'custom' ? '' : 'hidden'}`}>
                <InputField type="text" id="customConfirmId" label="Other" value={customConfirmIdMethod} onChange={(e: ChangeEvent) => setCustomConfirmIdMethod((e.target as HTMLInputElement).value)} />
              </div>
            </div>
            <Button text="Add Order Notes" type={ButtonType.Primary} icon={ButtonIcon.Arrow} disabled={customStringFieldIsInvalid(confirmIdMethod, customConfirmIdMethod)} onClick={next} />
            <Button text="Back" type={ButtonType.Secondary} onClick={() => navigate('/send/orders/awaiting-pickup')} />
          </>
        }

        {/* pick items */}
        {currentStep() === ProcessOrderStep.Pick &&
          <>
            {(order.delivery_type_code === OrderDeliveryType.ExternalPickup && errorMessage) &&
              <div style={{ marginTop: '-60px', marginBottom: '20px' }}>
                <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                  <p>{errorMessage}<br />Please try again or contact Rival Software <a href="mailto:support@sparrowhub.com.au?subject=SparrowHub%20Support%20Request" target="_blank" rel="noreferrer">here</a>.</p>
                </Alert>
              </div>
            }
            <div className="ProcessOrderModal_headingContainer">
              <Heading heading="Order details" />
              {order.delivery_type_code === OrderDeliveryType.ExternalPickup &&
                // TODO: conditionally show button based on order.initial_reservation_status
                <>
                  <Button text={ejectReservationLabel()} type={ButtonType.Primary} icon={ButtonIcon.Eject} small reverse onClick={handleEjectReservation} loading={isLoading} />
                  {lochtingDemoErrorState && <div className="ejectReservation_demoIndicator"></div>}
                </>
              }
            </div>
            <OrderItems order={order} orderItems={order.items} partialRefundItems={partialRefundItems} summaryTable noRefundAlert={!enableInSituPartialRefunds} />
            <div className="ProcessOrderModal_buttonContainer">
              {enableInSituPartialRefunds ?
                <>
                  {[UserRoleCode.Admin].includes(user!.role_code) &&
                    <Button text={partialRefundCreated() ? 'Edit Partial Refund' : 'Create Partial Refund'} type={ButtonType.Primary} small icon={ButtonIcon.Dollar} colour={ButtonColour.Blue} onClick={() => setShowPartialRefundModal(true)} />
                  }
                  <Button text={partialRefundCreated() ? 'Print Updated Pick List' : 'Print Pick List'} type={ButtonType.Primary} small icon={ButtonIcon.Print} colour={ButtonColour.Green} onClick={handlePrintPickList} />
                </>
                :
                <Button text="Print Pick List" type={ButtonType.Primary} small icon={ButtonIcon.Print} colour={ButtonColour.Green} onClick={handlePrintPickList} />
              }
            </div>
            <div className="printable_hidden" ref={printRef}>
              <PickList order={order} partialRefundItems={partialRefundItems} />
            </div>
            <Alert type={AlertType.Important}>
              <p>Have you collected all of the items above?<br />Have you printed out the pick list to include in the order?</p>
            </Alert>
            <Button text={[OrderDeliveryType.Pickup, OrderDeliveryType.Custom].includes(order.delivery_type_code) ? 'Package Order' : 'Proceed to Next Step'} type={ButtonType.Primary} icon={ButtonIcon.Arrow} onClick={() => { setErrorMessage(''); if (next) next(); }} />
            <Button text="Back" type={ButtonType.Secondary} onClick={() => navigate('/send/orders')} />
            <Modal show={showPartialRefundModal}>
              <Heading heading="Select which items and quantities you would like to refund:" />
              <div className="PartialRefundModal_items">
                {order.items.map((item: IOrderItem, i: number) => {
                  return (
                    <div className={`PartialRefundModal_item ${item.is_prescription && 'disabled'}`} key={`item-${i}-${item.sku}`}>
                      <div>
                        <p className="PartialRefundModal_itemSku semibold">SKU: {item.sku}</p>
                        <p className="PartialRefundModal_itemName semibold">{item.name}</p>
                        <p className="PartialRefundModal_itemQty semibold">Qty Purchased: {item.qty_ordered}</p>
                        {(item.qty_refunded || 0) > 0 && <p className="PartialRefundModal_itemQty semibold">Qty Refunded: {(item.qty_refunded || 0)}</p>}
                      </div>
                      <QuantitySelector label="Qty to Refund:" quantity={tempPartialRefundItems[i].qtyToRefund} min={0} max={(item.qty_ordered || 0) - (item.qty_refunded || 0)} disabled={item.is_prescription} onChange={(val: number) => handleTempPartialRefundChange(i, val)} />
                    </div>
                  )
                })}
              </div>
              <Button type={ButtonType.Primary} text="Save Changes" onClick={savePartialRefund} />
              <Button type={ButtonType.Secondary} text="Cancel" onClick={cancelPartialRefund} />
            </Modal>
          </>
        }

        {/* pack items (click & collect) */}
        {currentStep() === ProcessOrderStep.Pack &&
          <>
            {order.delivery_type_code !== OrderDeliveryType.ExternalPickup ?
              <>
                <div className="ProcessOrderModal_headingContainer">
                  <Heading heading={`How many ${order.delivery_type_code === OrderDeliveryType.Pickup ? 'bags' : 'packages'} do you need for this order?`} />
                </div>
                <div className="ProcessOrderModal_orderBags">
                  <SelectionGroup inputType={SelectionGroupInputType.Radio} groupType={SelectionGroupType.Grid} options={orderBagOptions} selected={orderBags} onChange={setOrderBags} largeLabels />
                  <div className={`customField_container ${orderBags === 'custom' ? '' : 'hidden'}`}>
                    <InputField type="number" regex={/^\d+$/} id="customOrderBags" label="Number of bags" min={0} step="1" value={customOrderBags} onChange={(e: ChangeEvent) => setCustomOrderBags(parseInt((e.target as HTMLInputElement).value))} />
                  </div>
                </div>
                <Button text={orderProcess === Process.SameDayDelivery ? 'Confirm Collection Time' : 'Confirm Order Details'} type={ButtonType.Primary} icon={ButtonIcon.Arrow} disabled={orderBagsIsInvalid()} onClick={next} />
                <Button text="Back" type={ButtonType.Secondary} onClick={back} />
              </>
              :
              <>
                <div className="ProcessOrderModal_headingContainer">
                  <Heading heading="Select a box size for this order:" />
                </div>
                <div className="ProcessOrderModal_orderBags">
                  <SelectionGroup inputType={SelectionGroupInputType.Radio} groupType={SelectionGroupType.Grid} options={lochtingBoxOptions} selected={orderBags} onChange={setOrderBags} largeLabels />
                </div>
                <Button text="Confirm Order Details" type={ButtonType.Primary} icon={ButtonIcon.Arrow} disabled={orderBagsIsInvalid()} onClick={next} />
                <Button text="Back" type={ButtonType.Secondary} onClick={back} />
              </>
            }
          </>
        }

        {/* declare dangerous goods */}
        {currentStep() === ProcessOrderStep.Declare &&
          <>
            <Heading heading="Does this order contain any of the following products?" subheading="Select all product types that apply." />
            <div>
              <SelectionGroup inputType={SelectionGroupInputType.Checkbox} groupType={SelectionGroupType.Grid} options={declareOptions} selected={declaredCategories} onChange={setDeclaredCategories} />
            </div>
            <Button text="Package Order" type={ButtonType.Primary} icon={ButtonIcon.Arrow} disabled={declaredCategories.length < 1} onClick={handleDeclareNext} />
            <Button text="Back" type={ButtonType.Secondary} onClick={back} />

            {/* follow-up modals */}
            <MultiModal>
              <Modal show={showPerfumeModal}>
                <Heading heading="Does the total amount of perfume in this order exceed 300mL?" />
                <div className="ProcessOrderModal_declarationConfirmationGrid">
                  <Button type={ButtonType.Primary} text="No, it does not exceed 300mL of perfume" onClick={() => handleDeclarationConfirmation('perfume', false)} />
                  <Button type={ButtonType.Primary} text="Yes, it does exceed 300mL of perfume" onClick={() => handleDeclarationConfirmation('perfume', true)} />
                </div>
              </Modal>
              <Modal show={showAerosolModal}>
                <Heading heading="Does the total volume of aerosol cans in this order exceed 50mL?" />
                <div className="ProcessOrderModal_declarationConfirmationGrid">
                  <Button type={ButtonType.Primary} text="No, it does not exceed 50mL of aerosol" onClick={() => handleDeclarationConfirmation('aerosol', false)} />
                  <Button type={ButtonType.Primary} text={`Yes, it does exceed\n50mL of aerosol`} onClick={() => handleDeclarationConfirmation('aerosol', true)} />
                </div>
              </Modal>
              <Modal show={showFlammableModal}>
                <Heading heading="Does the total amount of flammable goods in this order exceed 300mL?" />
                <div className="ProcessOrderModal_declarationConfirmationGrid">
                  <Button type={ButtonType.Primary} text="No, it does not exceed 300mL of flammable goods" onClick={() => handleDeclarationConfirmation('otherFlammable', false)} />
                  <Button type={ButtonType.Primary} text="Yes, it does exceed 300mL of flammable goods" onClick={() => handleDeclarationConfirmation('otherFlammable', true)} />
                </div>
              </Modal>
            </MultiModal>
          </>
        }

        {/* package order */}
        {currentStep() === ProcessOrderStep.Package &&
          <>
            <Heading heading="Select a box/satchel size for this order:" />
            {parcelTypes.map((selectedParcel: string, i: number) => {
              return (
                <SwitchTransition key={`selectiongroup-${i}`}>
                  <CSSTransition
                    key={selectedParcel === 'box-custom' ? 'box-custom' : 'standard'}
                    nodeRef={transitionRef}
                    timeout={200}
                    classNames="fade"
                  >
                    <div className={`ProcessOrderModal_parcelContainer ${i < parcelTypes.length - 1 ? 'divider parcel_divider' : ''}`} ref={transitionRef}>
                      {parcelTypes.length > 1 &&
                        <div className="ProcessOrderModal_parcelHeader">
                          <p className="bold">Parcel {i + 1}</p>
                          <img className={`button ${i < numRequiredParcels() ? 'disabled' : ''}`} src={trashIcon} alt="Remove parcel" onClick={() => handleRemoveParcel(i)} draggable="false" />
                        </div>
                      }
                      {(i < numRequiredParcels() && i !== 0) &&
                        <Alert type={AlertType.Important}>
                          <p style={{ whiteSpace: 'pre-wrap' }}>{requiredParcelMessage()}</p>
                        </Alert>
                      }
                      {selectedParcel !== customBox.CustomBox ?
                        <SelectionGroup inputType={SelectionGroupInputType.Radio} groupType={SelectionGroupType.Grid} options={parcelOptions} selected={selectedParcel} onChange={(val: string) => setParcelType(i, val)} />
                        :
                        <CustomParcelInput values={customParcelDimensions[i]} onChange={(values: Array<number | undefined>) => setCustomParcelDimension(i, values)} onClose={() => setParcelType(i, '')} />
                      }
                    </div>
                  </CSSTransition>
                </SwitchTransition>
              )
            })}
            <Button text="Add another parcel" label="Need to split this order over multiple parcels?" type={ButtonType.AddParcel} icon={ButtonIcon.Plus} reverse onClick={handleAddParcel} />
            <Button text="Continue to Parcel Weight" type={ButtonType.Primary} icon={ButtonIcon.Arrow} disabled={parcelSelectionIsInvalid()} onClick={next} />
            <Button text="Back" type={ButtonType.Secondary} onClick={back} />
          </>
        }

        {/* weigh parcel/s */}
        {currentStep() === ProcessOrderStep.Weigh &&
          <>
            <Heading heading="Enter the parcel weight:" />
            <div className="ProcessOrderModal_weightInput">
              {parcelWeights.map((parcelWeight: number | undefined, i: number) => {
                return (
                  <div className="ProcessOrderModal_inputContainer" key={`weight-input-parent-${i}`}>
                    <p className="bold">
                      Parcel {i + 1}:<br />
                      {parcelTypes[i] !== 'box-custom' ? parcelOptions.find(option => option.id === parcelTypes[i])!.label : 'Custom Box'}
                    </p>
                    <InputField type="number" regex={/^[\d.]+$/} id={`weight-input-${i}`} label="Weight" labelAfter="kg" min={0} max={22} step="any" value={parcelWeight} onChange={(e: ChangeEvent) => setParcelWeight(i, e)} />
                  </div>
                )
              })}
              <div className="ProcessOrderModal_videoContainer">
                <label className="bold-italic">Not sure what to weigh?</label>
                <video
                  controls
                  src="/assets/videos/parcel-weight.mp4"
                ></video>
              </div>
            </div>
            <Button text="Proceed to Courier Information" type={ButtonType.Primary} icon={ButtonIcon.Arrow} disabled={parcelWeights.some(weightIsInvalid)} onClick={order.courier_type_code === CourierType.Aramex ? handleAramexCourierNext : next} />
            <Button text="Back" type={ButtonType.Secondary} onClick={back} />
          </>
        }

        {/* additional notes (customer collection) */}
        {currentStep() === ProcessOrderStep.Notes &&
          <>
            <Heading heading="Do you have any additional notes for this order?" />
            <TextArea id="notes-input" value={additionalNotes} maxLength={512} onChange={(e: ChangeEvent) => setAdditionalNotes((e.target as HTMLTextAreaElement).value)} />
            {orderProcess === Process.CustomerCollection ?
              <>
                {errorMessage &&
                  <div style={{ marginTop: '-60px', marginBottom: '20px' }}>
                    <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                      <p>{errorMessage}<br />Please try again or contact Rival Software <a href="mailto:support@sparrowhub.com.au?subject=SparrowHub%20Support%20Request" target="_blank" rel="noreferrer">here</a>.</p>
                    </Alert>
                  </div>
                }
                <Button text="Mark Order as Collected" type={ButtonType.Primary} icon={ButtonIcon.Arrow} onClick={handleCompleteProcess} loading={isLoading} />
              </>
              :
              <Button text="Confirm Refund Details" type={ButtonType.Primary} icon={ButtonIcon.Arrow} onClick={next} />
            }
            <Button text="Back" type={ButtonType.Secondary} onClick={back} />
          </>
        }

        {/* courier details */}
        {currentStep() === ProcessOrderStep.CollectionTime &&
          <div className="ProcessOrderModal_collectionTime">
            <Heading heading="When would you like this order to be collected?" subheading="This order will be shipped by DoorDash." />
            <div>
              <SelectionGroup inputType={SelectionGroupInputType.Radio} groupType={SelectionGroupType.Grid} options={collectionTimeOptions} selected={collectionTime} onChange={setCollectionTime} />
              <div className={`customField_container ${collectionTime === CollectionTime.Custom ? '' : 'hidden'}`} style={{ margin: '-10px 0 40px 0' }}>
                <p style={{ marginTop: '0px' }}>Please select a delivery window for your order to be collected today:</p>
                <SelectInput id="collectionTime" options={customCollectionTimeOptions()} value={customCollectionTime} onChange={(e: ChangeEvent) => setCustomCollectionTime((e.target as HTMLInputElement).value)} />
              </div>
            </div>

            <Button text="Confirm Order Details" type={ButtonType.Primary} icon={ButtonIcon.Arrow} onClick={next} disabled={customStringFieldIsInvalid(collectionTime, customCollectionTime)} />
            <Button text="Back" type={ButtonType.Secondary} onClick={back} />
          </div>
        }

        {/* courier details */}
        {currentStep() === ProcessOrderStep.Courier &&
          <div className="ProcessOrderModal_courier">
            <Heading heading="How would you like to send out this order?" subheading="This order will be shipped by Australia Post." />
            <div className="ProcessOrderModal_dispatchType">
              <SelectionGroup inputType={SelectionGroupInputType.Radio} groupType={SelectionGroupType.Grid} options={ausPostDispatchTypeOptions} selected={ausPostDispatchType as string} onChange={setAusPostDispatchType} />
            </div>
            <Button text="Confirm Shipping Details" type={ButtonType.Primary} icon={ButtonIcon.Arrow} onClick={next} disabled={ausPostDispatchType === undefined || ausPostDispatchType === ''} />
            <Button text="Back" type={ButtonType.Secondary} onClick={back} />
          </div>
        }

        {/* confirm details */}
        {currentStep() === ProcessOrderStep.Confirm &&
          <div className="ProcessOrderModal_orderConfirmation">
            <Heading heading="Have you confirmed the below details are correct?" />
            {(orderProcess === Process.Delivery || orderProcess === Process.CustomDelivery || orderProcess === Process.SameDayDelivery || orderProcess === Process.ClickAndCollect) &&
              <>
                {partialRefundItems.some((item: any) => item.qtyToRefund === 0 || item.qtyToRefund !== item.qty_ordered) &&
                  <div className="summary">
                    <img className="shoppingBagIcon summaryIcon" src={shoppingBagIcon} alt="" title="" />
                    <p className="summaryHeading bold">Order Summary</p>
                    <OrderItems order={order} orderItems={order.items} partialRefundItems={partialRefundItems} summaryTable nonRefundsOnly />
                  </div>
                }
                {partialRefundItems.some((item: any) => item.qtyToRefund !== 0) &&
                  <div className="summary">
                    <img className="dollarIcon summaryIcon" src={dollarIcon} alt="" title="" />
                    <p className="summaryHeading bold">Refund Summary</p>
                    <OrderItems order={order} orderItems={order.items} partialRefundItems={partialRefundItems} summaryTable refundsOnly />
                  </div>
                }
                {orderRequiresContact(order) &&
                  <div style={{ margin: '-30px 0 40px 0' }}>
                    <Checkbox id="scheduleContact" selected={scheduleContact} onChange={() => setScheduleContact(val => !val)} required>
                      {requiresContactText()}
                    </Checkbox>
                  </div>
                }
              </>
            }
            {(orderProcess === Process.PartialRefund || orderProcess === Process.CompleteRefund) &&
              <div style={{ marginBottom: '35px' }}>
                <img className="dollarIcon summaryIcon" src={dollarIcon} alt="" title="" />
                <p className="summaryHeading bold">Refund Summary</p>
                <OrderItems order={order} orderItems={orderProcess === Process.PartialRefund ? filteredRefundItems() : order.items} summaryTable />
              </div>
            }
            {(orderProcess === Process.CustomRefund || orderProcess === Process.DeliveryRefund) &&
              <>
                <img className="dollarIcon summaryIcon" src={dollarIcon} alt="" title="" />
                <p className="summaryHeading bold">Refund Summary</p>
                <table className="ProcessOrderModal_refundSummary summaryTable" style={{ marginBottom: '60px !important' }}>
                  <thead>
                    <tr>
                      <th className="align_left">Reason</th>
                      <th className="align_right">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="align_left">{formattedRefundReason()}</td>
                      <td className="align_right">{formatPrice(orderProcess === Process.CustomRefund ? refundAmount! : calculateDeliveryRefund(order))}</td>
                    </tr>
                  </tbody>
                </table>
              </>
            }
            <div>
              {(
                (
                  ([Process.CustomRefund, Process.DeliveryRefund, Process.PartialRefund, Process.CompleteRefund, Process.CreateOrder].includes(orderProcess) === false) ||
                  (orderProcess === Process.CreateOrder && deliveryType === 'delivery')
                ) &&
                order.delivery_type_code !== OrderDeliveryType.ExternalPickup
              ) &&
                <>
                  <img className="summaryIcon" src={shippingIcon} alt="" title="" />
                  <p className="summaryHeading bold">Shipping Summary</p>
                </>
              }
              {/* Delivery order confirmation */}
              {(orderProcess === Process.Delivery) &&
                <>
                  <table className="ProcessOrderModal_shippingSummary summaryTable noRowColours">
                    <thead>
                      <tr>
                        <th className="align_left">Shipping Method</th>
                        <th className="align_left">Weight</th>
                        <th className="align_left">Dimensions</th>
                        {dummyData.state.useDummyData &&
                          <th className="align_right">Price</th>
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {parcelTypes.map((type: string, index: number) => {
                        const parcelType = parcelOptions.find(option => option.id === type)!;
                        return (
                          <tr key={`parcel-${index}`}>
                            <td className="align_left">Australia Post</td>
                            <td className="align_left">{parcelWeights[index]} kg</td>
                            <td className="align_left">{type !== ParcelType.Custom ? formatParcelDimensions([parcelType.length!, parcelType.width!, parcelType.height!]) : formatParcelDimensions(customParcelDimensions[index] as Array<number>)}</td>
                            {dummyData.state.useDummyData &&
                              <>
                                {index === 0 &&
                                  <td className="align_right" rowSpan={999}>
                                    <span>{order.delivery_type_code === OrderDeliveryType.Express ? '$10.00' : '$5.00'}</span>
                                  </td>
                                }
                              </>
                            }
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  {/* Shipping label */}
                  <div className="printable_hidden" ref={printShippingLabelRef}>
                    <Document file={shippingLabel} options={options} onLoadSuccess={onDocumentLoadSuccess}>
                      {Array.from(
                        new Array(numPages),
                        (el, index) => (
                          <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                          />
                        ),
                      )}
                    </Document>
                  </div>
                </>
              }
              {/* Custom Delivery order confirmation */}
              {orderProcess === Process.CustomDelivery &&
                <>
                  <table className="ProcessOrderModal_shippingSummary summaryTable noNegativeMargin">
                    <thead>
                      <tr>
                        <th className="align_left">Method</th>
                        <th className="align_left">Delivery Location</th>
                        <th className="align_right">Parcel(s)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="align_left">Custom Courier</td>
                        <td className="align_left">{order.delivery_city}</td>
                        <td className="align_right">{orderBags === 'custom' ? customOrderBags : orderBags}</td>
                      </tr>
                    </tbody>
                  </table>
                  <Button text="Print Package Label" type={ButtonType.Primary} colour={ButtonColour.Green} small icon={ButtonIcon.Print} onClick={handlePrintCollectionLabel} loading={isLoading} />
                  <div className="printable_hidden" ref={printRef}>
                    <CollectionLabel order={order} numItems={numItemsAfterRefund()} bags={orderBags === 'custom' ? customOrderBags : orderBags} partnerLocations={partnerLocations} showAddress />
                  </div>
                </>
              }
              {/* Same Day Delivery order confirmation */}
              {orderProcess === Process.SameDayDelivery &&
                <>
                  <table className="ProcessOrderModal_shippingSummary summaryTable noNegativeMargin">
                    <thead>
                      <tr>
                        <th className="align_left">Method</th>
                        <th className="align_left">Collection Time</th>
                        <th className="align_left">Parcel(s)</th>
                        {dummyData.state.useDummyData &&
                          <th className="align_right">Price</th>
                        }
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="align_left">DoorDash</td>
                        <td className="align_left">{collectionTime === 'custom' ? customCollectionTime : 'As soon as possible'}</td>
                        <td className="align_left">{orderBags === 'custom' ? customOrderBags : orderBags}</td>
                        {dummyData.state.useDummyData &&
                          <td className="align_right">$15.00</td>
                        }
                      </tr>
                    </tbody>
                  </table>
                  <Button text="Print Package Label" type={ButtonType.Primary} colour={ButtonColour.Green} small icon={ButtonIcon.Print} onClick={handlePrintCollectionLabel} loading={isLoading} />
                  <div className="printable_hidden" ref={printRef}>
                    <CollectionLabel order={order} numItems={numItemsAfterRefund()} bags={orderBags === 'custom' ? customOrderBags : orderBags} partnerLocations={partnerLocations} showAddress />
                  </div>
                </>
              }
              {/* Click & Collect order confirmation */}
              {orderProcess === Process.ClickAndCollect &&
                <>
                  {order.delivery_type_code !== OrderDeliveryType.ExternalPickup ?
                    <>
                      <table className="ProcessOrderModal_shippingSummary summaryTable noNegativeMargin">
                        <thead>
                          <tr>
                            <th className="align_left">Method</th>
                            <th className="align_left">Collection Point</th>
                            <th className="align_right">Parcel(s)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="align_left">Click & Collect</td>
                            <td className="align_left">{orderLocation.name}</td>
                            <td className="align_right">{orderBags === 'custom' ? customOrderBags : orderBags}</td>
                          </tr>
                        </tbody>
                      </table>
                      <Button text="Print Collection Label" type={ButtonType.Primary} colour={ButtonColour.Green} small icon={ButtonIcon.Print} onClick={handlePrintCollectionLabel} loading={isLoading} />
                      <div className="printable_hidden" ref={printRef}>
                        <CollectionLabel order={order} numItems={numItemsAfterRefund()} bags={orderBags === 'custom' ? customOrderBags : orderBags} partnerLocations={partnerLocations} />
                      </div>
                    </>
                    :
                    <>
                      <Button text={order.delivery_type_code === OrderDeliveryType.ExternalPickup ? 'Print Box Label' : 'Print Collection Label'} type={ButtonType.Primary} colour={ButtonColour.Green} small icon={ButtonIcon.Print} onClick={handlePrintCollectionLabel} loading={isLoading} />
                      <div className="printable_hidden" ref={printRef}>
                        {order.delivery_type_code === OrderDeliveryType.ExternalPickup ?
                          <LochtingBoxLabel order={order} numItems={numItemsAfterRefund()} partnerLocations={partnerLocations} overboxCodeSrc={overboxCodeSrc} />
                          :
                          <CollectionLabel order={order} numItems={numItemsAfterRefund()} bags={orderBags === 'custom' ? customOrderBags : orderBags} partnerLocations={partnerLocations} />
                        }
                      </div>
                    </>
                  }
                </>
              }
              {/* Partial/complete refund confirmation */}
              {(orderProcess === Process.CompleteRefund || orderProcess === Process.PartialRefund) &&
                <>
                  {/* TODO: re-enable when return shipping is supported */}
                  {/* <table className="ProcessOrderModal_shippingSummary summaryTable">
                    <thead>
                      <tr>
                        <th className="align_left">Shipping Method</th>
                        <th className="align_left">Postcode</th>
                        <th className="align_left">Parcel(s)</th>
                        <th className="align_left">Weight</th>
                      </tr>
                    </thead>
                    <tbody>
                      {parcelTypes.map((type: string, index: number) => {
                        return (
                          <tr key={`parcel-${index}`}>
                            <td className="align_left">Standard Delivery</td>
                            <td className="align_left">{orderLocation.address.postcode}</td>
                            <td className="align_left">1</td>
                            <td className="align_left">1.2kg</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table> */}
                  <div className="ProcessOrderModal_shippingLocations">
                    <Alert type={AlertType.Important}>
                      <p>If any return shipments are required for this refund, you must contact your relevant head office office representative.</p>
                    </Alert>
                  </div>
                  {/* TODO: re-enable when return shipping is supported */}
                  {/* <div className="ProcessOrderModal_shippingLocations">
                    <p className="bold">Shipping From</p>
                    <p>
                      {order.delivery_firstname} {order.delivery_lastname}<br />
                      {formatOrderAddress(order)}
                    </p>
                    <p className="bold">Shipping To</p>
                    <p>
                      {orderLocation.name}<br />
                      {formatLocationAddress(orderLocation)}
                    </p>
                  </div> */}
                  {/* {[UserRoleCode.Admin].includes(user!.role_code) &&
                    <Button text="Email Shipping Label" type={ButtonType.Primary} small icon={ButtonIcon.Mail} onClick={handleEmailShippingLabel} />
                  } */}
                </>
              }
              {orderProcess === Process.CreateOrder &&
                <>
                  <table className="ProcessOrderModal_shippingSummary summaryTable noNegativeMargin">
                    <thead>
                      <tr>
                        <th className="align_left" style={{ width: '160px' }}>Shipping Method</th>
                        <th className="align_left">Courier</th>
                        <th className="align_right" style={{ width: '100px' }}>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="align_left" style={{ verticalAlign: 'top' }}>{deliveryTypeLabel(deliveryType)}</td>
                        <td className="align_left" style={{ verticalAlign: 'top' }}>{deliveryLabel(deliveryType, deliveryProvider)}</td>
                        <td className="align_right" style={{ verticalAlign: 'top' }}>{deliveryPrice(deliveryType, deliveryProvider)}</td>
                      </tr>
                    </tbody>
                  </table>
                </>
              }
            </div>

            {/* Primary Button */}
            {orderProcess === Process.Delivery &&
              <Button text={partialRefundIncludesAllItems() ? 'Mark Order as Refunded' : 'Mark as Ready for Delivery'} type={ButtonType.Primary} icon={ButtonIcon.Arrow} disabled={orderRequiresContact(order) && !scheduleContact} onClick={() => setShowConfirmationModal(true)} />
            }
            {orderProcess === Process.CustomDelivery &&
              <Button text={partialRefundIncludesAllItems() ? 'Mark Order as Refunded' : 'Mark as Ready for Delivery'} type={ButtonType.Primary} icon={ButtonIcon.Arrow} onClick={() => setShowConfirmationModal(true)} />
            }
            {orderProcess === Process.SameDayDelivery &&
              <>
                {errorMessage &&
                  <div style={{ marginTop: '-20px', marginBottom: '20px' }}>
                    <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                      <p>{errorMessage}</p>
                      {showCustomErrorMessage ?
                        <p>This could be a result of an address error by the customer. To resolve, please follow instructions <a href="https://sparrowhub.frontkb.com/en/articles/431617" target="_blank" rel="noreferrer">here</a>. If this does not resolve your issue, please contact Rival Support <a href="mailto:support@sparrowhub.com.au?subject=SparrowHub%20Support%20Request" target="_blank" rel="noreferrer">here</a>.</p>
                        :
                        <p>Please try again or contact Rival Software <a href="mailto:support@sparrowhub.com.au?subject=SparrowHub%20Support%20Request" target="_blank" rel="noreferrer">here</a>.</p>
                      }
                    </Alert>
                  </div>
                }
                <Button text={partialRefundIncludesAllItems() ? 'Mark Order as Refunded' : 'Mark as Ready for Delivery'} type={ButtonType.Primary} icon={ButtonIcon.Arrow} disabled={orderRequiresContact(order) && !scheduleContact} onClick={handleCompleteProcess} loading={isLoading} />
              </>
            }
            {orderProcess === Process.ClickAndCollect &&
              <>
                {errorMessage &&
                  <div style={{ marginTop: '-20px', marginBottom: '20px' }}>
                    <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                      <p>{errorMessage}<br />Please try again or contact Rival Software <a href="mailto:support@sparrowhub.com.au?subject=SparrowHub%20Support%20Request" target="_blank" rel="noreferrer">here</a>.</p>
                    </Alert>
                  </div>
                }
                <Button text="Mark Order as Ready for Pickup" type={ButtonType.Primary} icon={ButtonIcon.Arrow} onClick={handleCompleteProcess} disabled={isDisabled || (orderRequiresContact(order) && !scheduleContact)} loading={isLoading} />
              </>
            }
            {(orderProcess === Process.CompleteRefund || orderProcess === Process.PartialRefund) &&
              <Button text="Finalise Refund" type={ButtonType.Primary} icon={ButtonIcon.Arrow} onClick={() => setShowConfirmationModal(true)} />
            }
            {(orderProcess === Process.CustomRefund || orderProcess === Process.DeliveryRefund) &&
              <>
                {errorMessage &&
                  <div style={{ marginTop: '-20px', marginBottom: '20px' }}>
                    <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                      <p>{errorMessage}<br />Please try again or contact Rival Software <a href="mailto:support@sparrowhub.com.au?subject=SparrowHub%20Support%20Request" target="_blank" rel="noreferrer">here</a>.</p>
                    </Alert>
                  </div>
                }
                <Button text="Finalise Refund" type={ButtonType.Primary} icon={ButtonIcon.Arrow} onClick={handleCompleteProcess} loading={isLoading} />
              </>
            }
            {orderProcess === Process.CreateOrder &&
              <Button text="Proceed to Payment" type={ButtonType.Primary} icon={ButtonIcon.Arrow} onClick={() => setShowPaymentModal(true)} />
            }
            <Button text="Back" type={ButtonType.Secondary} onClick={back} />
          </div>
        }

        {/* create refund */}
        {currentStep() === ProcessOrderStep.CreateRefund &&
          <>
            <div className="ProcessOrderModal_headingContainer">
              <Heading heading="Which type of refund would you like to process?" />
            </div>
            <div className="ProcessOrderModal_refundType">
              <SelectionGroup inputType={SelectionGroupInputType.Radio} groupType={SelectionGroupType.Grid} options={refundTypeOptions()} selected={refundType} onChange={setRefundType} />
            </div>
            <Button text="Proceed to Next Step" type={ButtonType.Primary} icon={ButtonIcon.Arrow} disabled={refundType === ''} onClick={() => navigate(`/send/process/${refundType}/${order.id}`)} />
            <Button text="Back" type={ButtonType.Secondary} onClick={() => navigate('/send/orders')} />
          </>
        }

        {/* select items to refund (partial refund) */}
        {currentStep() === ProcessOrderStep.RefundItems &&
          <>
            <div className="ProcessOrderModal_headingContainer">
              <Heading heading="Which products would you like to return?" />
            </div>
            <div>
              <SelectionGroup inputType={SelectionGroupInputType.Checkbox} groupType={SelectionGroupType.List} options={partialRefundItemOptions} selected={partialRefundItemIds} onChange={handleSetPartialRefundItemIds} />
            </div>
            <Button text="Select Quantities to Return" type={ButtonType.Primary} icon={ButtonIcon.Arrow} disabled={partialRefundItemIds.length === 0} onClick={next} />
            <Button text="Back" type={ButtonType.Secondary} onClick={() => navigate(`/send/process/create-refund/${order.id}`)} />
          </>
        }

        {/* select refund quantities (partial refund) */}
        {currentStep() === ProcessOrderStep.RefundQuantities &&
          <>
            <div className="ProcessOrderModal_headingContainer">
              <Heading heading="How many of each item would you like to return?" />
            </div>
            <div className="PartialRefundModal_items">
              {order.items.map((item: any, i: number) => {
                return (
                  <div key={i}>
                    {partialRefundItemIds.includes(item.sku) &&
                      <div className="PartialRefundModal_item">
                        <div>
                          <p className="PartialRefundModal_itemSku semibold">SKU: {item.sku}</p>
                          <p className="PartialRefundModal_itemName semibold">{item.name}</p>
                          <p className="PartialRefundModal_itemQty semibold">Qty Purchased: {item.qty_ordered}</p>
                          {(item.qty_refunded || 0) > 0 && <p className="PartialRefundModal_itemQty semibold">Qty Refunded: {(item.qty_refunded || 0)}</p>}
                        </div>
                        <QuantitySelector label="Qty to Refund:" quantity={partialRefundItems[i].qtyToRefund} min={0} max={(item.qty_ordered || 0) - (item.qty_refunded || 0)} onChange={(val: number) => handlePartialRefundChange(i, val)} />
                      </div>
                    }
                  </div>
                )
              })}
            </div>
            <Button text="Select Reason for Return" type={ButtonType.Primary} icon={ButtonIcon.Arrow} disabled={partialRefundItems.every((item: any) => item.qtyToRefund === 0)} onClick={next} />
            <Button text="Back" type={ButtonType.Secondary} onClick={back} />
          </>
        }

        {/* select refund reason */}
        {currentStep() === ProcessOrderStep.RefundReason &&
          <>
            <div className="ProcessOrderModal_headingContainer">
              <Heading heading={`What is the reason for the ${orderProcess === Process.CompleteRefund || orderProcess === Process.PartialRefund ? 'return' : 'refund'}?`} />
            </div>
            <div>
              <SelectionGroup inputType={SelectionGroupInputType.Radio} groupType={SelectionGroupType.Grid} options={refundType === 'delivery-refund' ? deliveryRefundReasonOptions : refundReasonOptions} selected={refundReason} onChange={setRefundReason} />
              <div className={`customField_container ${refundReason === RefundReason.Other ? '' : 'hidden'}`}>
                <InputField type="text" id="customConfirmId" label="Other" value={customRefundReason} onChange={(e: ChangeEvent) => setCustomRefundReason((e.target as HTMLInputElement).value)} />
              </div>
            </div>
            <Button text="Add Order Notes" type={ButtonType.Primary} icon={ButtonIcon.Arrow} disabled={customStringFieldIsInvalid(refundReason, customRefundReason)} onClick={next} />
            <Button text="Back" type={ButtonType.Secondary} onClick={orderProcess === Process.CompleteRefund || orderProcess === Process.DeliveryRefund ? () => navigate(`/send/process/create-refund/${order.id}`) : back} />
          </>
        }

        {/* set refund amount (custom refund) */}
        {currentStep() === ProcessOrderStep.RefundAmount &&
          <>
            <div className="ProcessOrderModal_headingContainer">
              <Heading heading="How much would you like to refund?" />
            </div>
            <p>Maximum custom refund: {formatPrice(calculateMaxRefund(order))}</p>
            <div className="ProcessOrderModal_refundAmount">
              <InputField type="number" regex={/^[\d.]+$/} id="refundAmount" label="Refund Amount" placeholder="$" min={0} max={calculateMaxRefund(order)} step="any" value={refundAmount} onChange={(e: ChangeEvent) => setRefundAmount(parseFloat((e.target as HTMLInputElement).value))} />
            </div>
            <Button text="Select Reason for Return" type={ButtonType.Primary} icon={ButtonIcon.Arrow} disabled={refundAmountIsInvalid()} onClick={next} />
            <Button text="Back" type={ButtonType.Secondary} onClick={() => navigate(`/send/process/create-refund/${order.id}`)} />
          </>
        }

        {/* select items (create order) */}
        {currentStep() === ProcessOrderStep.SelectItems &&
          <>
            <div className="ProcessOrderModal_headingContainer">
              <Heading heading="Select the items for this order:" />
            </div>
            <div style={{ paddingBottom: '40px' }}>
              {/* <SearchBar placeholder="Search for items to add to cart..." results={dummyProducts} onSelect={(item: any) => addItemToOrder(item)}></SearchBar> */}
              <SearchBar placeholder="Search for items to add to cart..." query={searchQuery} onSubmit={handleSearch} />
              <Button text="Create Custom Product" type={ButtonType.Primary} colour={ButtonColour.Blue} icon={ButtonIcon.Receipt} small onClick={() => setShowCustomProductModal(true)} />
              <p className="summaryHeading bold" style={{ marginTop: '50px' }}>My Cart</p>
              <OrderItems order={order} orderItems={createOrderItems} setItems={setCreateOrderItems} summaryTable createOrder fields={['sku-name', 'quantity-select', 'price', 'delete']} />
            </div>

            {errorMessage &&
              <div style={{ marginTop: '-30px' }}>
                <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                  <p>{errorMessage}<br />Please try again or contact Rival Software <a href="mailto:support@sparrowhub.com.au?subject=SparrowHub%20Support%20Request" target="_blank" rel="noreferrer">here</a>.</p>
                </Alert>
              </div>
            }

            <Button
              text='Proceed to Next Step'
              type={ButtonType.Primary}
              icon={ButtonIcon.Arrow}
              onClick={() => { setErrorMessage(''); next(); }}
              disabled={createOrderItems.length === 0}
            />
            <Button
              text='Send cart to customer'
              type={ButtonType.Primary}
              icon={ButtonIcon.Arrow}
              onClick={handleCreateCart}
              disabled={createOrderItems.length === 0}
              loading={isLoading}
            />
            <Button
              text='Back'
              type={ButtonType.Secondary}
              onClick={() => navigate("/send/orders")}
            />

            <CustomProductModal show={showCustomProductModal} onClose={() => setShowCustomProductModal(false)} onCreate={(customProduct: any) => addItemToOrder(customProduct)} />
          </>
        }

        {/* select shipping (create order) */}
        {currentStep() === ProcessOrderStep.Payment &&
          <>
            <div className="ProcessOrderModal_headingContainer">
              <Heading heading="Enter the customer's payment details:" />
            </div>

            <div className="summary" style={{ marginTop: '20px' }}>
              <img className="shoppingBagIcon summaryIcon" src={shoppingBagIcon} alt="" title="" />
              <p className="summaryHeading bold">Order Summary</p>
              <OrderItems order={order} orderItems={createOrderItems} summaryTable />
              <img className="personIcon summaryIcon" style={{ marginTop: '30px' }} src={personIcon} alt="" title="" />
              <p className="summaryHeading bold" style={{ marginBottom: '20px' }}>Customer Details</p>
              <CustomerDetails order={createdOrder()} />
            </div>
            
            <PaymentInput user={user} location={location} orderItems={createOrderItems} deliveryMethod={selectedDeliveryObject()} deliveryAddress={deliveryAddress()} billingAddress={billingAddress()} onSetDeliveryAddress={handleSetDeliveryAddress} onSetBillingAddress={handleSetBillingAddress} onSuccess={() => { setPaymentReceived(true); handleCompleteProcess(); }} />

            {/* <Button
              text='Complete custom order'
              type={ButtonType.Primary}
              icon={ButtonIcon.Arrow}
              onClick={() => { console.log('handle complete custom order; confirmation modal?') }}
              // disabled={deliveryType === ""}
            /> */}
            <Button text='Back' type={ButtonType.Secondary} onClick={back} />

            {/* <Modal show={showPaymentModal}>
              <Heading heading="Customer Payment" />
              <div className="orderDetails_divider divider">
                <OrderDetails partnerLocations={partnerLocations} order={createdOrder()} phone email bold={false} />
              </div>
              <form className="ProcessOrderModal_paymentDetails">
                <InputField type="text" id="card-number" label="Card Number" placeholder="1234 1234 1234 1234" value={cardNumber} regex={/^\d+$/} onChange={(e: ChangeEvent) => setCardNumber((e.target as HTMLInputElement).value)} />
                <div className="columns">
                  <InputField type="text" id="expiration" label="Expiration" placeholder="MM/YY" value={expiration} regex={/^\d+$/} onChange={(e: ChangeEvent) => setExpiration((e.target as HTMLInputElement).value)} />
                  <InputField type="text" id="cvv" label="CVV" placeholder="CVV" value={cvv} regex={/^\d{0,3}$/} onChange={(e: ChangeEvent) => setCvv((e.target as HTMLInputElement).value)} />
                </div>
                <Button text="Finalise Order" type={ButtonType.Primary} onClick={() => { handlePayment('cardnotpresent') }} />
              </form>
              <Button text="Cancel" type={ButtonType.Secondary} onClick={() => { setShowPaymentModal(false) }} />
            </Modal> */}
          </>
        }

        {/* customer details (create order) */}
        {currentStep() === ProcessOrderStep.DeliveryDetails &&
          <>
            <div className="ProcessOrderModal_headingContainer">
              <Heading heading="Enter the customer's details:" />
            </div>

            <form id="form_processOrderModal-delivery" className="ProcessOrderModal_customerDetails" style={{ margin: '30px 0 50px 0' }}>
              <p className="formHeading bold">Shipping Address</p>
              <AddressInput address={deliveryAddress()} onSetAddress={handleSetDeliveryAddress} />
            </form>

            {!showDeliveryOptions ?
              <>
                {errorMessage &&
                  <div style={{ marginTop: '0px', marginBottom: '20px' }}>
                    <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                      <p>{errorMessage}<br />Please try again or contact Rival Software <a href="mailto:support@sparrowhub.com.au?subject=SparrowHub%20Support%20Request" target="_blank" rel="noreferrer">here</a>.</p>
                    </Alert>
                  </div>
                }
                
                <Button text="View Delivery & Collection Options" type={ButtonType.Primary} disabled={!customerDetailsAreValid() || isLoading} loading={isLoading} onClick={handleShowDelivery} />
              </>
            :
              <>
                <h3>Delivery & Collection Options</h3>
                {deliveryOptions.length !== 0 &&
                  <SelectionGroup inputType={SelectionGroupInputType.Radio} groupType={SelectionGroupType.DeliveryList} options={deliveryOptions} selected={selectedDeliveryOption} onChange={setSelectedDeliveryOption} />
                }

                {errorMessage &&
                  <div>
                    <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                      <p>{errorMessage}<br />Please try again or contact Rival Software <a href="mailto:support@sparrowhub.com.au?subject=SparrowHub%20Support%20Request" target="_blank" rel="noreferrer">here</a>.</p>
                    </Alert>
                  </div>
                }

                <Button text="Add payment details" type={ButtonType.Primary} icon={ButtonIcon.Arrow} onClick={() => { setErrorMessage(''); next(); }} disabled={!customerDetailsAreValid()} />
                <Button text="Send cart to customer" type={ButtonType.Primary} icon={ButtonIcon.Arrow} onClick={handleCreateCart} disabled={!customerDetailsAreValid()} loading={isLoading} />
              </>
            }

            <Button text="Back" type={ButtonType.Secondary} onClick={back} />
          </>
        }

        {/* send cart link to customer (create order) */}
        {currentStep() === ProcessOrderStep.SendLink &&
          <>
            <div className="ProcessOrderModal_headingContainer">
              <Heading heading="Send cart link to customer:" />
            </div>
            
            <form className="ProcessOrderModal_customerDetails" style={{ margin: '30px 0 50px 0' }}>
              <InputField type="text" id="customerEmail" label="Email" value={deliveryEmail} onChange={(e: ChangeEvent) => setDeliveryEmail((e.target as HTMLInputElement).value)} />
              <InputField type="text" id="customerPhone" label="Mobile Number" value={deliveryPhone} onChange={(e: ChangeEvent) => setDeliveryPhone((e.target as HTMLInputElement).value)} />
            </form>

            <Button
              text='Send link via email'
              type={ButtonType.Primary}
              icon={ButtonIcon.Arrow}
              onClick={handleSendCartEmail}
              disabled={cartNotificationsSent.includes('email') || deliveryEmail === ''}
            />
            <Button
              text='Send link via SMS'
              type={ButtonType.Primary}
              icon={ButtonIcon.Arrow}
              onClick={handleSendCartLinkSMS}
              disabled={cartNotificationsSent.includes('sms') || deliveryPhone === ''}
            />
            <Button
              text='Complete'
              type={ButtonType.Primary}
              onClick={handleCompleteProcess}
              disabled={cartNotificationsSent.length == 0}
            />
            <Button text='Back' type={ButtonType.Secondary} onClick={handleSendLinkBack} />
          </>
        }

        {/* Confirmation/Success Modal */}
        <div className={showSuccessMessage ? 'showSuccess' : ''}>
          <Modal show={showConfirmationModal}>
            {(orderProcess === Process.Delivery || orderProcess === Process.CustomDelivery) &&
              <>
                {!showSuccessMessage ?
                  <>
                    {/* Confirmation modal content */}
                    <Heading heading="Have you checked that the correct products and quantities are ready for delivery?" />

                    {partialRefundIncludesAllItems() ?
                      <>
                        <div className="confirmationAlert">
                          <Alert type={AlertType.Important}>
                            <p>No items to deliver. All items have been marked for refund.</p>
                          </Alert>
                        </div>
                      </>
                      :
                      <>
                        <div className="confirmationAlert">
                          <Alert type={AlertType.Important} small>
                            <p>You will not be able to edit the shipment once the label is printed.</p>
                          </Alert>
                        </div>
                        <OrderItems order={order} orderItems={order.items} partialRefundItems={partialRefundItems} fields={['sku', 'name', 'quantity']} boldQuantity nonRefundsOnly summaryTable />
                      </>
                    }

                    {orderRequiresContact(order) &&
                      <div style={{ margin: '20px 0' }}>
                        <Checkbox id="scheduleContact" selected={scheduleContact} onChange={() => setScheduleContact(val => !val)} required>
                          {requiresContactText()}
                        </Checkbox>
                      </div>
                    }

                    {errorMessage &&
                      <div style={{ marginTop: '40px', marginBottom: '-30px' }}>
                        <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                          <p>{errorMessage}</p>
                          {showCustomErrorMessage ?
                            <p>This could be a result of an address error by the customer. To resolve, please follow instructions <a href="https://sparrowhub.frontkb.com/en/articles/431617" target="_blank" rel="noreferrer">here</a>. If this does not resolve your issue, please contact Rival Support <a href="mailto:support@sparrowhub.com.au?subject=SparrowHub%20Support%20Request" target="_blank" rel="noreferrer">here</a>.</p>
                            :
                            <p>Please try again or contact Rival Software <a href="mailto:support@sparrowhub.com.au?subject=SparrowHub%20Support%20Request" target="_blank" rel="noreferrer">here</a>.</p>
                          }
                        </Alert>
                      </div>
                    }
                    <Button text="Yes, I have" type={ButtonType.Primary} icon={ButtonIcon.Arrow} disabled={orderRequiresContact(order) && !scheduleContact} onClick={handleCompleteProcess} loading={isLoading} />
                  </>
                  :
                  <>
                    {/* Success message */}
                    <Alert type={AlertType.PositiveSecondary}>
                      {partialRefundIncludesAllItems() ?
                        <p>The complete refund has successfully been processed and the customer has been notified via email.</p>
                        :
                        <>
                          {orderProcess === Process.Delivery && ausPostDispatchType === DeliveryModelCode.Pickup &&
                            <p>This order is now ready for delivery and can be found in <span className="extrabold">Requires Manifest &gt; Pickup Orders</span></p>
                          }
                          {orderProcess === Process.Delivery && ausPostDispatchType === DeliveryModelCode.Dropoff &&
                            <p>This order is now ready for delivery and can be found in <span className="extrabold">Requires Manifest &gt; Drop Off Orders</span></p>
                          }
                          {orderProcess === Process.CustomDelivery &&
                            <p>This order is now ready for delivery and can be found in <span className="extrabold">Dispatch & Collection &gt; Awaiting Courier Pickup</span></p>
                          }
                        </>
                      }
                    </Alert>
                  </>
                }
              </>
            }
            {orderProcess === Process.SameDayDelivery &&
              <Alert type={AlertType.PositiveSecondary}>
                <p>This order is now ready for delivery and can be found in <span className="extrabold">Dispatch & Collection &gt; Awaiting Courier Pickup</span></p>
              </Alert>
            }
            {orderProcess === Process.ClickAndCollect &&
              <Alert type={AlertType.PositiveSecondary}>
                {showEjectMessage ?
                  <p>All items have been successfully ejected from the robot</p>
                  :
                  <p>This order is now ready for customer collection and can be found in <span className="extrabold">Dispatch & Collection &gt; Awaiting Click & Collect</span></p>
                }
              </Alert>
            }
            {orderProcess === Process.CustomerCollection &&
              <Alert type={AlertType.PositiveSecondary}>
                <p>This order has been marked as collected and can be found in <span className="extrabold">Closed Orders &gt; Completed Orders</span></p>
              </Alert>
            }
            {(orderProcess === Process.CompleteRefund || orderProcess === Process.PartialRefund) &&
              <>
                {!showSuccessMessage ?
                  <>
                    {/* Confirmation modal content */}
                    <Heading heading="Are you sure you want to process the refund for the below items and quantities?" />
                    <div style={{ marginBottom: '70px' }}>
                      <OrderItems order={order} orderItems={orderProcess === Process.PartialRefund ? filteredRefundItems() : order.items} fields={['name', 'quantity']} />
                    </div>
                    {errorMessage &&
                      <div style={{ marginTop: '-30px', marginBottom: '-30px' }}>
                        <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                          <p>{errorMessage}<br />Please try again or contact Rival Software <a href="mailto:support@sparrowhub.com.au?subject=SparrowHub%20Support%20Request" target="_blank" rel="noreferrer">here</a>.</p>
                        </Alert>
                      </div>
                    }
                    <Button text="Yes, I'm Sure" type={ButtonType.Primary} icon={ButtonIcon.Arrow} onClick={handleCompleteProcess} loading={isLoading} />
                  </>
                  :
                  <>
                    {/* Success message */}
                    {orderProcess === Process.CompleteRefund &&
                      <Alert type={AlertType.PositiveSecondary}>
                        <p>This order refund was successful and can be found in <span className="extrabold">Closed Orders &gt; Refunded Orders</span></p>
                      </Alert>
                    }
                    {orderProcess === Process.PartialRefund &&
                      <Alert type={AlertType.PositiveSecondary}>
                        <p>This order refund was successful</p>
                      </Alert>
                    }
                  </>
                }
              </>
            }
            {orderProcess === Process.CustomRefund &&
              <Alert type={AlertType.PositiveSecondary}>
                <p>This order refund was successful</p>
              </Alert>
            }
            {orderProcess === Process.DeliveryRefund &&
              <Alert type={AlertType.PositiveSecondary}>
                <p>This order refund was successful</p>
              </Alert>
            }
            {orderProcess === Process.CreateOrder &&
              <>
                {!showSuccessMessage ?
                  <>
                    {/* Confirmation modal content */}
                    <p>TODO PAYMENT CONFIRMATION MODAL</p>
                    {/* <Heading heading={
                      paymentType === 'sms' ? 'Are you sure you want to send the payment link via SMS?' :
                        paymentType === 'email' ? 'Are you sure you want to send the payment link via email?' :
                          'Have you processed the card not present transaction?'
                    } />
                    <div className="orderDetails_divider divider">
                      <OrderDetails order={createdOrder()} partnerLocations={partnerLocations} phone email address={deliveryType !== 'clickcollect'} location={deliveryType === 'clickcollect'} />
                    </div>
                    <Button
                      text={paymentType === "cardnotpresent" ? "Yes, I have" : "Yes, I am"}
                      type={ButtonType.Primary}
                      icon={ButtonIcon.Arrow}
                      onClick={handleCompleteProcess}
                      loading={isLoading}
                    /> */}
                  </>
                  :
                  <>
                    {/* Success message */}
                    <Alert type={AlertType.PositiveSecondary}>
                      {paymentReceived ?
                        <p>This order has successfully been created and can be found in <span className="extrabold">Open Orders &gt; New Orders</span></p>
                      :
                        <p>This order has successfully been created and can be found in <span className="extrabold">Open Orders &gt; Pending Payment</span></p>
                      }
                    </Alert>
                  </>
                }
              </>
            }
            {!showSuccessMessage &&
              <Button text="Back" type={ButtonType.Secondary} onClick={() => setShowConfirmationModal(false)} />
            }
          </Modal>
        </div>
      </div>
    </StyledProcessOrderModal>
  );
}

const StyledProcessOrderModal = styled.div<{ deliveryType: OrderDeliveryType }>`
  ${containers.default}
  padding-top: 36px;
  position: relative;
  overflow: hidden;

  // TOP GRADIENT
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 10px;
    ${props => handleDeliveryType(props.deliveryType)};
  }

  .ProcessOrderModal_orderDetails {
    .Badge {
      display: inline-flex;
    }
  }

  .divider {
    &.ProcessOrderModal_orderDetails {
      padding: 28px 0;
      position: relative;
      z-index: 0;
    }
    &.parcel_divider {
      padding-top: 0;
    }
  }

  textarea {
    margin-top: 46px;
    margin-bottom: 60px;
  }

  .ProcessOrderModal_stepContents {
    padding-top: 50px;

    .ProcessOrderModal_orderBags {
      input[type=number] {
        margin-bottom: 47px;
      }
    }

    .ProcessOrderModal_refundAmount {
      width: 182px;

      label {
        display: block;
        margin-top: 30px;
      }

      input[type=number] {
        margin-bottom: 67px;
      }
    }

    .Modal {
      padding-top: 75px;
      padding-bottom: 75px;
      transition: padding ${transitions.default}, max-height ${transitions.default};

      .Alert {
        margin-top: 0;
        margin-bottom: 15px;
      }

      .confirmationAlert {
        .Alert {
          margin-top: 20px;
          margin-bottom: 15px;

          img {
            margin-right: 14px;
          }
        }
      }

      .ProcessOrderModal_declarationConfirmationGrid {
        display: grid;
        grid-gap: 25px 14px;
        gap: 25px 14px;
        grid-template-columns: 1fr 1fr;

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 230px;
          height: 128px;
          margin-top: 50px;
          padding: 30px;
        }
      }

      .ProcessOrderModal_createOrderPaymentGrid {
        display: grid;
        grid-gap: 25px 14px;
        gap: 25px 14px;
        grid-template-columns: 1fr 1fr;
        margin: 50px 0;

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 128px;
          padding: 30px;
          margin-top: 0;

          &:last-child {
            grid-column: span 2;
          }
        }
      }
    }

    .showSuccess {
      .Modal {
        padding-top: 33px;
        padding-bottom: 33px;
        max-height: unset !important;

        .Alert {
          margin: 0;
          p {
            font-size: 1rem !important; // 16px
          }
        }
      }
    }

    .ProcessOrderModal_headingContainer {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 10px;
      position: relative;

      .link {
        text-align: right;
        cursor: pointer;
        line-height: 164%;
        margin: 0;

        span {
          text-decoration: underline;
        }
      }

      .Button {
        width: fit-content;
        height: 30px;
        padding: 0 12px;
        font-size: 0.75rem; // 12px
        /* margin-bottom: 5px; */
        margin-top: 4px !important;
        align-self: center;
        
        img {
          margin-right: 6px;
        }
      }

      .ejectReservation_demoIndicator {
        background: ${colours.important};
        width: 7px;
        height: 7px;
        border-radius: 100%;
        position: absolute;
        right: -15px;
        top: 20px;
      }
    }

    .ProcessOrderModal_parcelHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        font-size: 1.25rem; // 20px
        margin: 36px 0;
      }

      img {
        height: fit-content;
        padding: 8px;
        margin-right: -8px;
      }
    }

    .ProcessOrderModal_deliveryMethodContainer {
      margin-bottom: 47px !important;

      .SelectionGroup_grid {
        margin-bottom: 40px !important;
      }
    }

    .ProcessOrderModal_buttonContainer {
      display: flex;

      .Button:first-child {
        margin-right: 10px;
      }
    }

    .ProcessOrderModal_weightInput {
      position: relative;
      margin-top: 45px;
      margin-bottom: 67px;
      min-height: 325px;

      .ProcessOrderModal_inputContainer {
        width: 116px;

        p {
          font-size: 1.25rem; // 20px
        }
      }

      .ProcessOrderModal_videoContainer {
        position: absolute;
        top: 0;
        right: 0;
        border-left: 1px solid #DADADA;
        padding: 0 35px;

        label {
          display: block;
          margin-bottom: 10px;
        }

        video {
          width: 166px;
          height: 295px;
          border-radius: 4px;
        }
      }
    }

    .ProcessOrderModal_orderConfirmation {
      .divider {
        padding-top: 10px;
        padding-bottom: 50px;
        margin-bottom: 35px;
      }

      .summary {
        margin-bottom: 55px;
      }

      .summaryIcon {
        width: 35px;
        height: 35px;

        &.shoppingBagIcon {
          width: 23px;
          height: auto;
        }

        &.dollarIcon {
          margin-left: -10px;
        }
      }

      p.summaryHeading {
        font-size: 1.25rem; // 20px
        margin-top: 10px;
        margin-bottom: 5px;
      }

      .process table {
        margin-bottom: 15px;
      }

      .ProcessOrderModal_shippingSummary {
        width: 100%;
        border-spacing: 0 21px;
        margin-bottom: 61px;
      }

      .ProcessOrderModal_refundSummary {
        width: 100%;
        border-spacing: 0 21px;
        margin-bottom: 61px !important;
      }

      .ProcessOrderModal_shippingLocations {
        margin-top: -30px;
        margin-bottom: 80px;

        p.bold {
          margin-bottom: 15px;
          margin-top: 50px;
          line-height: 164%;
        }

        p {
          font-size: 1rem; // 16px
          line-height: 1.64;
        }
      }

      .Heading {
        margin-bottom: 30px;
      }

      .Button_primary.Button_small {
        margin-top: -35px;
        margin-bottom: 65px;
      }
    }

    .SelectionGroup_grid {
      margin: 0 0 51px 0;

      &:first-child {
        margin: 57px 0;
      }
    }

    .SelectionGroup_list {
      margin: 0 0 51px 0;

      &:first-child {
        margin: 57px 0;
      }
    }

    .CustomParcelInput {
      margin: 0 0 51px 0;

      &:first-child {
        margin: 57px 0;
      }
    }

    .Button_label {
      margin-top: 18px;
    }

    .Button_addParcel {
      margin-bottom: 72px;
    }

    .Button_primary.Button_small {
      margin-top: 48px;
    }

    .Alert {
      margin-top: 61px;
    }

    .ProcessOrderModal_parcelContainer {
      .Alert {
        margin: -15px 0 25px 0;
      }
    }

    .Button_primary {
      margin-top: 25px;
    }

    .Modal {
      .Button_primary {
        margin-top: 58px;
      }
    }

    .Button_secondary {
      margin-top: 25px;
    }
  }

  .PartialRefundModal_items {
    margin-top: 40px;
    margin-bottom: 50px;

    .PartialRefundModal_item {
      border: 1px solid #9B9B9B;
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
      border-radius: 6px;
      margin: 10px 0;
      padding: 12px 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.disabled {
        pointer-events: none;
        opacity: 0.5;

        .QuantitySelector_disabled {
          opacity: 1 !important;
        }
      }

      p {
        margin: 4px 0;
      }

      .PartialRefundModal_itemSku {
        font-size: 0.625rem; // 10px
      }

      .PartialRefundModal_itemName {
        font-size: 0.75rem; // 12px
      }

      .PartialRefundModal_itemQty {
        font-size: 0.625rem; // 10px
      }

      .QuantitySelector {
        margin: 0 5px 0 10px;
      }
    }
  }

  .customField_container {
    height: 121px;
    opacity: 1;
    overflow: hidden;
    transition: height ${transitions.default},
                opacity ${transitions.default},
      margin ${transitions.default};

    &.hidden {
      height: 0;
      opacity: 0;
      pointer-events: none;
      margin-bottom: -25px !important;
    }
  }

  .summaryIcon {
    width: 35px;
    height: 35px;

    &.personIcon {
      margin-left: -5px;
    }
  }

  .ProcessOrderModal_customerDetails,
  .ProcessOrderModal_paymentDetails
  {
    .InputField label {
      display: inline-block;
      margin-top: 20px;
    }
  }

  p.formHeading {
    font-size: 1.25rem; // 20px
    margin: 0;
  }

  p.summaryHeading {
    font-size: 1.25rem; // 20px
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .tempCustomerDetails {
    margin-top: 15px;

    p {
      margin: 0 0 5px 0;

      span:first-child {
        display: inline-block;
        width: 80px;
      }
    }
  }

  .orderDetails_divider {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    margin-bottom: 35px !important;
  }

  .ProcessOrderModal_dispatchType {
    .SelectionButton {
      height: 149px;
    }
  }
`

const handleDeliveryType = (deliveryType: OrderDeliveryType): string => {
  switch (deliveryType) {
    case OrderDeliveryType.Express:
      return 'background: linear-gradient(to left, #FABD4A, #F67329 90%);'
    case OrderDeliveryType.Free:
    case OrderDeliveryType.Standard:
      return 'background: linear-gradient(to left, #6DDD96, #67D3F7 90%);'
    case OrderDeliveryType.Pickup:
      return 'background: linear-gradient(to left, #AE44B4, #6D5BD7 90%);'
    case OrderDeliveryType.SameDay:
      return 'background: linear-gradient(to left, #AE44B4, #CF124B 90%);'
    case OrderDeliveryType.Custom:
      return 'background: linear-gradient(to right, #73AAEB, #1239C5 90%);'
    default:
      return '';
  }
};