import { FunctionComponent, useState, ChangeEvent } from 'react';
import styled from 'styled-components';

import { useApi } from '../../context/ApiProvider';
import { ApiHelper } from '../../common/ApiHelper/ApiHelper';
import { SparrowHubApiInterface } from 'sparrowhub-client-axios';

import { Modal } from '../Modal/Modal';
import { Heading } from '../Heading/Heading';
import { Button, ButtonType } from '../Button/Button';
import { InputField } from '../InputField/InputField';
import { SelectionButton } from '../SelectionButton/SelectionButton';
import { SelectionGroupInputType } from '../SelectionGroup/SelectionGroup';


type CustomProductModalProps = {
  show: boolean
  onCreate?: Function
  onClose?: Function
}

export const CustomProductModal: FunctionComponent<CustomProductModalProps> = ({ show, onCreate, onClose }) => {
  const { apiHelper, api }: { apiHelper: ApiHelper; api: SparrowHubApiInterface } = useApi();

  // state
  const [name, setName] = useState('');
  const [sku, setSku] = useState('');
  const [barcode, setBarcode] = useState('');
  const [price, setPrice] = useState('');
  const [gst, setGst] = useState(false);

  // methods
  const transitionDuration = 300;
  const handleCreateProduct = (): void => {
    const product = {
      name,
      sku,
      total: price,
      gst,
      qty_ordered: 1
    }

    if (onCreate) onCreate(product);
    if (onClose) onClose();

    setTimeout(() => {
      clearState();
    }, transitionDuration);
  }

  const handleClose = (): void => {
    if (onClose) onClose();
  }

  const clearState = (): void => {
    setName('');
    setSku('');
    setPrice('');
    setBarcode('');
    setGst(false);
  }

  const formIsInvalid = (): boolean => {
    const formEl = (document.getElementById('form_custom-product-modal') as HTMLFormElement);
    return name.trim() === ''
      || sku.trim() === ''
      || price.trim() === ''
      || (formEl && !formEl.checkValidity());
  }

  return (
    <StyledCustomProductModal className="CustomProductModal">
      <Modal show={show}>
        <Heading heading="Enter the details of your custom product:" />
        <form id="form_custom-product-modal">
          <InputField type="text" id="productName" label="Product Name" value={name} maxLength={255} required onChange={(e: ChangeEvent) => setName((e.target as HTMLInputElement).value)} />
          <InputField type="text" id="sku" label="SKU" value={sku} maxLength={128} required onChange={(e: ChangeEvent) => setSku((e.target as HTMLInputElement).value)} />
          <InputField type="text" id="barcode" label="Barcode" value={barcode} maxLength={255} regex={/^\d+$/} onChange={(e: ChangeEvent) => setBarcode((e.target as HTMLInputElement).value)} />
          <div className="CustomProductModal_columns">
            <InputField type="number" id="price" label="Price" placeholder="$" min={0} step="any" regex={/^[0-9]*\.?[0-9]{0,2}$/} value={price} required onChange={(e: ChangeEvent) => setPrice((e.target as HTMLInputElement).value)} />
            <SelectionButton type={SelectionGroupInputType.Checkbox} checkbox id="gst" label="This price includes GST" selected={gst} value="" onChange={(e: ChangeEvent) => setGst(gst => !gst)} />
          </div>
          <Button type={ButtonType.Primary} text="Add Product" disabled={formIsInvalid()} onClick={handleCreateProduct} />
        </form>
        <Button type={ButtonType.Secondary} text="Close" onClick={handleClose} />
      </Modal>
    </StyledCustomProductModal>
  );
}

const StyledCustomProductModal = styled.div`
  .Modal {
    .Heading {
      margin-bottom: 30px;
    }

    .InputField {
      margin: 24px 0;
    }

    .CustomProductModal_columns {
      display: grid;
      gap: 10px;
      grid-gap: 10px;
      grid-template-columns: 275px 1fr;
      margin-bottom: -15px;

      & > div {
        margin: auto 0 24px 0;

        input {
          margin-bottom: 0;
        }
      }
    }
  }
`