import { ChangeEventHandler, FunctionComponent } from "react";
import styled from 'styled-components';
import { transitions, colours } from '../../assets/css/variables';

import tickIcon from '../../assets/images/icons/TickWhite.svg';

type CheckboxProps = {
  children: any
  selected: boolean
  value?: any
  id: string
  onChange?: Function
  required?: boolean
  disabled?: boolean
}

export const Checkbox: FunctionComponent<CheckboxProps> = ({ children, selected, value, id, onChange, required, disabled }) => {
  const handleClick = (): void => {
    if (onChange) onChange(value);
  }

  return (
    <StyledCheckbox className={`Checkbox ${selected && 'selected'} ${required && 'required'} ${disabled && 'disabled'}`} onClick={handleClick}>
      <input type="checkbox" id={id} name={id} value={value} checked={selected} onChange={onChange as ChangeEventHandler} style={{ backgroundImage: `url(${tickIcon})` }} />
      <label className="semibold" htmlFor={id}>
        {children}
      </label>
    </StyledCheckbox>
  );
}



const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;

  cursor: pointer;

  &:hover {
    input {
      transform: scale(1.2);
    }
  }

  input {
    appearance: none;
    border: 1px solid ${colours.inputOutline};
    background: white;
    border-radius: 4px;

    min-width: 18px;
    min-height: 18px;
    margin: 1px;
    pointer-events: none;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px;
    margin-right: 11px;

    transition:
      border ${transitions.fast},
      transform ${transitions.fast},
      background-color ${transitions.fast},
      outline ${transitions.fast};
  }

  &.selected {
    outline: none;

    input {
      border: 1px solid black;
      background-color: black;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  label {
    font-size: 0.8125rem; // 13px
    letter-spacing: -0.02em;
  }

  &.required {
    label:after {
      content: "*";
      margin-left: 2px;
      color: ${colours.alert};
    }
  }
`;
