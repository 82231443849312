import { FunctionComponent, useState, useEffect } from 'react';
import styled from 'styled-components';
import { colours, transitions, boxShadows } from '../../assets/css/variables';
import { filterOrders, filterOrdersByCategory } from '../../helpers/utils';
import { useDummyData } from '../../context/DummyDataProvider';
import useSound from 'use-sound';

import { IOrders } from '../../types/IOrders';
import { Category, Subcategory } from '../../types/DashboardCategories';

import { DashboardCount } from '../DashboardCount/DashboardCount';

import arrowIcon from '../../assets/images/icons/Arrow.svg';
import openIcon from '../../assets/images/icons/Orders.svg';
import manifestIcon from '../../assets/images/icons/RequiresManifest.svg';
import dispatchIcon from '../../assets/images/icons/Shipping.svg';
import closedIcon from '../../assets/images/icons/ClosedOrders.svg';

import notification from '../../assets/audio/notification.wav';


export const allCategories = [
  {
    id: Category.OpenOrders,
    label: 'Open Orders',
    icon: openIcon
  },
  {
    id: Category.RequiresManifest,
    label: 'Requires Manifest',
    icon: manifestIcon
  },
  {
    id: Category.DispatchCollection,
    label: 'Dispatch & Collection',
    icon: dispatchIcon
  },
  {
    id: Category.ClosedOrders,
    label: 'Closed Orders',
    icon: closedIcon
  },
]

export const allSubcategories = [
  // open
  { id: Subcategory.IncomingOrders, label: 'New Orders' },
  { id: Subcategory.FraudOrders, label: 'Fraud Orders' },
  { id: Subcategory.InProgress, label: 'In Progress' },
  { id: Subcategory.PendingPayment, label: 'Pending Payment' },
  // requires manifest
  { id: Subcategory.ManifestPickup, label: 'Pickup Orders' },
  { id: Subcategory.ManifestDropoff, label: 'Drop Off Orders' },
  // dispatch & collection
  { id: Subcategory.DispatchPickup, label: 'Awaiting Courier Pickup' },
  { id: Subcategory.DispatchDropoff, label: 'Awaiting Drop Off' },
  { id: Subcategory.DispatchCollection, label: 'Awaiting Click & Collect' },
  // closed
  { id: Subcategory.CompletedOrders, label: 'Completed Orders' },
  { id: Subcategory.CancelledOrders, label: 'Cancelled Orders' },
  { id: Subcategory.PartialRefundOrders, label: 'Partially Refunded Orders' },
  { id: Subcategory.RefundOrders, label: 'Refunded Orders' },
]

type DashboardProps = {
  category: Category
  subcategory: Subcategory
  query: string
  setCategory: Function
  setSubcategory: Function
  orders: IOrders | null
}

export const Dashboard: FunctionComponent<DashboardProps> = ({ category, subcategory, query, setCategory, setSubcategory, orders }) => {
  // context
  const dummyData: any = useDummyData();

  // state
  const [localNumOrders, setLocalNumOrders] = useState({});
  const [suppressAnimation, setSuppressAnimation] = useState(false);

  const [playNotification] = useSound(notification, { volume: 0.6 });

  // update local display when component mounts
  // to trigger dashboard animations after delay
  useEffect(() => {
    // check store for dashboard values
    const storeNumOrders = dummyData.state.dashboard.numOrders;

    // if existing dashboard values exist, set locally
    // (avoids empty display when returning to dashboard)
    if (storeNumOrders.open !== -1) {
      setLocalNumOrders(storeNumOrders);
    }
    
    // get updated values from store
    const newNumOrders = getNumOrders();

    // play sound if new open orders
    if (localNumOrders.hasOwnProperty('open') && newNumOrders.open > (localNumOrders as any).open) {
      playNotification();
    }

    // if no existing dashboard values exist
    if (storeNumOrders.open === -1 || storeNumOrders.open === 0) {
      // set values locally immediately
      // (avoids empty display on first load)
      setLocalNumOrders(newNumOrders);
    } else {
      // else set values locally after delay
      // (updates display to trigger animations)
      const delay = dummyData.state.useDummyData ? 1500 : 0;
      // const delay = 0;
      setTimeout(() => {
        setLocalNumOrders(newNumOrders);
      }, delay);
    }

    // store dashboard numbers in dummy data
    dummyData.mutations.setDashboardNumOrders(newNumOrders);
  }, [orders]);

  // watch props to suppress DashboardCount animation
  // when returning from search and when changing stores
  useEffect(() => {
    if (query !== '' || orders === null) {
      setSuppressAnimation(true);
    } else {
      setTimeout(() => {
        setSuppressAnimation(false);
      }, 500);
    }
  }, [query, orders]);

  // computed
  const numOrdersByCategory = (category: Category): number => {
    if (!orders || orders[0] === null) return 0;
    return filterOrders(orders, category, query, undefined, dummyData.state.useDummyData).length;
  }
  
  const numOrdersBySubcategory = (subcategory: Subcategory): number => {
    if (!orders || orders[0] === null) return 0;
    return filterOrders(orders, category, query, subcategory, dummyData.state.useDummyData).length;
  }
  
  const unfilteredOrdersByCategory = (category: Category): number => {
    if (!orders || orders[0] === null) return 0;
    return filterOrders(orders, category, undefined, undefined, dummyData.state.useDummyData).length;
  }

  const getNumOrders = (): Record<string, number> => {
    const result: Record<string, number> = {};
    allCategories.forEach(category => {
      if (!orders || orders[0] === null) {
        result[category.id] = 0;
      } else {
        result[category.id] = filterOrdersByCategory(orders, category.id, dummyData.state.useDummyData).filter(order => order.is_visible !== false).length;
      }
    })
    return result;
  }

  const availableCategories = (): Array<Category> => {
    if (!orders || orders[0] === null) return [Category.OpenOrders, Category.DispatchCollection, Category.ClosedOrders];
    
    const includeManifest = unfilteredOrdersByCategory(Category.RequiresManifest) !== 0;

    if (includeManifest) {
      return [Category.OpenOrders, Category.RequiresManifest, Category.DispatchCollection, Category.ClosedOrders];
    } else {
      return [Category.OpenOrders, Category.DispatchCollection, Category.ClosedOrders];
    }
  }
  
  const availableSubcategories = (): Array<Subcategory> => {
    switch (category) {
      case Category.OpenOrders:
        return [
          Subcategory.IncomingOrders,
          // Subcategory.FraudOrders,
          Subcategory.InProgress,
          Subcategory.PendingPayment,
        ];
      case Category.RequiresManifest:
        return [
          Subcategory.ManifestPickup,
          Subcategory.ManifestDropoff,
        ];
      case Category.DispatchCollection:
        return [
          Subcategory.DispatchPickup,
          Subcategory.DispatchDropoff,
          Subcategory.DispatchCollection,
        ];
      case Category.ClosedOrders:
        return [
          Subcategory.CompletedOrders,
          // Subcategory.CancelledOrders,
          Subcategory.PartialRefundOrders,
          Subcategory.RefundOrders,
        ];
    }
  }

  return (
    <>
      <StyledDashboard className="Dashboard" numCategories={availableCategories().length} numSubcategories={availableSubcategories().length}>
        <div className="Dashboard_categories">
          {allCategories.map((cat: any, i: number) => {
            return (
              <div
                className={`Dashboard_tile Dashboard_tile_primary ${cat.id === category ? 'selected' : ''} ${!availableCategories().includes(cat.id) ? 'hidden' : ''}`}
                // style={cat.id === 'overdue' && ((query !== '' ? numOrdersByCategory(cat.id) : localNumOrders as any)[cat.id]) > 0 ? { color: colours.alert} : {}}
                id={`tile_${cat.id}`}
                key={cat.id}
                onClick={() => setCategory(cat.id)}
              >
                <div className="tile_countParent">
                  {(cat.id !== Category.ClosedOrders || query) &&
                      <>
                      <div style={{ display: 'inline', position: 'relative' }}>
                        <DashboardCount
                          value={query !== '' ? numOrdersByCategory(cat.id) : (localNumOrders as any)[cat.id]}
                          small={i > 3}
                          noAnimation={query !== '' || suppressAnimation || i === 5}
                        />
                      </div>
                      <div style={{ display: 'inline' }}>
                        {query && <span className="tile_resultLabel bold">{numOrdersByCategory(cat.id) === 1 ? 'result' : 'results'}</span>}
                      </div>
                    </>
                  }
                </div>
                <p className="tile_label">{cat.label}</p>
                <img src={arrowIcon} alt="" className={`tile_arrow ${cat.id === category ? 'selected' : ''}`} draggable="false" />
                <img src={cat.icon} alt="" className="tile_icon" draggable="false" />
              </div>
            )
          })}
        </div>

        <div className="Dashboard_subcategories">
          {allSubcategories.map((subcat: any, i: number) => {
            return (
              <div
                className={`Dashboard_tile Dashboard_tile_secondary ${subcat.id === subcategory ? 'selected' : ''} ${!availableSubcategories().includes(subcat.id) ? 'hidden' : ''}`}
                // style={cat.id === 'overdue' && ((query !== '' ? numOrdersByCategory(cat.id) : localNumOrders as any)[cat.id]) > 0 ? { color: colours.alert} : {}}
                id={`tile_${subcat.id}`}
                key={subcat.id}
                onClick={() => setSubcategory(subcat.id)}
              >
                <p className="tile_label">{subcat.label}</p>
                {numOrdersBySubcategory(subcat.id) > 0 && <p className="tile_count">{numOrdersBySubcategory(subcat.id)}</p>}
              </div>
            )
          })}
        </div>
      </StyledDashboard>
    </>
  );
}

const StyledDashboard = styled.div<{numCategories: number, numSubcategories: number}>`
  margin-top: 31px;
  margin-bottom: 40px;
  user-select: none;

  .Dashboard_categories {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;

    .Dashboard_tile {
      ${props => setTileWidth(props.numCategories)};

      &.hidden {
        width: 0;
        display: block !important;
        padding: 0 !important;
        opacity: 0;
        margin-right: -12px;

        * {
          opacity: 0;
        }
      }
    }
  }
  
  .Dashboard_subcategories {
    display: flex;
    gap: 10px;

    .Dashboard_tile {
      ${props => setTileWidth(props.numSubcategories)};

      &.hidden {
        display: none !important;
      }
    }
  }

  .Dashboard_tile {
    position: relative;
    border: 2px solid #00000000;
    cursor: pointer;
    border-radius: 6px;
    background: white;
    overflow: hidden;
    white-space: pre;
    color: black;
    ${boxShadows.default}
    transition:
      box-shadow ${transitions.default},
      width ${transitions.default},
      margin-right ${transitions.default},
      opacity ${transitions.default},
      border-color ${transitions.default};

    &:hover {
      ${boxShadows.hover}
    }

    &.selected {
      border: 2px solid black;
      
      .tile_label {
        font-family: 'Mulish Bold'; 
      }
      .tile_count {
        background: black;
        color: white;
        font-family: 'Mulish Bold'; 
      }
    }

    p {
      margin: 0;

      &.tile_label {
        font-family: 'Mulish Regular';
        font-size: 0.625rem; // 10px
        display: flex;
        align-items: center;
        /* gap: 6px; */
      }

      &.tile_count {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        min-width: 24px;
        height: 24px;
        padding: 0 5px;
        border-radius: 12px;
        background: white;
        color: black;
        border: 1px solid black;
        font-size: 0.625rem; // 10px
      }
    }

    img {
      width: 14px;
      height: 10px;
      transition: opacity ${transitions.default}, margin-left ${transitions.slow};

      &.selected {
        opacity: 0;
        margin-left: -32px;
      }
    }

    &.Dashboard_tile_primary {
      height: 106px;
      padding: 11px 5px 11px 15px;
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-start;

      .tile_countParent {
        margin-top: 1px;
        height: 38px;
        transition: margin-top ${transitions.slow};
        display: inline-block;

        .tile_resultLabel {
          font-size: 1.125rem; // 18px
          margin-left: 8px;
          display: inline-block;
        }
      }
      
      &.selected {
        .tile_countParent {
          margin-top: 3px;
        }
      }

      .tile_arrow {
        position: absolute;
        top: 18px;
        right: 19px;
      }
      
      .tile_icon {
        position: absolute;
        top: 15px;
        left: 16px;
        width: 17px;
        height: 17px;
      }
    }

    &.Dashboard_tile_secondary {
      height: 49px;
      grid-column: span 2;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px;

      /* p.tile_label {
        margin: 1px 18px 0 18px;
      }

      .tile_countParent {
        display: flex;
        align-items: center;
        
        .tile_count {
          display: flex;
          align-items: center;
          display: inline-block;
        }

        .tile_resultLabel {
          font-size: 1rem; // 16px
          margin-left: 8px;
          margin-bottom: 1px;
          display: inline-block;
          margin-left: 8px;
        }
      } */
    }
  }
`

const setTileWidth = (number: number): string => {
  return `
    width: calc(100% / ${number});
  `
}