import { FunctionComponent, useState, ChangeEvent } from 'react';
import styled from 'styled-components';

import { IOrder, AddressState, AddressCountry, OrderDeliveryType } from '../../types/IOrders';
import { IRequestBodyUpdateOrder } from '../../types/IRequests';

import { useApi } from '../../context/ApiProvider';
import { ApiHelper } from '../../common/ApiHelper/ApiHelper';
import { SparrowHubApiInterface } from 'sparrowhub-client-axios';
import { useDummyData } from '../../context/DummyDataProvider';

import { Modal } from '../Modal/Modal';
import { Heading } from '../Heading/Heading';
import { InputField } from '../InputField/InputField';
import { SelectInput, SelectInputOption } from '../SelectInput/SelectInput';
import { Button, ButtonType } from '../Button/Button';
import { Alert, AlertIcon, AlertType } from '../Alert/Alert';
import { OrderDetails } from '../OrderDetails/OrderDetails';
import { OrderBadges } from '../OrderBadges/OrderBadges';


type EditCustomerDetailsModalProps = {
  show: boolean
  order: IOrder
  onClose?: Function
  onRefresh: Function
}

const stateOptions: Array<SelectInputOption> = Object.values(AddressState).map((state) => {
  return {
    value: state,
    label: state
  }
});

const countryOptions: Array<SelectInputOption> = Object.values(AddressCountry).map((country) => {
  return {
    value: country,
    label: country
  }
});

export const EditCustomerDetailsModal: FunctionComponent<EditCustomerDetailsModalProps> = ({ show, order, onClose, onRefresh }) => {
  const { apiHelper, api }: { apiHelper: ApiHelper; api: SparrowHubApiInterface } = useApi();
  const dummyData: any = useDummyData();
  
  // state
  const [showSuccess, setShowSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // initial values
  let initialStreetParsed = [];
  try {
    initialStreetParsed = JSON.parse(order.delivery_street);
  } catch (error) {
    initialStreetParsed = ['',''];
  }
  const initialStreet1 = initialStreetParsed[0];
  const initialStreet2 = initialStreetParsed[1];
  const initialSuburb = order.delivery_city;
  const initialPostcode = order.delivery_postcode;
  const initialState = order.delivery_state_code;
  const initialCountry = order.delivery_country_code;  

  const [street1, setStreet1] = useState(initialStreet1);
  const [street2, setStreet2] = useState(initialStreet2);
  const [suburb, setSuburb] = useState(initialSuburb);
  const [postcode, setPostcode] = useState(initialPostcode);
  const [state, setState] = useState(initialState);
  const [country, setCountry] = useState(initialCountry);

  // computed
  const validChanges = (): boolean => {
    const fieldsChanged = 
      street1 !== initialStreet1
      || street2 !== initialStreet2
      || suburb !== initialSuburb
      || postcode !== initialPostcode
      || state !== initialState
      || country !== initialCountry;

    const fieldsValid = 
      street1 !== ''
      && suburb !== ''
      && postcode.length === 4;

    return (fieldsChanged && fieldsValid);
  }

  // methods
  const successDuration = 3000;
  const saveChanges = (): Promise<any> => {
    setErrorMessage('');
    return new Promise<any>((resolve, reject) => {
      if (dummyData.state.useDummyData) {
        // dummyData.mutations.setOrderStatus(order.id, orderStatus);
        resolve('');
      } else {
        const requestBody: IRequestBodyUpdateOrder = {
          delivery_street: street2 ? JSON.stringify([street1, street2]) : JSON.stringify([street1]),
          delivery_city: suburb,
          delivery_postcode: postcode,
          delivery_state_code: state,
          delivery_country_code: country
        }
        setIsLoading(true);
        apiHelper.updateOrder(order.id, requestBody).then((response) => {
          // console.log(response);
          setIsLoading(false);
          if (response.status === 200) {
            onRefresh();
            setShowSuccess(true);
            setTimeout(() => {
              handleClose();
            }, successDuration);
            resolve(response.body.data);
          } else {
            setErrorMessage(`Error updating order: ${response.body.message}`);
            reject(response.body.message);
          }
        });
      }
    });
  }

  const transitionDuration = 300;
  const handleClose = (): void => {
    setTimeout(() => {
      // reset state
      setStreet1(initialStreet1);
      setStreet2(initialStreet2);
      setSuburb(initialSuburb);
      setPostcode(initialPostcode);
      setState(initialState);
      setCountry(initialCountry);
      // reset success message
      setShowSuccess(false);
    }, transitionDuration);

    if (onClose) onClose();
  }

  return (
    <StyledEditCustomerDetailsModal className="EditDetails">
      <Modal show={show}>
        {!showSuccess ?
          <>
            <Heading heading="Delivery address" />
            <div className="EditDetails_details divider">
              <OrderBadges order={order} />
              <OrderDetails order={order} phone email bold={false} />
            </div>
            
            <form>
              <InputField type="text" id="street" label="Street Address" value={street1} onChange={(e: ChangeEvent) => setStreet1((e.target as HTMLInputElement).value)} />
              <InputField type="text" id="street" value={street2} onChange={(e: ChangeEvent) => setStreet2((e.target as HTMLInputElement).value)} />
              <InputField type="text" id="suburb" label="Suburb" value={suburb} onChange={(e: ChangeEvent) => setSuburb((e.target as HTMLInputElement).value)} />
              <InputField type="text" id="postcode" label="Postcode" value={postcode} regex={/^\d{0,4}$/} onChange={(e: ChangeEvent) => setPostcode((e.target as HTMLInputElement).value)} />
              <SelectInput id="state" label="State" options={stateOptions} value={state} onChange={(e: ChangeEvent) => setState((e.target as HTMLInputElement).value as AddressState)} />
              <SelectInput id="country" label="Country" options={countryOptions} value={country} onChange={(e: ChangeEvent) => setCountry((e.target as HTMLInputElement).value as AddressCountry)} />
            </form>
            {errorMessage &&
              <div style={{ marginTop: '20px', marginBottom: '-20px' }}>
                <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                  <p>{errorMessage}<br/>Please try again or contact Rival Software <a href="mailto:support@sparrowhub.com.au?subject=SparrowHub%20Support%20Request" target="_blank" rel="noreferrer">here</a>.</p>
                </Alert>
              </div>
            }
            <Button type={ButtonType.Primary} text="Save Changes" onClick={saveChanges} disabled={!validChanges()} loading={isLoading} />
            <Button type={ButtonType.Secondary} text="Cancel" onClick={handleClose} />
          </>
        :
          <>
            <Alert type={AlertType.PositiveSecondary} successModal>
              <p>Delivery address has successfully been updated</p>
            </Alert>
          </>
        }
      </Modal>
    </StyledEditCustomerDetailsModal>
  );
}

const StyledEditCustomerDetailsModal = styled.div`
  .Modal {
    position: relative;
    z-index: -1;
    
    .EditDetails_details {
      margin-top: 28px;
      /* margin-bottom: 38px; */

      .Badge {
        display: inline-flex;
        margin-right: 8px;
      }
    }

    form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      margin-top: 30px;

      /* address lines */
      div:nth-child(1) {
        grid-column: span 2;
      }
      div:nth-child(2) {
        margin-top: -15px;
        grid-column: span 2;
      }
    }

    .Button_primary {
      margin-top: 30px;
      margin-bottom: 23px;
    }
  }
`