import { FunctionComponent, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { boxShadows, transitions } from '../../assets/css/variables';
import styled from 'styled-components';

import { UserRoleCode } from '../../types/IUsers';

import { useDummyData } from '../../context/DummyDataProvider';
import { useApi } from '../../context/ApiProvider';
import { ApiHelper } from "../../common/ApiHelper/ApiHelper";
import { SparrowHubApiInterface } from 'sparrowhub-client-axios';

import { Product } from '../../types/Products';

import closeIcon from '../../assets/images/icons/Close.svg';
import sparrowHubLogo from '../../assets/images/logos/sparrowhub-lockup.svg';


type ProductMenuOption = {
  id: Product
  label?: string
  imgSrc?: string
  requiredUserRoles?: Array<UserRoleCode>
  dummyOnly?: boolean
}

const menuOptions: Array<ProductMenuOption> = [
  {
    id: Product.SparrowSend,
    label: 'Order fulfilment',
  },
  {
    id: Product.SparrowQueue,
    label: 'Queue'
  },
  // {
  //   id: Product.SparrowConsult,
  //   imgSrc: sparrowConsultLogo,
  //   requiredUserRoles: [UserRoleCode.Admin],
  //   dummyOnly: true,
  // },
  // {
  //   id: Product.SparrowInsight,
  //   imgSrc: sparrowInsightLogo,
  //   requiredUserRoles: [UserRoleCode.Admin],
  //   dummyOnly: true,
  // },
  // {
  //   id: Product.SparrowPhone,
  //   imgSrc: sparrowPhoneLogo,
  //   requiredUserRoles: [UserRoleCode.Admin],
  //   dummyOnly: true,
  // },
]

type ProductMenuProps = {
  visible: boolean,
  userRole: UserRoleCode | undefined,
  onClose: Function,
}

export const ProductMenu: FunctionComponent<ProductMenuProps> = ({ visible, userRole, onClose }) => {
  const navigate = useNavigate();

  // get API context
  const { apiHelper, api }: { apiHelper: ApiHelper; api: SparrowHubApiInterface } = useApi();
  const dummyData: any = useDummyData();

  // methods
  const handleSelectProduct = (product: Product): void => {
    // save in localStorage
    localStorage.setItem('Scription_product', product);

    // nav to correct route
    switch (product) {
      case Product.SparrowSend:
        navigate('/send/orders');
        break;
      case Product.SparrowQueue:
        navigate('/queue/orders');
        break;
      case Product.SparrowConsult:
        navigate('/consult');
        break;
      case Product.SparrowInsight:
        navigate('/insight');
        break;
      case Product.SparrowPhone:
        navigate('/phone/process/create-order');
        break;
    }
    onClose();
  }

  // add and remove mouse event listeners on mount/unmount
  useEffect(() => {
    document.addEventListener('pointerdown', handleClickOutside);
    return () => {
      document.removeEventListener('pointerdown', handleClickOutside);
    }
  })

  const handleClickOutside = (event: PointerEvent): void => {
    if (menuRef && !menuRef.contains(event.target as Node) && visible) {
      onClose();
    }
  }

  let menuRef: HTMLDivElement | null;
  const initClickOutside = (el: HTMLDivElement | null): void => {
    if (el) {
      menuRef = el;
    }
  }

  return (
    <StyledProductMenu className={`ProductMenu ${visible ? '' : 'hidden'}`} ref={el => initClickOutside(el)}>
      <div className="ProductMenu_parent">
        <img className="ProductMenu_close button" alt="Close nav menu" src={closeIcon} onClick={() => onClose()} draggable="false" />
        <img className="ProductMenu_hubLogo" src={sparrowHubLogo} alt="" draggable="false" />
        <p className="ProductMenu_header bold">Your Apps</p>

        <div className="ProductMenu_grid">
          {menuOptions.filter(option => option.dummyOnly !== true || dummyData.state.useDummyData).map((option: ProductMenuOption, i: number) => {
            if (!option.requiredUserRoles || (userRole && option.requiredUserRoles.includes(userRole))) {
              return (
                <div className="ProductMenu_option" key={`option_${option.id}`} onClick={() => handleSelectProduct(option.id)}>
                  {option.imgSrc &&
                    <img src={option.imgSrc} alt="" draggable="false" />
                  }
                  {option.label &&
                    <p className="bold">{option.label}</p>
                  }
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
    </StyledProductMenu>
  );
}

const StyledProductMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  background: white;
  border-radius: 6px;
  padding: 65px 40px 34px 40px;
  box-shadow: -4px 4px 4px 0 rgba(0, 0, 0, 0.1);
  transition: transform ${transitions.default};

  &.hidden {
    transform: translateX(-100vw);
  }

  .ProductMenu_parent {
    width: 100%;
    height: 100%;

    .ProductMenu_close {
      position: absolute;
      width: 30px;
      top: 25px;
      left: 32px;
    }

    .ProductMenu_hubLogo {
      position: absolute;
      top: 25px;
      right: 32px;
      width: 198px;
      height: auto;
    }

    .ProductMenu_header {
      margin-top: 50px;
      font-size: 2.25rem; // 36px
    }

    .ProductMenu_grid {
      width: 100%;
      height: 100%;
      display: grid;
      gap: 20px;
      grid-gap: 20px;

      grid-template-columns: repeat(auto-fill, 250px);
      grid-template-rows: repeat(auto-fill, 126px);

      .ProductMenu_option {
        width: 250px;
        height: 126px;
        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;
        border-radius: 6px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
        transition: transform ${transitions.default};

        img {
          height: 70px;
          width: auto;
          user-select: none;
        }

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
`