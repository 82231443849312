import { FunctionComponent } from 'react';
import styled from 'styled-components';

import { CourierType, IOrder, OrderDeliveryType } from '../../types/IOrders';
import { orderRequiresContact } from '../../helpers/utils';

import { Badge } from '../Badge/Badge';


type OrderBadgesProps = {
  order: IOrder
}

export const OrderBadges: FunctionComponent<OrderBadgesProps> = ({ order }) => {
  return (
    <StyledOrderBadges className="OrderBadges">
      {/* Determine whether to use the Amazon or Ebay badge with the `order.platform_payment_method` value */}
      {order.platform_payment_method === 'amazon' && <Badge type={'payment_method_amazon'} iconOnly />}
      {order.platform_payment_method === 'ebay' && <Badge type={'payment_method_ebay'} iconOnly />}

      <Badge type={order.delivery_type_code} />
      {(order.delivery_type_code === OrderDeliveryType.Standard || order.delivery_type_code === OrderDeliveryType.Express) && <Badge type={'courier_australiaPost'} />}
      {order.delivery_type_code === OrderDeliveryType.SameDay && <Badge type={'courier_doordash'} />}
      {orderRequiresContact(order) && <Badge type={'requires_contact'} />}
    </StyledOrderBadges>
  );
}

const StyledOrderBadges = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`