import { FunctionComponent, useState, useEffect } from "react";
import { Navigate, Params, useParams } from "react-router-dom";

import { IUser } from "../types/IUsers";
import { IOrders } from "../types/IOrders";
import { ILocation, ILocations } from "../types/ILocations";

import { Body } from "../components/Body/Body";
import { Heading } from "../components/Heading/Heading";
import { BackButton } from "../components/BackButton/BackButton";
import { ProcessOrderModal } from "../components/ProcessOrderModal/ProcessOrderModal";
import { ProductSearchPage } from "./ProductSearchPage";


export enum Process {
  Delivery = 'delivery',
  CustomDelivery = 'custom-delivery',
  SameDayDelivery = 'same-day-delivery',
  ClickAndCollect = 'click-and-collect',
  CustomerCollection = 'customer-collection',
  CreateRefund = 'create-refund',
  PartialRefund = 'partial-refund',
  CompleteRefund = 'complete-refund',
  CustomRefund = 'custom-refund',
  DeliveryRefund = 'delivery-refund',
  CreateOrder = 'create-order',
  SchedulePickup = 'schedule-pickup',
  OrganiseDropoff = 'organise-dropoff'
}

export enum ProcessOrderStep {
  ConfirmID = "Confirm ID",
  Pick = "Pick",
  Pack = "Pack",
  Declare = "Declare",
  Package = "Package",
  Weigh = "Weigh",
  Courier = "Courier",
  Notes = "Notes",
  Confirm = "Confirm",
  CreateRefund = "Create Refund",
  RefundItems = "Items",
  RefundQuantities = "Quantities",
  RefundReason = "Reason",
  RefundAmount = "Amount",
  SelectItems = "Select Items",
  Payment = "Payment",
  DeliveryDetails = "Shipping",
  ConfirmOrders = "Confirm Orders",
  PrintManifest = "Print Manifest",
  SchedulePickup = "Schedule Pickup",
  DropoffLocation = "Drop Off Location",
  CollectionTime = "Collection Time",
  SendLink = "Send Link"
}

export const processSteps = {
  'delivery': [
    ProcessOrderStep.Pick,
    ProcessOrderStep.Declare,
    ProcessOrderStep.Package,
    ProcessOrderStep.Weigh,
    ProcessOrderStep.Courier,
    ProcessOrderStep.Confirm
  ],
  'custom-delivery': [
    ProcessOrderStep.Pick,
    ProcessOrderStep.Pack,
    ProcessOrderStep.Confirm
  ],
  'same-day-delivery': [
    ProcessOrderStep.Pick,
    ProcessOrderStep.Pack,
    ProcessOrderStep.CollectionTime,
    ProcessOrderStep.Confirm
  ],
  'click-and-collect': [
    ProcessOrderStep.Pick,
    ProcessOrderStep.Pack,
    ProcessOrderStep.Confirm
  ],
  'customer-collection': [
    ProcessOrderStep.ConfirmID,
    ProcessOrderStep.Notes
  ],
  'create-refund': [
    ProcessOrderStep.CreateRefund
  ],
  'partial-refund': [
    ProcessOrderStep.RefundItems,
    ProcessOrderStep.RefundQuantities,
    ProcessOrderStep.RefundReason,
    ProcessOrderStep.Notes,
    ProcessOrderStep.Confirm
  ],
  'complete-refund': [
    ProcessOrderStep.RefundReason,
    ProcessOrderStep.Notes,
    ProcessOrderStep.Confirm
  ],
  'custom-refund': [
    ProcessOrderStep.RefundAmount,
    ProcessOrderStep.RefundReason,
    ProcessOrderStep.Notes,
    ProcessOrderStep.Confirm
  ],
  'delivery-refund': [
    ProcessOrderStep.RefundReason,
    ProcessOrderStep.Notes,
    ProcessOrderStep.Confirm
  ],
  'create-order': [
    ProcessOrderStep.SelectItems,
    ProcessOrderStep.DeliveryDetails,
    ProcessOrderStep.Payment,
    ProcessOrderStep.SendLink
  ],
  'schedule-pickup': [
    ProcessOrderStep.PrintManifest,
    ProcessOrderStep.SchedulePickup
  ],
  'organise-dropoff': [
    ProcessOrderStep.PrintManifest,
    ProcessOrderStep.DropoffLocation
  ]
}

type ProcessOrderPageProps = {
  loggedin: boolean
  user: IUser | null
  location: ILocation | null
  onSelectLocation: Function
  onSelectUser: Function
  onLogout: Function
  orders: IOrders
  partnerLocations: ILocations
}

export const ProcessOrderPage: FunctionComponent<ProcessOrderPageProps> = ({ loggedin, user, location, onSelectLocation, onSelectUser, onLogout, orders, partnerLocations }) => {
  const { process, orderId }: Readonly<Params<string>> = useParams();

  const [currentStep, setCurrentStep] = useState(0);
  const [createOrderItems, setCreateOrderItems] = useState([] as Array<any>);
  const [searchQuery, setSearchQuery] = useState("");
  const [showSearchResults, setShowSearchResults] = useState(false);

  // scroll to top when step changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep])

  const backButtonPath = (): string => {
    if (['partial-refund', 'complete-refund', 'custom-refund', 'delivery-refund'].includes(process!)) {
      return `/send/process/create-refund/${orderId}`;
    } else {
      if (process === Process.CreateRefund) {
        return '/send/orders';
      } else if (process === Process.CustomerCollection) {
        return '/send/orders/awaiting-pickup';
      } else {
        return '/send/orders';
      }
    }
  }

  const backButtonLabel = (): string => {
    if (['partial-refund', 'complete-refund', 'custom-refund', 'delivery-refund'].includes(process!)) {
      return 'Create Refund';
    } else {
      if (process === Process.CreateRefund) {
        return 'Open Orders';
      } else if (process === Process.CustomerCollection) {
        return 'Awaiting Click & Collect';
      } else {
        return 'Open Orders';
      }
    }
  }

  const handleBack = (numSteps: number = 1): void => {
    const prevStep = currentStep - numSteps;
    if (prevStep >= 0) setCurrentStep(prevStep);
  }

  const handleNext = (numSteps: number = 1): void => {
    const nextStep = currentStep + numSteps;
    if (nextStep < processSteps[process as keyof typeof processSteps].length) setCurrentStep(nextStep);
  }

  const handleStepReset = (): void => {
    setCurrentStep(0);
  }
  
  const handleSetStep = (stepIndex: number): void => {
    if (stepIndex >= 0 && stepIndex < processSteps[process as keyof typeof processSteps].length) {
      setCurrentStep(stepIndex);
    } else {
      console.warn('Invalid step index.')
    }
  }

  return (
    <>
      {!loggedin || !location ?
        <Navigate to="/login" />
        :
        <>
          {!orders ?
            <Navigate to="/send/orders" />
            :
            <Body loggedin={loggedin} user={user} location={location} onSelectLocation={onSelectLocation} onSelectUser={onSelectUser} onLogout={onLogout}>
              <div style={{
                display: !showSearchResults ? 'block' : 'none',
                pointerEvents: !showSearchResults ? 'all' : 'none'
              }}>
                <Heading heading={location.name} label="You are dispatching from:" showChangeLocation onSelectLocation={onSelectLocation} />
                <BackButton to={backButtonPath()} text={`Back to ${backButtonLabel()}`} onClick={handleStepReset} />
                <ProcessOrderModal key={process} user={user!} location={location!} orderId={orderId!} process={process as Process} steps={processSteps[process as keyof typeof processSteps]} currentStepIndex={currentStep} next={handleNext} back={handleBack} setStep={handleSetStep} orders={orders} partnerLocations={partnerLocations} createOrderItems={createOrderItems} setCreateOrderItems={setCreateOrderItems} setShowSearchResults={setShowSearchResults} searchQuery={searchQuery} setSearchQuery={setSearchQuery} onLogout={onLogout} />
              </div>

              <div style={{
                display: showSearchResults ? 'block' : 'none',
                pointerEvents: showSearchResults ? 'all' : 'none'
              }}>
                <BackButton to={null} text="Back to Current Phone Order" onClick={() => setShowSearchResults(false)} />
                <ProductSearchPage createOrderItems={createOrderItems} setCreateOrderItems={setCreateOrderItems} setShowSearchResults={setShowSearchResults} partnerLocations={partnerLocations} searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
              </div>
            </Body>
          }
        </>
      }
    </>
  );
}