import { ChangeEvent, FunctionComponent, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { getFieldsFromGoogleAddressComponents } from '../../helpers/utils';
import { AddressCountries, AddressRegions } from 'sparrowhub-client-axios';
import { PaymentAddress } from '../PaymentInput/PaymentInput';

import { InputField } from '../InputField/InputField';
import { SelectInput, SelectInputOption } from '../SelectInput/SelectInput';

const stateOptions: Array<SelectInputOption> = [
  {
    value: undefined,
    label: ''
  },
  ...Object.values(AddressRegions).map((state) => {
    return {
      value: state,
      label: state
    }
  })
];

const countryOptions: Array<SelectInputOption> = Object.values(AddressCountries).map((country) => {
  return {
    value: country,
    label: country
  }
});

type AddressInputProps = {
  heading?: string,
  address: PaymentAddress,
  onSetAddress: Function
}

export const AddressInput: FunctionComponent<AddressInputProps> = ({ heading, address, onSetAddress }) => {
  const googleInitialized = useRef(false);
  
  const addressIsNotEmpty = (): boolean => {
    return (
      address.street1 !== '' ||
      address.street2 !== '' ||
      address.state !== '' ||
      address.postcode !== ''
    )
  }

  // init Google Places Autocomplete widget
  useEffect(() => {
    const initAutocomplete = async () => {
      const container = document.getElementById('Delivery_addressWidgetContainer');

      //@ts-ignore
      await google.maps.importLibrary("places") as google.maps.PlacesLibrary;
      //@ts-ignore
      const placeAutocomplete = new google.maps.places.PlaceAutocompleteElement({
        componentRestrictions: {country: ['au']},
      });
      
      //@ts-ignore
      if (container) {
        container.appendChild(placeAutocomplete);
      } else {
        console.log('Unable to find container element for Autocomplete widget.')
      }

      // Add the gmp-placeselect listener, and display the results.
      //@ts-ignore
      placeAutocomplete.addEventListener('gmp-placeselect', async ({ place }) => {
        await place.fetchFields({ fields: ['displayName', 'addressComponents'] });
        const json = place.toJSON();
        const fields = getFieldsFromGoogleAddressComponents(json.displayName, json.addressComponents);
        onSetAddress(fields);
      });
    }
    if (!googleInitialized.current) {
      googleInitialized.current = true;
      initAutocomplete()
        .catch(console.log);
    }
  }, []);
  
  return (
    <StyledAddressInput className="AddressInput">
      {heading && <h3>{heading}</h3>}

      {/* name inputs */}
      <div className="columns">
        <InputField type="text" id="firstName" label="First Name" value={address.first_name} required onChange={(e: ChangeEvent) => onSetAddress({ first_name: (e.target as HTMLInputElement).value })} />
        <InputField type="text" id="lastName" label="Last Name" value={address.last_name} required onChange={(e: ChangeEvent) => onSetAddress({ last_name: (e.target as HTMLInputElement).value })} />
      </div>

      {/* contact inputs */}
      <InputField type="email" id="customerEmail" label="Email" value={address.email} required onChange={(e: ChangeEvent) => onSetAddress({ email: (e.target as HTMLInputElement).value })} />
      <InputField type="tel" id="customerPhone" label="Mobile Number" value={address.phone} required onChange={(e: ChangeEvent) => onSetAddress({ phone: (e.target as HTMLInputElement).value })} />
      
      {/* google address lookup  */}
      <div style={{ marginTop: '20px' }}>
        <label htmlFor="Delivery_addressWidgetContainer" style={{ display: 'block', marginBottom: '6px' }}>Address Lookup</label>
        <div id="Delivery_addressWidgetContainer"></div>
      </div>

      {/* manual address inputs */}
      {addressIsNotEmpty() &&
        <>
          <InputField type="text" id="street" label="Street Address" value={address.street1} required onChange={(e: ChangeEvent) => onSetAddress({ street1: (e.target as HTMLInputElement).value })} />
          <InputField type="text" id="street" value={address.street2} onChange={(e: ChangeEvent) => onSetAddress({ street2: (e.target as HTMLInputElement).value })} />
          <InputField type="text" id="suburb" label="Suburb" value={address.suburb} required onChange={(e: ChangeEvent) => onSetAddress({ suburb: (e.target as HTMLInputElement).value })} />
          
          <div className="columns">
            <InputField type="text" id="postcode" label="Postcode" value={address.postcode} regex={/^\d{0,4}$/} required onChange={(e: ChangeEvent) => onSetAddress({ postcode: (e.target as HTMLInputElement).value })} />
            <SelectInput id="state" label="State" options={stateOptions} value={address.state} required onChange={(e: ChangeEvent) => onSetAddress({ state: (e.target as HTMLInputElement).value as AddressRegions })} />
            {/* <SelectInput id="country" label="Country" options={countryOptions} value={address.country} required onChange={(e: ChangeEvent) => onSetAddress({ country: (e.target as HTMLInputElement).value as AddressCountries })} /> */}
          </div>
        </>
      }
    </StyledAddressInput>
  );
}

const StyledAddressInput = styled.div`
  h3 {
    margin-bottom: 0;
  }

  .InputField label {
    display: inline-block;
    margin-top: 20px;
  }
`